import { apiClient, Button, ButtonGroup, DropdownFormInput, FormGroup, Message, Modal, useLanguageResource } from "@ruter-as/web-components-and-tools";
import * as sentry from "@sentry/react";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import companyAgreementApi from "src/common/api/companyAgreementApi/companyAgreementApi";
import { CompanyAgreementOrder } from "src/common/api/companyAgreementApi/order";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import { ensureExpiryDateIsNotInThePastAndFormatForBackend, getExpiryDates } from "../../../common/expiryDate";
import employeeTicketsLanguageResource from "./lang-resource";

interface Props {
  onClose: () => void;
  onSuccess: () => void;
  ticket: CompanyAgreementOrder;
}

interface ChangeActiveTicketForm {
  expiryDate: string;
}

const CancelActiveTicketModal: React.FC<Props> = ({ onClose, onSuccess, ticket }) => {
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const fromDates = getExpiryDates(6, formLang.today);
  const language = useLanguageResource(employeeTicketsLanguageResource);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);


  const formMethods = useForm<ChangeActiveTicketForm>({
    defaultValues: {
      expiryDate: fromDates[0].value,
    },
  });

  const expiryDate = formMethods.watch("expiryDate");

  const submit = async (data: ChangeActiveTicketForm) => {
    setSubmitError(false);
    setSubmitting(true);

    const newExpiryDate = ensureExpiryDateIsNotInThePastAndFormatForBackend(data.expiryDate);
    const response = await apiClient.request(companyAgreementApi.ticket.cancelActiveTicket(ticket.id, newExpiryDate));

    if (response.type === "success") {
      onSuccess();
    } else {
      setSubmitError(true);
      setSubmitting(false);
      sentry.captureException(response.error);
    }
  };

  return (
    <Modal
      isOpen={true}
      title={language.cancelActiveTicket}
      handleClose={onClose}
      data-test-id="cancel-active-ticket-modal"
    >
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(submit)}>
          <DropdownFormInput name="expiryDate" label={language.from} required>
            {fromDates.map(x => <option key={x.value} value={x.value}>{x.text}</option>)}
          </DropdownFormInput>
          <FormGroup>
            <Message skin="info" title={language.cancelActiveTicketInfoTitle} data-test-id="information-message" style={{ marginTop: "1rem" }}>
              <p style={{ marginBottom: "1rem" }}>
                {language.cancelActiveTicketInfoPrivacy(expiryDate)}
              </p>
            </Message>
          </FormGroup>
          {submitError && <FormGroup><Message skin="danger" data-test-id="change-zone-submit-error-message" title={formLang.submitError} /></FormGroup>}
          <ButtonGroup>
            <Button variant="primary" type="submit" text={formLang.yes} loading={submitting} />
            <Button variant="cancel" type="button" text={formLang.cancel} data-test-id="cancel-button" onClick={onClose} />
          </ButtonGroup>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default CancelActiveTicketModal;
