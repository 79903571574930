import { hasValidAgreement } from "src/common/agreements";
import { AgreementSubTypeId } from "src/types/AgreementSubTypeId";
import { AuthCompany, WidgetAccessOptions } from "src/types/AuthCompany";
import { Widget } from "src/types/companyAgreement/Widget";
import { Contact } from "src/types/Contact";
import { CompanyRoleType } from "src/types/user/CompanyRoleType";
import { UserRoleType } from "src/types/user/UserRoleType";
import { EnvName } from "../EnvName";
import { Tenant } from "../Tenant";
import { InvoiceRef, mapInvoiceRef } from "./InvoiceRef";
import { HasNoService } from "./Services";

interface WidgetAccess {
  getIframeUrl: (widget: Widget, preview: boolean, theme: "light" | "dark") => string,
  getLinkUrl: (widget: Widget) => string,
}

interface PayRoll {
  showDatePicker: boolean
}

export interface HasCompanyTicketService {
  hasService: true,
  invoiceRefs: InvoiceRef[]
  widgetSettings: WidgetSettings
  agreementNumber: string
  allowOrderTicket: boolean
  payRoll: PayRoll
  isObosTestCustomer: boolean
  hasIncreasedExpiryDate: boolean
  widgetUrls: WidgetAccess
}

export interface WidgetSettings {
  emailForTicketRequests: string | null;
  emailForInvoicePreview: string | null;
  widgetAccess: WidgetAccessOptions;
  employeeIdLabel: string;
}

export type CompanyTicketService = HasCompanyTicketService | HasNoService;

const getIframeUrl = (widget: Widget, preview: boolean, theme: "light" | "dark", env: EnvName): string => {
  const { clientId, clientSecret } = widget;

  let origin = "http://localhost:4000";
  if (env === "test") {
    origin = "https://bigcustomer-widget.test.transhub.io";
  } else if (env === "stage") {
    origin = "https://bigcustomer-widget.stage.transhub.io";
  } else if (env === "prod") {
    origin = "https://bigcustomer-widget.transhub.io";
  }

  let url = `${origin}/?id=${clientId}&secret=${clientSecret}&theme=${theme}`;
  if (preview) {
    url += "&preview=true";
  }
  return url;
};


const getLinkUrl = (widget: Widget, env: EnvName) => {
  const { clientId, clientSecret } = widget;

  let origin = "http://localhost:4000";
  if (env === "test") {
    origin = "https://bigcustomer-widget.test.transhub.io";
  } else if (env === "stage") {
    origin = "https://bigcustomer-widget.stage.transhub.io";
  } else if (env === "prod") {
    origin = "https://bigcustomer-widget.transhub.io";
  }

  return `${origin}/?id=${clientId}&secret=${clientSecret}&viewmode=link`;
};

const calculateWidgetAcces = (env: EnvName): WidgetAccess => ({
  getIframeUrl: (widget: Widget, preview: boolean, theme: "light" | "dark") => getIframeUrl(widget, preview, theme, env),
  getLinkUrl: (widget: Widget) => getLinkUrl(widget, env),
});

export const calculateCompanyTicketService = (selectedCompany: AuthCompany, contact: Contact, tenant: Tenant, env: EnvName): CompanyTicketService => {

  const agreements = selectedCompany.allAgreements.filter(x => x.subTypeId === AgreementSubTypeId.SUBSCRIPTIONS);

  if (agreements.length === 0 || tenant !== Tenant.Ruter) {
    return { hasService: false };
  }

  const isCompanyAdmin = selectedCompany.roles.some(role => role === CompanyRoleType.FreeTicketOrCompanyTicketAdmin)
    || contact.roles.some(role => role === UserRoleType.GlobalCompanyAdmin);

  if (!isCompanyAdmin) {
    return { hasService: false };
  }

  return {
    hasService: true,
    allowOrderTicket: hasValidAgreement([agreements[0]]),
    invoiceRefs: agreements.map(mapInvoiceRef),
    widgetSettings: agreements[0].settings,
    agreementNumber: agreements[0].id,
    payRoll: {
      showDatePicker: env !== "prod",
    },
    isObosTestCustomer: env !== "prod" && selectedCompany?.id === "1229668",
    hasIncreasedExpiryDate: env !== "prod",
    widgetUrls: calculateWidgetAcces(env),
  };
};

export const getValidCompanyticketService = (service: Partial<CompanyTicketService>): CompanyTicketService => ({
  hasService: true,
  agreementNumber: "",
  allowOrderTicket: false,
  hasIncreasedExpiryDate: false,
  invoiceRefs: [],
  isObosTestCustomer: false,
  payRoll: {
    showDatePicker: false,
  },

  widgetSettings: {
    emailForInvoicePreview: "",
    emailForTicketRequests: "",
    employeeIdLabel: "",
    widgetAccess: "DISABLED",
  },
  widgetUrls: {
    getIframeUrl: (widget: Widget, preview: boolean, theme: "light" | "dark") => getIframeUrl(widget, preview, theme, "local"),
    getLinkUrl: (widget: Widget) => getLinkUrl(widget, "local"),
  },
  ...service,
});
