import {
  apiClient, ButtonGroup,
  CheckboxList,
  Container,
  DropdownFormInput, formatter, PhoneFormInput,
  RadioButtonFormInput,
  TextFormInput, useLanguageContext,
  useLanguageResource,
} from "@ruter-as/web-components-and-tools";
import parsePhoneNumberFromString from "libphonenumber-js";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAuthContextAuthenticated, useValidCompanyTicketService } from "src/AuthContext";
import { AddOn, mapZones, Zone } from "src/common/api/commonTypes/Zone";
import companyAgreementApi from "src/common/api/companyAgreementApi/companyAgreementApi";
import { Prices } from "src/common/api/companyAgreementApi/prices";
import { ensureExpiryDateIsNotInThePastAndFormatForBackend, getExpiryDates } from "src/common/expiryDate";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import { CancelButton, SubmitButton } from "src/components/common/buttons";
import { ALL_ZONES_FROM } from "src/constants";
import { formLanguageResource } from "../common/form/lang-resource";
import { createTicketLanguageResource } from "../companyAgreement/createTicket/lang-resource";
import { freeTicketLanguageResource } from "../freeTicketAgreement/freeTicket/lang-resource";
import "./ObosTestCustomer.scss";

type TicketHolderType = "MEMBER" | "FAMILY_MEMBER";

type ProfileType = "ADULT" | "CHILD" | "RETIRED";

interface FormData {
  phone: string;
  firstName: string;
  lastName: string;
  employeeId: string;
  startDate: string;
  addOns: string[];
  profile: ProfileType;
  ticketHolderType: TicketHolderType;
  invoiceRef: string;
  zoneFrom: string;
  zoneTo: string;
  OSLO_CITYBIKE?: boolean;
  OTTO?: boolean;
  RYDE?: boolean;
  TIER?: boolean;
}

interface PostContract {
  startDate: string;
  zoneFrom: string;
  zoneTo: string;
  phone: string;
  phoneCountryCode: string;
  employeeId: string;
  firstName: string;
  lastName: string;
  agreementName: string;
  addOns: string[];
}

const mapAddOns = (json: AddOn[]): AddOn[] => json;

const mapContract = (data: FormData): PostContract => {
  const phone = parsePhoneNumberFromString(data.phone);
  if (!phone || !phone.isValid()) {
    throw new Error("invalid phone number should be impossible as it is validated by the form");
  }
  const addons = [];

  if (data.OSLO_CITYBIKE) {
    addons.push("OSLO_CITYBIKE");
  }

  if (data.OTTO) {
    addons.push("OTTO");
  }

  if (data.RYDE) {
    addons.push("RYDE");
  }

  if (data.TIER) {
    addons.push("TIER");
  }

  return {
    startDate: ensureExpiryDateIsNotInThePastAndFormatForBackend(data.startDate).toISOString(),
    agreementName: data.invoiceRef,
    employeeId: data.employeeId,
    firstName: data.firstName,
    lastName: data.lastName,
    phone: phone.nationalNumber.toString(),
    phoneCountryCode: `+${phone.countryCallingCode}`,
    zoneFrom: data.zoneFrom,
    zoneTo: data.zoneTo,
    addOns: addons,
  };
};

const ObosTestCustomer: React.FC = () => {
  const profileLang = useLanguageResource(freeTicketLanguageResource);
  const lang = useLanguageResource(createTicketLanguageResource);
  const langProductName = useLanguageResource(formLanguageResource);
  const { selectedLanguage } = useLanguageContext();
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const authContext = useAuthContextAuthenticated();
  const selectedCompany = authContext.userData.selectedCompany;
  const companyTicketService = useValidCompanyTicketService();
  const navigate = useNavigate();
  const [companyAddOns, setCompanyAddOns] = useState<AddOn[]>();
  const [submitting, setSubmitting] = useState(false);
  const [zones, setZones] = useState<Zone[]>();
  const dates = getExpiryDates(32, formLang.today, 5);
  const [prices, setPrices] = useState<Prices[]>([]);

  useEffect(() => {
    const fetch = async () => {
      const response = await apiClient.get(`/api/add-on/list-by-company-id/${selectedCompany.id}`, mapAddOns);
      if (response.type === "success") {
        const result = response.result;
        setCompanyAddOns(result);
      } else {
        setCompanyAddOns(() => {
          throw response.error;
        });
      }
    };
    fetch();
  }, [selectedCompany.id]);

  useEffect(() => {
    const fetch = async () => {
      const response = await apiClient.get(`/api/zone/list`, mapZones);
      if (response.type === "success") {
        const result = response.result;
        setZones(result);
      } else {
        setZones(() => {
          throw response.error;
        });
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetchPrices = async () => {
      const priceResponse = await apiClient.request(companyAgreementApi.product.list.get("3"));

      if (priceResponse.error) {
        setPrices(() => { throw priceResponse.error; });
        return;
      }

      setPrices([priceResponse.result]);
    };
    fetchPrices();
  }, []);

  const formMethods = useForm<FormData>({
    defaultValues: {
      phone: "",
      firstName: "",
      lastName: "",
      employeeId: "",
      startDate: "",
      ticketHolderType: "MEMBER",
      invoiceRef: companyTicketService.invoiceRefs[0].agreementNumber,
      zoneFrom: "1",
      zoneTo: "1",
    },
  });

  const OSLO_CITYBIKE = useWatch({ control: formMethods.control, name: "OSLO_CITYBIKE" });
  const OTTO = useWatch({ control: formMethods.control, name: "OTTO" });
  const RYDE = useWatch({ control: formMethods.control, name: "RYDE" });
  const TIER = useWatch({ control: formMethods.control, name: "TIER" });
  const zoneFrom = useWatch({ control: formMethods.control, name: "zoneFrom" });
  const zoneTo = useWatch({ control: formMethods.control, name: "zoneTo" });

  const getAgreementText = (invoiceReference: string, agreementNumber: string) => {
    if (invoiceReference) {
      return `${invoiceReference} (${agreementNumber})`;
    }
    return `${agreementNumber}`;
  };

  const relations = [
    { value: "MEMBER", text: "Medlem" },
    { value: "FAMILY_MEMBER", text: "Familie" },
  ];

  const getPrice = () => {
    let price: number | undefined = 0;
    const addOnsObject: { [x: string]: boolean | undefined } = {
      OSLO_CITYBIKE,
      OTTO,
      RYDE,
      TIER,
    };
    const companyTicketPrices = prices.find((x) => x.templateId === "3");

    if ((!zoneFrom || !zoneTo) && zoneFrom !== ALL_ZONES_FROM) {
      return null;
    }

    let zoneCount = 1;
    if (zoneFrom === ALL_ZONES_FROM) {
      zoneCount = 3;
    } else if (zoneFrom !== zoneTo) {
      zoneCount = 2;
    }
    if (companyTicketPrices) {
      price = companyTicketPrices.prices.find((x) => x.nrOfZones === zoneCount)?.pricePerMonthInclVAT;
    }

    if (price && companyAddOns) {
      const addOnsPrice = companyAddOns.reduce((prev, curr) => (addOnsObject[curr.type] ? curr.pricePerMonth + prev : prev), 0);

      price = price + addOnsPrice;
    }
    return price;
  };

  const price = getPrice();

  const formattedPrice = price ? formatter.number.integer(price) : "-";
  const formattedDate = Intl.DateTimeFormat("no", { month: "long", year: "numeric" }).format();

  const submit = async (data: FormData) => {
    try {
      setSubmitting(true);
      const contract = mapContract(data);
      await apiClient.post("/api/ticket/create", contract);
      setSubmitting(false);
      navigate("/bedriftsavtale");
    } catch (e) {
      setSubmitting(() => {
        throw e;
      });
    }
  };

  return (
    <Container width="xs" className="create-ticket-form-page">
      <h1>{lang.title}</h1>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(submit)}>
          <PhoneFormInput name="phone" required label={formLang.phone} />
          <TextFormInput name="firstName" label={formLang.firstName} required minLength={2} maxLength={50} />
          <TextFormInput name="lastName" label={formLang.lastName} required minLength={2} maxLength={50} />
          <TextFormInput name="employeeId" label={"Medlemsnr"} required minLength={1} maxLength={20} />
          <DropdownFormInput name="invoiceRef" label={formLang.invoiceReference} required>
            {companyTicketService.invoiceRefs.map((option) => (
              <option key={option.agreementNumber} value={option.agreementNumber}>
                {getAgreementText(option.invoiceReference, option.agreementNumber)}
              </option>
            ))}
          </DropdownFormInput>
          <DropdownFormInput name="startDate" label={formLang.startDate}>
            {dates.map((d) => (
              <option value={d.value} key={d.value}>
                {d.text}
              </option>
            ))}
          </DropdownFormInput>
          <DropdownFormInput name="ticketHolderType" label={formLang.relation} required>
            {relations.map((relation) => (
              <option key={relation.value} value={relation.value}>
                {relation.text}
              </option>
            ))}
          </DropdownFormInput>
          <RadioButtonFormInput
            name="profile"
            label={"Reisende"}
            required
            options={[
              { text: profileLang.adult, value: "adult" },
              { text: profileLang.child, value: "child" },
              { text: profileLang.reducedRate, value: "reducedRate" },
            ]}
          />
          {zones && authContext.features.getZoneInput(zones)}
          {companyAddOns && companyAddOns.length > 0 && (
            <CheckboxList
              label={lang.addOnTitle}
              options={companyAddOns.map((addOn) => ({
                label: selectedLanguage === "en" ? addOn.description.en : addOn.description.nb,
                name: addOn.type,
              }))}
            />
          )}
          <div className="components-common-form-productpricefield">
            <div className="label">{langProductName.productName(formattedDate)}</div>
            <div>
              <span className="price" data-test-id="product-price-field">
                {formattedPrice}
              </span>
              <span className="label">kr</span>
            </div>
          </div>
          <ButtonGroup>
            <SubmitButton text={lang.orderTicket} submitting={submitting} />
            <CancelButton onClick={() => navigate("/bedriftsavtale")} />
          </ButtonGroup>
        </form>
      </FormProvider>
    </Container>
  );
};

export default ObosTestCustomer;
