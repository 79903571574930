import { apiClient, ButtonGroup, Container, DropdownFormInput, FieldValidationError, formatter, parser, PhoneFormInput, TextFormInput, useLanguageResource } from "@ruter-as/web-components-and-tools";
import * as Sentry from "@sentry/react";
import parsePhoneNumberFromString from "libphonenumber-js/min";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAuthContextAuthenticated, useValidHousingAssociationService } from "src/AuthContext";
import { Zone } from "src/common/api/commonTypes/Zone";
import CreateHousingTicketFormData, { CreateCompanyTicketErrorType, QuaranteeenError, QuaranteeenErrorJson } from "src/common/api/companyAgreementApi/housingAssociation/createHousingTicketFormDate";
import housingAssociationApi from "src/common/api/companyAgreementApi/housingAssociation/housingAssociationApi";
import { mapHousingAssociationOrder } from "src/common/api/companyAgreementApi/housingAssociation/housingAssociationOrder";
import HousingAssociationPostContract from "src/common/api/companyAgreementApi/housingAssociation/housingAssociationPostContract";
import { getExpiryDates } from "src/common/expiryDate";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import getTicketOwnerFieldName from "src/common/ticketOwnerFieldName";
import { CancelButton, SubmitButton } from "src/components/common/buttons";
import { housingAsssociationTicketFormLanguageResource } from "./lang-resource";


const HousingAssociationTicketForm: React.FC = () => {
  const authContext = useAuthContextAuthenticated();
  const housingAssociationService = useValidHousingAssociationService();
  const lang = useLanguageResource(housingAsssociationTicketFormLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [zones, setZones] = useState<Zone[]>();
  const [backendError, setBackendError] = useState<CreateCompanyTicketErrorType>();
  const ticketOwnerFieldName = getTicketOwnerFieldName(housingAssociationService);


  const dates = getExpiryDates(6, formLang.today);

  useEffect(() => {
    const fetchZones = async () => {
      const response = await apiClient.request(housingAssociationApi.zones.getAll());
      if (response.type === "success") {
        setZones(response.result);
      } else {
        setZones(() => {
          throw response.error;
        });
      }
    };

    fetchZones();
  }, []);

  const formMethods = useForm<CreateHousingTicketFormData>({
    defaultValues: {
      agreementNumber: housingAssociationService.agreementNumber,
      firstName: "",
      lastName: "",
      phone: "",
      startDate: dates[0].value,
      zoneFrom: "1",
      zoneTo: "1",
    },
  });

  const onSubmit = async (data: CreateHousingTicketFormData) => {
    setLoading(true);
    const phone = parsePhoneNumberFromString(data.phone);

    if (!phone || !phone.isValid()) {
      throw new Error("invalid phone number should be impossible as it is validated by the form");
    }

    const backendContract: HousingAssociationPostContract = {
      agreementName: data.agreementNumber,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: phone.nationalNumber.toString(),
      phoneCountryCode: `+${phone.countryCallingCode}`,
      startDate: parser.date.fromShortDateString(data.startDate).toISOString(),
      zoneFrom: data.zoneFrom,
      zoneTo: data.zoneTo,
      employeeId: data.employeeNumber,
      addOns: [],
    };

    const response = await apiClient.request(housingAssociationApi.ticket.create(backendContract));

    if (response.type === "success") {
      navigate("/borettslag");
    } else if (response.type === "HttpError" && response.responseStatus === 409) {
      Sentry.captureException(response.error, { extra: { responseBody: response.responseBody } });
      const responseBodyJson = response.responseBody as QuaranteeenErrorJson;

      if (responseBodyJson.expectedQuaranteenEndDate && responseBodyJson.lastOrder?.expirationDate) {
        const responseBody: QuaranteeenError = {
          type: "QUARANTEEN_ERROR",
          expectedQuaranteenEndDate: new Date(responseBodyJson.expectedQuaranteenEndDate),
          lastOrder: mapHousingAssociationOrder(responseBodyJson.lastOrder),
        };

        setBackendError(responseBody);
      } else {
        setBackendError({ type: "EXISTING_OPEN_TICKET_ERROR" });
      }
    } else {
      setLoading(() => {
        throw response.error;
      });
    }
    setLoading(false);
  };

  const dateOptions = dates.map((o) => ({
    value: o.value,
    text: o.text,
  }));

  if (!zones) {
    return null;
  }

  const renderErrorMessage = (error: CreateCompanyTicketErrorType) => {
    if (error.type === "QUARANTEEN_ERROR") {
      const formattedLastOrderExpirationDate = error.lastOrder.expirationDate ? formatter.date.toShortDateString(error.lastOrder.expirationDate) : "–";
      const formattedExpectedQuaranteenEndDate = formatter.date.toShortDateString(error.expectedQuaranteenEndDate);
      return <FieldValidationError text={lang.gapToSmall(formattedLastOrderExpirationDate, formattedExpectedQuaranteenEndDate)} />;
    } else {
      return <FieldValidationError text={lang.errorExistingOpenTicket} />;
    }
  };


  return <Container width="xs" data-test-id="housing-association-ticket-form" >
    <h1>{lang.title}</h1>
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <PhoneFormInput name="phone" required label={formLang.phone} mobileOnly />
        <TextFormInput name="firstName" required minLength={2} maxLength={50} label={formLang.firstName} />
        <TextFormInput name="lastName" required minLength={2} maxLength={50} label={formLang.lastName} />
        <TextFormInput name="employeeNumber" required minLength={2} maxLength={20} label={ticketOwnerFieldName} />
        {authContext.features.getZoneInput(zones)}
        <DropdownFormInput name="startDate" required label={formLang.startDate}>
          {dateOptions.map(option => <option value={option.value} key={option.value}>{option.text}</option>)}
        </DropdownFormInput>
        {backendError && renderErrorMessage(backendError)}
        <ButtonGroup>
          <SubmitButton submitting={loading} text={lang.orderTicket} />
          <CancelButton onClick={() => { navigate("/borettslag"); }} />
        </ButtonGroup>
      </form>
    </FormProvider>
  </Container>;
};

export default HousingAssociationTicketForm;