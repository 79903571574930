import { Tenant } from "src/types/features/Tenant";



export interface Urls {
  cookies: string,
  travelTerms: string,
  privacy: string
  contactUs: ContactUsUrlType
  terms?: string
}

interface ContactUsUrlType {
  external: string
  internal?: string
}

export const getExternalContactUsUrl = (tenant: Tenant): string => {
  switch (tenant) {
    case Tenant.Ruter:
      return "https://ruter.no/kjop-billett/storkunde/skjemaoversikt/stille-et-sporsmal/#page=form";
    case Tenant.Brakar:
      return "https://brakar.no/kundesenter/";
    case Tenant.Akt:
      return "https://www.akt.no/kundeservice/kontakt-oss/";
  }
};

const getTravelTermsUrl = (tenant: Tenant) => {
  switch (tenant) {
    case Tenant.Ruter:
      return "https://ruter.no/fa-hjelp/vilkar/reisevilkar/";
    case Tenant.Akt:
      return "https://www.akt.no/kundeservice/rettigheter-og-regler/transportvilkar-for-akt/";
    case Tenant.Brakar:
      return "https://brakar.no/reiseinformasjon/transportvedtekter/";
  }
};

const getCookiesUrl = (tenant: Tenant) => {
  switch (tenant) {
    case Tenant.Ruter:
      return "https://ruter.no/fa-hjelp/vilkar/personvern/ruter.no/";
    case Tenant.Brakar:
      return "https://brakar.no/personvern/informasjonskapsler/";
    case Tenant.Akt:
      return "https://www.akt.no/kundeservice/rettigheter-og-regler/personvern-i-agder-kollektivtrafikk-akt/informasjonskapsler/";
  }
};

const getPrivacyUrl = (tenant: Tenant) => {
  switch (tenant) {
    case Tenant.Ruter:
      return "https://ruter.no/fa-hjelp/vilkar/personvern/";
    case Tenant.Akt:
      return "https://www.akt.no/kundeservice/rettigheter-og-regler/personvern-i-agder-kollektivtrafikk-akt/";
    case Tenant.Brakar:
      return "https://brakar.no/personvern/";
  }
};

const calculateContactUs = (tenant: Tenant): ContactUsUrlType => {
  return {
    external: getExternalContactUsUrl(tenant),
    internal: tenant === Tenant.Ruter ? "/kontaktoss" : undefined,
  };
};


export const calculateUrls = (tenant: Tenant): Urls => {
  return {
    cookies: getCookiesUrl(tenant),
    privacy: getPrivacyUrl(tenant),
    travelTerms: getTravelTermsUrl(tenant),
    contactUs: calculateContactUs(tenant),
    terms: tenant === Tenant.Ruter ? "/vilkar/ruterbedrift" : undefined,
  };
};

export const getValidUrls = (urls: Partial<Urls> = {}): Urls => ({
  ...calculateUrls(Tenant.Ruter),
  ...urls,
});

