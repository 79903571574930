import { LanguageResource, languageResourceBuilder, OneParamLanguageResourceTemplate, TwoParamsLanguageResourceTemplate } from "@ruter-as/web-components-and-tools";

interface CompanyPageLanguageResource {
  agreements: string;
  agreementNumber: string;
  agreementInvoideRef: string;
  agreementType: string;
  edit: string;
  newAgreement: string;
  editAgreement: string;
  subscriptions: string;
  ticketOffice: string;
  schoolTicket: string;
  freeTicket: string;
  noSubscriptions: string;
  travelCardSubscription: string;
  travelCardFixedPrice: string;
  housingAssociation: string
  invoicingInterval: string;
  invoicingIntervalDaily: string;
  invoicingIntervalWeekly: string;
  invoicingIntervalMonthlyAtEnd: string;
  invoicingIntervalQuarterly: string
  invoicingIntervalAnnually: string;
  invoiceReference: string;
  paymentDeadlineDaysLabel: string;
  paymentDeadlineDaysValidationLabel: string;
  progressDetails: {
    fetchingFreetickets: string;
    fetchingTicketOfficeTickets: string;
    fetchingCompanyTickets: string;
    fetchingInvoices: string
  },
  submitFailure: string;
  editAgreementButton: string;
  deleteAgreementButton: string;
  deleteAgreement: string;
  deleteAgreementConfirm: string;
  deleteFailure: string;
  deleteCompany: string;
  deleteCompanySuccess: string;
  deleteCompanyTitle: OneParamLanguageResourceTemplate;
  deleteCompanyConfirmationMessage: TwoParamsLanguageResourceTemplate;
  operatorCompanyName: OneParamLanguageResourceTemplate;
  subTypeIdValidationError: OneParamLanguageResourceTemplate;
}

const companyPageLanguageResource: LanguageResource<CompanyPageLanguageResource> = {
  nb: {
    agreements: "Avtaler",
    agreementNumber: "Avtalenummer",
    agreementInvoideRef: "Fakturareferanse",
    agreementType: "Type",
    edit: "Endre",
    newAgreement: "Ny avtale",
    editAgreement: "Endre avtale ($agreement$)",
    subscriptions: "Bedriftsbilletter mobil",
    ticketOffice: "Billettluke",
    schoolTicket: "Skolebilletter Oslo",
    freeTicket: "Fribilletter",
    noSubscriptions: "Uten abo",
    travelCardFixedPrice: "Bedrift fastpris reisekort",
    travelCardSubscription: "Bedrift abonnement",
    housingAssociation: "Borettslag",
    invoicingInterval: "Faktura intervall",
    invoicingIntervalDaily: "Faktureres daglig",
    invoicingIntervalWeekly: "Faktureres ukentlig",
    invoicingIntervalMonthlyAtEnd: "Faktureres i slutten av måneden",
    invoicingIntervalQuarterly: "Faktureres kvartalsvis",
    invoicingIntervalAnnually: "Faktureres årlig",
    invoiceReference: "Fakturareferanse",
    paymentDeadlineDaysLabel: "Forfallsdato (dager etter fakturadato)",
    paymentDeadlineDaysValidationLabel: "Forfallsdato",
    progressDetails: {
      fetchingCompanyTickets: "Bedriften har ingen bedriftsbilletter",
      fetchingFreetickets: "Bedriften har ingen fribilletter",
      fetchingInvoices: "Bedriften har ingen fakturaer",
      fetchingTicketOfficeTickets: "Bedriften har ingen billettluke billetter",
    },
    submitFailure: "Det skjedde en feil under lagring av avtale!",
    editAgreementButton: "Endre avtale",
    deleteAgreementButton: "Slett avtale",
    deleteAgreement: "Slett avtale ($agreement$)",
    deleteAgreementConfirm: "Er du sikker på at du vil slette avtale med id: $agreement$ og fakturareferanse: $invoiceReference$?",
    deleteFailure: "Det skjedde en feil under sletting av avtale!",
    deleteCompany: "Slett bedrift",
    deleteCompanySuccess: "Bedriften er slettet!",
    deleteCompanyTitle: languageResourceBuilder.oneParamTemplate("Slette bedrift med navn $p1$"),
    deleteCompanyConfirmationMessage: languageResourceBuilder.twoParamTemplate("Er du sikker på at du vil slette $p1$ med organisasjonsnummer $p2$?"),
    operatorCompanyName: languageResourceBuilder.oneParamTemplate("$p1$ (Operatør)"),
    subTypeIdValidationError: languageResourceBuilder.oneParamTemplate("Kan ikke legge til $p1$ avtale når det allerede eksiterer en fribillett, bedriftsbillett, skolebillett eller borettslag avtale"),
  },
  en: {
    agreements: "Agreements",
    agreementNumber: "Agreement number",
    agreementInvoideRef: "Invoice reference",
    agreementType: "Type",
    edit: "Edit",
    newAgreement: "New agreement",
    editAgreement: "Edit agreement ($agreement$)",
    subscriptions: "Company ticket mobile",
    ticketOffice: "Ticket counter",
    schoolTicket: "School tickets Oslo",
    freeTicket: "Free tickets",
    noSubscriptions: "No subscription",
    travelCardFixedPrice: "Company fixed price travel card",
    travelCardSubscription: "Company subscription",
    housingAssociation: "House owner association",
    invoicingInterval: "Invoicing interval",
    invoicingIntervalDaily: "Invoiced every day",
    invoicingIntervalWeekly: "Invoiced once a week",
    invoicingIntervalMonthlyAtEnd: "Invoice at end of month",
    invoicingIntervalQuarterly: "Invoiced quarterly",
    invoicingIntervalAnnually: "Invoiced once a year",
    invoiceReference: "Invoice reference",
    paymentDeadlineDaysLabel: "Due date (number of day after invoice date)",
    paymentDeadlineDaysValidationLabel: "Due date",
    progressDetails: {
      fetchingCompanyTickets: "Company has no company tickets",
      fetchingFreetickets: "Company has no free tickets",
      fetchingInvoices: "Company has no invoices",
      fetchingTicketOfficeTickets: "Company has no ticket office tickets",
    },
    submitFailure: "An error occured while saving new agreement!",
    editAgreementButton: "Edit agreement",
    deleteAgreementButton: "Delete agreement",
    deleteAgreement: "Delete agreement ($agreement$)",
    deleteAgreementConfirm: "Are you sure that you want to delete agreement with id: $agreement$ and invoice reference: $invoiceReference$?",
    deleteFailure: "An error occured while deleting the agreement!",
    deleteCompany: "Delete company",
    deleteCompanySuccess: "Company is succesfully deleted!",
    deleteCompanyTitle: languageResourceBuilder.oneParamTemplate("Delete company with name $p1$"),
    deleteCompanyConfirmationMessage: languageResourceBuilder.twoParamTemplate("Are your sure that you want delete company $p1$ with organization number $p2$?"),
    operatorCompanyName: languageResourceBuilder.oneParamTemplate("$p1$ (Sub contractor)"),
    subTypeIdValidationError: languageResourceBuilder.oneParamTemplate("Cannot add $p1$ agreement when there already exists a freeticket, companyticket, schoolticket or housing association agreement"),
  },
};

export default companyPageLanguageResource;