import { apiClient, Button, Container, SearchInput, Table, TablePagination, useLanguageResource } from "@ruter-as/web-components-and-tools";
import moment from "moment";
import React, { FormEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContextAuthenticated, useValidFreeTicketAgreementService } from "src/AuthContext";
import freeTicketApi from "src/common/api/freeTicketApi/freeTicketApi";
import usePaginationAndQuery from "src/common/usePaginationAndQuery";
import { ticketZonesLanguageResource } from "src/components/common/text/ticketZones/lang-resource";
import { getTicketZonesString } from "src/components/common/text/ticketZones/TicketZonesField";
import { SearchResult } from "src/types/SearchResult";
import { FreeTicket } from "../../../common/api/freeTicketApi/freeTicket";
import createAndDownloadCSV, { showNumberAsText } from "../../../common/csvFileCreator";
import { TicketHolderType } from "../../../types/freeTicketAgreement/ticketHolderType";
import MediaType from "../../../types/mediaType";
import { ButtonLink } from "../../common/buttons";
import GotoColumn from "../../common/Table/GotoColumn/GotoColumn";
import { formatPhonenumber } from "../../common/Table/PhoneColumn/PhoneColumn";
import StatusColumn from "../../common/Table/StatusColumn/StatusColumn";
import { freeTicketsFamilyLanguageResource } from "../freeTicketsFamily/lang-resource";
import { formatProfile } from "../freeTicketsFamily/MobileTicket";
import "./FreeTickets.scss";
import { freeTicketsLanguageResource } from "./lang-resource";
import MediaTypeColumn from "./MediaTypeColumn";

const FreeTickets: React.FC = () => {
  const lang = useLanguageResource(freeTicketsLanguageResource);
  const langFam = useLanguageResource(freeTicketsFamilyLanguageResource);
  const authContext = useAuthContextAuthenticated();
  const freeTicketService = useValidFreeTicketAgreementService();
  const selectedCompany = authContext.userData.selectedCompany;
  const companyName = selectedCompany.name;
  const navigate = useNavigate();
  const ticketZonesLanguage = useLanguageResource(ticketZonesLanguageResource);
  const [searchResult, setSearchResult] = useState<SearchResult<FreeTicket> | undefined>(undefined);
  const { setQuery, query, throttledQuery, pagination } = usePaginationAndQuery();
  const { page, pageSize } = pagination;
  const [loading, setLoading] = useState(true);

  const companyId = selectedCompany?.id;

  if (!companyId) throw new Error("CompanyId is undefined");

  const fetch = useCallback(async () => {
    setLoading(true);
    const response = await apiClient.request(freeTicketApi.ticket.byCompanyId.get(companyId, throttledQuery, page, pageSize));

    if (response.type === "success") {
      setSearchResult(response.result);
    } else {
      setSearchResult(() => {
        throw response.error;
      });
    }
    setLoading(false);

  }, [companyId, page, pageSize, throttledQuery]);

  useEffect(() => {
    fetch();
    return () => {
      setLoading(false);
      setSearchResult(undefined);
    };
  }, [fetch]);

  const onSubmitCallback = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetch();
  };

  const humanReadableRelation = (ticketHolderType: TicketHolderType): string => {
    switch (ticketHolderType) {
      case TicketHolderType.EMPLOYEE:
        return lang.employee;
      case TicketHolderType.FAMILY_MEMBER:
        return lang.familyMember;
      case TicketHolderType.RETIRED:
        return lang.retired;
      case TicketHolderType.UNKNOWN:
        return lang.unknown;
      default:
        throw new Error();
    }
  };

  const renderRelationColumn = (freeTicket: FreeTicket) => {
    const text = humanReadableRelation(freeTicket.ticketHolderType);
    return <td>{text}</td>;
  };

  const renderRow = (freeTicket: FreeTicket) => (
    <tr key={freeTicket.id} onClick={() => navigate(`/fribilletter/${freeTicket.id}`)}>
      <td>{freeTicket.firstName}</td>
      <td>{freeTicket.lastName}</td>
      <td>{freeTicket.employeeId}</td>
      <MediaTypeColumn freeTicket={freeTicket} />
      {renderRelationColumn(freeTicket)}
      <StatusColumn status={freeTicket.status} />
      <GotoColumn />
    </tr>
  );

  const download = async (filter: "active,pending" | "expired") => {
    const response = await apiClient.request(freeTicketApi.ticket.byCompanyId.download(companyId, filter));

    if (response.type !== "success") {
      throw response.error;
    }

    const freeTickets = response.result.matches;

    const getZoneFrom = (zoneFrom: string, allZones: boolean): string => {
      if (allZones) {
        return lang.all;
      }
      return zoneFrom;
    };
    const getZoneTo = (zoneTo: string, zoneFrom: string, nrOfZones: number, allZones: boolean): string => {
      if (allZones) {
        return lang.all;
      }
      if (nrOfZones === 1) {
        return zoneFrom;
      }

      return zoneTo;
    };

    const formatTravelCardNumber = (travelCardNumber: string | null) => {
      if (!freeTicketService.hasTravelCardNumber) {
        return "";
      }
      if (!travelCardNumber) {
        return "";
      }
      return travelCardNumber;
    };

    const phoneOrCardNumberHeader = freeTicketService.hasTravelCardNumber ? lang.phoneOrTravelCardNumber : lang.mobileNumber;

    const sortedFreeTickets = [...freeTickets];
    if (filter === "expired") {
      // sort by expirationDate descending
      sortedFreeTickets.sort((a, b) => {
        return (b.expirationDate?.getTime() || 0) - (a.expirationDate?.getTime() || 0);
      });
    }

    const csvModel = sortedFreeTickets.map((x) => [
      showNumberAsText(x.employeeId),
      x.firstName,
      x.lastName,
      x.employeeBirthDate ? moment(x.employeeBirthDate).format("L") : "",
      humanReadableRelation(x.ticketHolderType),
      x.mediaType === MediaType.MOBILE_TICKET ? formatPhonenumber(x.phoneCountryCode, x.phone) : formatTravelCardNumber(x.travelCardNumber),
      x.mediaType === MediaType.MOBILE_TICKET ? lang.mobileTicket : lang.travelCard,
      getZoneFrom(x.zoneFrom, x.allZones),
      getZoneTo(x.zoneTo, x.zoneFrom, x.nrOfZones || 1, x.allZones),
      formatProfile(x.profile, langFam),
      getTicketZonesString(ticketZonesLanguage, x.allZones, x.nrOfZones || 1, x.zoneFrom, x.zoneTo),
      moment(x.startDate).format("L"),
      x.expirationDate ? moment(x.expirationDate).format("L") : "-",
      x.invoiceReference || "",
    ]);

    const headers = [
      lang.employeeId,
      lang.firstName,
      lang.lastName,
      lang.birthDate,
      lang.relation,
      phoneOrCardNumberHeader,
      lang.mediaType,
      lang.zoneFrom,
      lang.zoneTo,
      lang.ticketProfile,
      lang.zones,
      lang.startDate,
      lang.endDate,
      lang.department,
    ];
    const dateAsString = moment().format("L");
    let fileName = `Aktive og ventende fribilletter for ${companyName} per ${dateAsString}.csv`;
    if (filter === "expired") {
      fileName = `Avsluttede fribilletter for ${companyName} per ${dateAsString}.csv`;
    }

    createAndDownloadCSV(fileName, headers, csvModel);
  };

  return (
    <Container width="m" data-test-id="components-freeticketagreement-freetickets" className="components-freeticketagreement-freetickets">
      <h1>{lang.title}</h1>
      <form data-testid="sok-form" onSubmit={onSubmitCallback}>
        <div className="search-and-buttons">
          <SearchInput value={query} onChange={(e) => setQuery(e.target.value)} />
          <Button
            text={lang.createTicket}
            type="button"
            data-test-id="create-ticket-button"
            variant="primary"
            onClick={() => navigate("/fribilletter/ny")}
            disabled={!freeTicketService.allowOrderTicket}
          />
        </div>
      </form>
      <div className="download">
        <ButtonLink
          text={lang.downloadActiveAndPendingAsCSV}
          onClick={() => download("active,pending")}
          dataTestId="download-active-and-pending-tickets-button"
        />
        <ButtonLink text={lang.downloadCancelledAsCSV} onClick={() => download("expired")} dataTestId="download-cancelled-tickets-button" />
      </div>
      <Table loading={loading}>
        <thead>
          <tr>
            <th>{lang.firstName}</th>
            <th>{lang.lastName}</th>
            <th>{lang.employeeId}</th>
            <th>{lang.mediaType}</th>
            <th>{lang.relationType}</th>
            <th>{lang.status}</th>
            <th className="goto-column" aria-label="goto-column" />
          </tr>
        </thead>
        <tbody>{searchResult?.matches.map(renderRow)}</tbody>
      </Table>
      <TablePagination pagination={pagination} totalRecords={searchResult?.totalEntries} />
    </Container>
  );
};

export default FreeTickets;
