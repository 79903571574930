import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface HousingUnitCostsLanguageResource {
  title: string
  downloadAsCSV: string
  changeType: string
  lastName: string
  firstName: string
  phone: string
  zones: string
  montlyPrice: string
  changeTypeChanged: string
  changeTypeCancelled: string
  changeTypeNew: string
}

export const housingUnitCostsLanguageResource: LanguageResource<HousingUnitCostsLanguageResource> = {
  nb: {
    title: "Kostnader pr. boenhet",
    montlyPrice: "Månedspris",
    firstName: "Fornavn",
    lastName: "Etternavn",
    zones: "Soner",
    downloadAsCSV: "Last ned som CSV",
    phone: "Telefonnr",
    changeType: "Årsak",
    changeTypeNew: "Ny",
    changeTypeCancelled: "Avsluttet",
    changeTypeChanged: "Endret",
  },
  en: {
    title: "Housing units costs",
    montlyPrice: "Price per month",
    firstName: "First name",
    lastName: "Last name",
    zones: "Zones",
    downloadAsCSV: "Download as CSV",
    phone: "Phone",

    changeType: "Cause",
    changeTypeNew: "New",
    changeTypeCancelled: "Cancelled",
    changeTypeChanged: "Changed",
  },
};