import { Tenant } from "src/types/features/Tenant";
import { AgreementInvoicingInterval } from "../AgreementInvoicingInterval";
import { AgreementSubTypeId } from "../AgreementSubTypeId";
import { Agreement } from "../Company";
import { Contact } from "../Contact";
import { UserRoleType } from "../user/UserRoleType";
import { EnvName } from "./EnvName";



export interface HasCompanyAdminAccess {
  hasAccess: true;
  agreementOptions: AgreementSubTypeId[]
  defaultAgreements: Agreement[]
  requireSignee: boolean
  showCustomerNumber: boolean
}

interface NoAccess {
  hasAccess: false
}

export type CompanyAdmin = HasCompanyAdminAccess | NoAccess;

const getAgreementOptions = (tenant: Tenant, env: EnvName): AgreementSubTypeId[] => {
  switch (tenant) {
    case Tenant.Ruter: return [
      AgreementSubTypeId.TRAVELCARD_SUBSCRIPTION,
      AgreementSubTypeId.TRAVELCARD_FIXED_PRICE,
      AgreementSubTypeId.SUBSCRIPTIONS,
      AgreementSubTypeId.TICKETOFFICE,
      AgreementSubTypeId.FREETICKET,
      AgreementSubTypeId.SCHOOLTICKET,
      AgreementSubTypeId.NO_SUBSCRIPTIONS,
      ...env !== "prod" ? [AgreementSubTypeId.HOUSING_ASSOCIATION] : [],
    ];
    case Tenant.Akt: return [AgreementSubTypeId.TICKETOFFICE, AgreementSubTypeId.FREETICKET];
    case Tenant.Brakar: return [AgreementSubTypeId.TICKETOFFICE, AgreementSubTypeId.FREETICKET];
  }
};

const getDefaultAgreements = (tenant: Tenant): Array<Agreement> => {
  const ticketOfficeAgreement: Agreement = {
    id: "1",
    invoiceReference: "",
    invoicingInterval: AgreementInvoicingInterval.MONTHLY_AT_END,
    paymentDeadline: 30,
    subTypeId: AgreementSubTypeId.TICKETOFFICE,
    fromDate: null,
    endDate: null,
  };

  const companyAgreement: Agreement = {
    id: "2",
    invoiceReference: "",
    invoicingInterval: AgreementInvoicingInterval.MONTHLY_AT_END,
    paymentDeadline: 30,
    subTypeId: AgreementSubTypeId.SUBSCRIPTIONS,
    fromDate: null,
    endDate: null,
  };

  const freeTicketAgreement: Agreement = {
    id: "3",
    invoiceReference: "",
    invoicingInterval: AgreementInvoicingInterval.MONTHLY_AT_END,
    paymentDeadline: 30,
    subTypeId: AgreementSubTypeId.FREETICKET,
    fromDate: null,
    endDate: null,
  };

  switch (tenant) {
    case Tenant.Ruter: return [ticketOfficeAgreement, companyAgreement];
    case Tenant.Akt: return [ticketOfficeAgreement];
    case Tenant.Brakar: return [ticketOfficeAgreement, freeTicketAgreement];
  }
};

export const calculateCompanyAdmin = (contact: Contact, tenant: Tenant, env: EnvName): CompanyAdmin => {
  if (!contact.roles.some((x) => x === UserRoleType.GlobalCompanyAdmin)) {
    return { hasAccess: false };
  }

  return {
    hasAccess: true,
    agreementOptions: getAgreementOptions(tenant, env),
    defaultAgreements: getDefaultAgreements(tenant),
    requireSignee: tenant === Tenant.Ruter && env !== "prod",
    showCustomerNumber: tenant !== Tenant.Ruter,
  };

};
