import { Icon, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { Dispatch, SetStateAction, useState } from "react";
import TicketZonesField from "src/components/common/text/ticketZones/TicketZonesField";
import { TravelCardState } from "src/types/TravelCardState";
import { UNKNOWN } from "../../../constants";
import { SchoolTicket, SchoolTicketTravelCard } from "../../../types/schoolTicketAgreement/schoolTicket";
import TicketStatus from "../../common/text/ticketStatus/TicketStatus";
import { concatName } from "../utils";
import shoolTicketDetailLanguageResource, { ShoolTicketDetailLanguageResource } from "./lang-resource";
import { formatCurrencyAmount, formatDate, formatStringOrNull } from "./MobileTicket";
import EditTicket from "./modals/EditTicket";
import ReplaceTicket from "./modals/ReplaceTicket";
import "./TravelCardTicket.scss";
interface Props {
  ticket: SchoolTicketTravelCard;
  cancelActiveTicket: Dispatch<SetStateAction<SchoolTicket | null>>;
  refetch: () => Promise<void>;
  cancelPendingTicket: Dispatch<SetStateAction<SchoolTicket | null>>;
}

const getStatus = (status: TravelCardState | typeof UNKNOWN, lang: ShoolTicketDetailLanguageResource): string => {
  if (status === TravelCardState.CREATED) {
    return lang.statuses.created;
  }
  if (status === TravelCardState.DEACTIVATED) {
    return lang.statuses.deactivated;
  }
  if (status === TravelCardState.REQUESTED) {
    return lang.statuses.requested;
  }
  if (status === TravelCardState.CANCELLED) {
    return lang.statuses.cancelled;
  }
  if (status === TravelCardState.ANONYMISED) {
    return lang.statuses.anonymised;
  }
  return lang.unknown;
};

const TravelCardTicket = ({ ticket, cancelActiveTicket, refetch, cancelPendingTicket }: Props) => {
  const lang = useLanguageResource(shoolTicketDetailLanguageResource);
  const fullName = concatName(ticket.firstName, ticket.lastName);
  const [showEditTicket, setShowEditTicket] = useState(false);
  const [showReplaceTicket, setShowReplaceTicket] = useState(false);
  const showCancelActiveTicketButton = !ticket.allowedActions.CANCEL;
  const showCancelPendingTicketButton = ticket.allowedActions.CANCEL;

  const enableEditButton =
    ticket.allowedActions.UPDATE_CARD_NAME ||
    ticket.allowedActions.UPDATE_CLASS_NAME ||
    ticket.allowedActions.UPDATE_NAME ||
    ticket.allowedActions.UPDATE_STUDENTID ||
    ticket.allowedActions.UPDATE_ADDRESS;

  const enableReplaceCardButton = ticket.allowedActions.RESEND;

  return (
    <div className="components-agreement-schoolticket-travelcard" data-test-id="travel-card-ticket">
      <div className={`status ${ticket.status}`} />
      <div className="student-name">
        <div className="value" title={fullName} data-test-id="full-name">
          {fullName || "–"}
        </div>
      </div>
      <div className="info">
        <div className="row">
          <div className="label">{lang.status}</div>
          <div className="value" data-test-id="ticket-status">
            <TicketStatus status={ticket.status} />
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.productionStatus}</div>
          <div className="value" data-test-id="travel-card-state">
            {getStatus(ticket.travelCardState, lang)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.cardName}</div>
          <div className="value" data-test-id="card-name">
            {formatStringOrNull(ticket.cardName)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.travelCardNumber}</div>
          <div className="value" data-test-id="travel-card-number">
            {formatStringOrNull(ticket.cardNumber)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.manufacturedAt}</div>
          <div className="value" data-test-id="manufactured-at">
            {formatDate(ticket.manufacturedAt)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.validFrom}</div>
          <div className="value" data-test-id="valid-from">
            {formatDate(ticket.startDate)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.validTo}</div>
          <div className="value" data-test-id="valid-to">
            {formatDate(ticket.expirationDate)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.pricePerMonth}</div>
          <div className="value" data-test-id="price-per-month">
            {formatCurrencyAmount(ticket, ticket.pricePerMonth)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.zones}</div>
          <div className="value" data-test-id="zones">
            <TicketZonesField
              allZones={ticket.allZones}
              
              nrOfZones={ticket.nrOfZones || 0}
              zoneFrom={ticket.zoneFrom}
              zoneTo={ticket.zoneTo}
            />
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.invoiceRef}</div>
          <div className="value" data-test-id="invoice-reference">
            {formatStringOrNull(ticket.invoiceReference)}
          </div>
        </div>
      </div>
      <div className="actions">
        <button type="button" disabled={!enableEditButton} onClick={() => setShowEditTicket(true)} data-test-id="edit-ticket-button">
          <Icon variant="PencilIcon" size="small" />
          <span>{lang.editTicket}</span>
        </button>

        <button onClick={() => setShowReplaceTicket(true)} disabled={!enableReplaceCardButton} data-test-id="replace-card-button">
          <Icon variant="ArrowsSwapIcon" size="small" />
          <span>{lang.replaceTicket}</span>
        </button>

        {showCancelActiveTicketButton && (
          <button
            type="button"
            disabled={!ticket.allowedActions.UPDATE_EXPIRY}
            onClick={() => {
              cancelActiveTicket(ticket);
            }}
            data-test-id="cancel-active-ticket-button"
          >
            <Icon variant="CrossIcon" size="small" />
            <span>{lang.cancelActiveTicket}</span>
          </button>
        )}

        {showCancelPendingTicketButton && (
          <button
            type="button"
            disabled={!ticket.allowedActions.CANCEL}
            onClick={() => {
              cancelPendingTicket(ticket);
            }}
            data-test-id="cancel-pending-ticket-button"
          >
            <Icon variant="CrossIcon" size="small" />
            <span>{lang.cancelPendingTicket}</span>
          </button>
        )}
      </div>
      {showEditTicket && (
        <EditTicket
          submitted={() => {
            setShowEditTicket(false);
            refetch();
          }}
          ticket={ticket}
          hide={() => setShowEditTicket(false)}
        />
      )}
      {showReplaceTicket && (
        <ReplaceTicket
          hide={() => {
            setShowReplaceTicket(false);
          }}
          submitted={() => {
            setShowReplaceTicket(false);
            refetch();
          }}
          ticket={ticket}
        />
      )}
    </div>
  );
};

export default TravelCardTicket;
