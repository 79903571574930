import { CompanyTicketService } from "src/types/features/services/CompanyTicketService";
import { HousingAssociationService } from "src/types/features/services/HousingAssociationService";

const getTicketOwnerFieldName = (companyTicketService: CompanyTicketService | HousingAssociationService): string => {
  const defaultValue = "Ansattnr";

  if (companyTicketService.hasService) {
    return companyTicketService.widgetSettings.employeeIdLabel || defaultValue;
  }
  return defaultValue;
};

export default getTicketOwnerFieldName;
