import { Label, SearchInput, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { Icon } from "@ruter-ds/rds-components";
import { ProfileIcon, ProfilePencilIcon, TickIcon, WalkExitIcon } from "@ruter-ds/rds-icons";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContextAuthenticated } from "src/AuthContext";
import { UserDataCompany } from "../../../types/AuthCompany";
import "./DropDownMenu.scss";
import { headerLanguageResource } from "./languageResource";
import { MenuState } from "./menuState";

interface Props {
  menu: MenuState,
  hideAccountMenu: () => void;
  showAccountMenu: () => void;
}

const DropDownMenu: React.FC<Props> = ({ menu, hideAccountMenu, showAccountMenu }) => {
  const ref = useRef<HTMLDivElement>(null);
  const authContext = useAuthContextAuthenticated();
  const { companies, contact, selectedCompany } = authContext.userData;
  const language = useLanguageResource(headerLanguageResource);
  const [filter, setFilter] = useState("");
  const navigate = useNavigate();
  const filteredCompanies = useMemo(() => {
    return companies.filter((x) => {
      if (x.id === selectedCompany?.id) {
        return false;
      }

      const searchTerms = filter.split(" ");

      return searchTerms.every((s) => x.name.toLowerCase().includes(s.toLowerCase()));
    });
  }, [companies, selectedCompany?.id, filter]);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (ref === null || ref.current === null) {
        return;
      }

      if (!ref.current.contains(event.target as Node | null)) {
        const open = menu.showAccountMenu;

        if (open) {
          setTimeout(() => hideAccountMenu(), 100);
        }
      }
    },
    [menu.showAccountMenu, hideAccountMenu],
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  if (!contact || !selectedCompany) {
    return null;
  }

  const handleMenuClick = (action: () => void) => {
    action();
    hideAccountMenu();
  };

  const toggleOpen = () => {
    const open = menu.showAccountMenu;

    if (open) {
      hideAccountMenu();
    } else {
      showAccountMenu();
    }
  };

  const renderCompanyButton = (company: UserDataCompany) => (
    <button
      key={company.id}
      type="button"
      className={selectedCompany.id === company.id ? "selected" : ""}
      onClick={() =>
        handleMenuClick(() => {
          if (authContext.authenticated) {
            authContext.userData.setSelectedCompany(company.id);
          }
        })
      }
      data-test-id={`company_${company.id}`}
    >
      <Icon component={<TickIcon />} size="medium" className="selected-icon" />
      <span className="company-name">{company.name}</span>
    </button>
  );

  const renderCompanySimpleView = () => {
    return (
      <div className="companies simple-view" data-test-id="drop-down-menu-companies" data-test-view-type="simple">
        {companies.map(renderCompanyButton)}
      </div>
    );
  };

  const renderCompanySearchView = () => {
    return (
      <div className="companies search-view" data-test-id="drop-down-menu-companies" data-test-view-type="search">
        <div className="selected-company">
          <div className="header">{language.chosenCompany}</div>
          <div className="value">{selectedCompany.name}</div>
        </div>
        <div className="search">
          <Label text={language.searchForCompany}>
            <SearchInput
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value);
              }}
            />
          </Label>
        </div>
        <div data-test-id="search-result" className="search-result">
          {filteredCompanies.length ? (
            filteredCompanies.map(renderCompanyButton)
          ) : (
            <div data-test-id="no-result" className="no-result">
              {language.noCompanyResult}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderCompanySelection = () => {
    if (companies.length > 10) {
      return renderCompanySearchView();
    }
    return renderCompanySimpleView();
  };

  const renderSubMenu = () => (
    <div className="dropdown-content">
      <button
        type="button"
        className="profile"
        onClick={() => handleMenuClick(() => navigate("/minside"))}
        data-test-id="my-page-button"
      >
        <Icon component={<ProfilePencilIcon />} size="medium" className="profile-icon" />
        {language.myPage}
      </button>
      <button type="button" data-test-id="logout-button" onClick={() => authContext.logout()}>
        <Icon component={<WalkExitIcon />} size="medium" className="logout-icon" />
        {language.logOut}
      </button>
      {renderCompanySelection()}
    </div>
  );

  return (
    <div className="components-layout-dropdownmenu" ref={ref}>
      <button type="button" onClick={toggleOpen} className="toogle-button" data-test-id="dropdown-menu-toggle-button">
        <Icon component={<ProfileIcon />} />
        <div className="text first-name">{contact.firstName}</div>
        <div className="text first-and-company-name">{`${contact.firstName} | ${selectedCompany.name}`}</div>
      </button>
      {menu.showAccountMenu && renderSubMenu()}
    </div>
  );
};

export default DropDownMenu;
