import { apiClient, Container, DateInput, formatter, parser, Table, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { addDays, addMonths, format, startOfMonth, startOfToday } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useAuthContextAuthenticated, useValidHousingAssociationService } from "src/AuthContext";
import housingAssociationApi from "src/common/api/companyAgreementApi/housingAssociation/housingAssociationApi";
import { HousingAssociationOrder } from "src/common/api/companyAgreementApi/housingAssociation/housingAssociationOrder";
import { HousingUnitCostChange } from "src/common/api/companyAgreementApi/housingAssociation/housingUnitCostChange";
import { HousingUnitCost } from "src/common/api/companyAgreementApi/housingAssociation/housingUnitCosts";
import { showNumberAsText } from "src/common/csvFileCreator";
import getTicketOwnerFieldName from "src/common/ticketOwnerFieldName";
import { ButtonLink } from "src/components/common/buttons";
import { ticketZonesLanguageResource } from "src/components/common/text/ticketZones/lang-resource";
import { getZonesText, TicketZonesComponent } from "src/components/common/text/ticketZones/TicketZones";
import "./HousingUnitCosts.scss";
import { housingUnitCostsLanguageResource } from "./lang-resource";

interface RowViewModel {
  id: string;
  price: string;
  employeeNumber: string;
  firstName: string;
  lastName: string;
  phone: string;
  zones: string;
  month: string;
  monthAsNumber: string;
  startDate: string;
  status: string;
}

const getMonth = (row: HousingUnitCost) => formatter.date.toMonthAndYear(new Date(row.year, row.month - 1));

const getMonthAsNumber = (row: HousingUnitCost) => format(new Date(row.year, row.month - 1), "yyyyMM");

const zonesAreEqual = (oldZones: Array<string>, newZones: Array<string>) => {
  if (oldZones.length !== newZones.length) {
    return false;
  }

  for (let i = 0; i < oldZones.length; i += 1) {
    if (oldZones[i] !== newZones[i]) {
      return false;
    }
  }

  return true;
};

const sorting = (rowA: RowViewModel, rowB: RowViewModel) => {
  const sortA = (rowA.lastName.padEnd(50, "0") + rowA.firstName).toLowerCase();
  const sortB = (rowB.lastName.padEnd(50, "0") + rowB.firstName).toLowerCase();

  if (sortA === sortB) {
    return 0;
  }

  if (sortA > sortB) {
    return 1;
  }

  return -1;
};


const HousingUnitCosts: React.FC = () => {
  const authContext = useAuthContextAuthenticated();
  const housingAssociationService = useValidHousingAssociationService();
  const ticketZonesLanguage = useLanguageResource(ticketZonesLanguageResource);
  const language = useLanguageResource(housingUnitCostsLanguageResource);
  const ticketOwnerFieldName = getTicketOwnerFieldName(housingAssociationService);
  const selectedCompany = authContext.userData.selectedCompany;
  const [fullHousingCosts, setFullHousingCosts] = useState<undefined | HousingUnitCost[]>();
  const [housingUnitCostChanges, setHousingUnitCostChanges] = useState<undefined | HousingUnitCostChange[]>();
  const [selectedDate, setSelectedDate] = useState(formatter.date.toShortDateString(startOfToday()));

  const fetch = useCallback(async () => {
    let parsedSelectedDate = startOfToday();

    try {
      parsedSelectedDate = parser.date.fromShortDateString(selectedDate);
    } catch (e) {

    }
    const currentMonth = addDays(startOfMonth(parsedSelectedDate), 6);
    const previousMonth = addMonths(currentMonth, -1);

    const [fullPayrollResponse, payrollChangesResponse] = await Promise.all([
      apiClient.request(housingAssociationApi.invoice.getByAgreementAndDate(housingAssociationService.agreementNumber, parsedSelectedDate)),
      apiClient.request(housingAssociationApi.invoice.getChangesByAgreementNumberAndDate(housingAssociationService.agreementNumber, previousMonth, currentMonth)),
    ]);

    if (fullPayrollResponse.type !== "success") {
      setFullHousingCosts(() => { throw fullPayrollResponse.error; });
      return;
    }
    if (payrollChangesResponse.type !== "success") {
      setHousingUnitCostChanges(() => { throw payrollChangesResponse.error; });
      return;
    }

    setFullHousingCosts(fullPayrollResponse.result);
    setHousingUnitCostChanges(payrollChangesResponse.result);
  }, [housingAssociationService.agreementNumber, selectedDate]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const getZones = (order: HousingAssociationOrder): string =>
    getZonesText({
      language: ticketZonesLanguage,
      nrOfZones: order.nrOfZones,
      zones: order.zones,
    });

  const getViewModel = (rows: Array<HousingUnitCost>): Array<RowViewModel> =>
    rows
      .map((r) => ({
        id: r.order.id,
        price: r.orderLinePrice.toFixed(0),
        employeeNumber: r.order.employeeId,
        firstName: r.order.firstName,
        lastName: r.order.lastName,
        phone: r.order.phone,
        zones: getZones(r.order),
        month: getMonth(r),
        monthAsNumber: getMonthAsNumber(r),
        startDate: formatter.date.toShortDateString(r.order.startDate),
        status: r.order.status,
      }))
      .sort(sorting);

  const download = () => {
    if (!fullHousingCosts) {
      return;
    }

    const viewModel = getViewModel(fullHousingCosts);
    const csvModel = viewModel.map((x) => [x.lastName, x.firstName, x.phone, showNumberAsText(x.employeeNumber), x.zones, x.price]);

    let csvFile = "data:text/csv;charset=utf-8,\uFEFF";
    const headers = [language.lastName, language.firstName, language.phone, ticketOwnerFieldName, language.zones, language.montlyPrice];
    const headerFile = headers.join(";");
    csvFile += `${headerFile}\r\n`;

    const rows: string[] = [];
    csvModel.forEach((row) => {
      rows.push(row.join(";"));
    });

    csvFile += rows.join("\r\n");

    const fileName = `${selectedCompany?.name} ${selectedDate}.csv`;
    const file = encodeURI(csvFile);
    const link = document.createElement("a");
    link.setAttribute("href", file);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  };

  const renderChangeRow = (change: HousingUnitCostChange) => {
    const rowId = (change.oldOrder && change.oldOrder.id) || (change.newOrder && change.newOrder.id);

    const oldPrice = change.oldOrder ? formatter.number.integer(Math.round(change.oldOrder.price)) : undefined;
    const newPrice = change.newOrder ? formatter.number.integer(Math.round(change.newOrder.price)) : undefined;

    if (change.changeType === "SUBSCRIBED" && change.newOrder) {
      return (
        <tr key={rowId}>
          <td>{language.changeTypeNew}</td>
          <td>{change.newOrder.lastName}</td>
          <td>{change.newOrder.firstName}</td>
          <td>{change.newOrder.phone}</td>
          <td>{change.newOrder.employeeId}</td>
          <td>
            <span className="new-value">
              <TicketZonesComponent nrOfZones={change.newOrder.numberOfZones} zones={change.newOrder.zones}  />
            </span>
          </td>
          <td style={{ textAlign: "right" }}>
            <span className="price-prefix">kr</span>
            <span className="new-value">{newPrice}</span>
          </td>
        </tr>
      );
    }
    if (change.changeType === "UNSUBSCRIBED" && change.oldOrder) {
      return (
        <tr key={rowId}>
          <td>{language.changeTypeCancelled}</td>
          <td>{change.oldOrder.lastName}</td>
          <td>{change.oldOrder.firstName}</td>
          <td>{change.oldOrder.phone}</td>
          <td>{change.oldOrder.employeeId}</td>
          <td>
            <span className="old-value">
              <TicketZonesComponent nrOfZones={change.oldOrder.numberOfZones} zones={change.oldOrder.zones} />
            </span>
          </td>
          <td style={{ textAlign: "right" }}>
            <span className="price-prefix">kr</span>
            <span className="old-value">{oldPrice}</span>
          </td>
        </tr>
      );
    }

    // ChangePrice
    if (change.oldOrder === null || change.newOrder === null) {
      throw new Error("oldOrder and newOrder can not both be null");
    }

    const priceChange = oldPrice !== newPrice;
    const zoneChanged =
      change.oldOrder.numberOfZones !== change.newOrder.numberOfZones || !zonesAreEqual(change.oldOrder.zones, change.newOrder.zones);

    return (
      <tr key={rowId}>
        <td>{language.changeTypeChanged}</td>
        <td>{change.newOrder.lastName}</td>
        <td>{change.newOrder.firstName}</td>
        <td>{change.newOrder.phone}</td>
        <td>{change.newOrder.employeeId}</td>
        <td>
          {zoneChanged && (
            <span className="old-value">
              <TicketZonesComponent nrOfZones={change.oldOrder.numberOfZones} zones={change.oldOrder.zones} />
            </span>
          )}

          <span className="new-value">
            <TicketZonesComponent nrOfZones={change.newOrder.numberOfZones} zones={change.newOrder.zones} />
          </span>
        </td>
        <td style={{ textAlign: "right" }}>
          <span className="price-prefix">kr</span>
          {priceChange && <span className="old-value">{oldPrice}</span>}
          <span className="new-value">{newPrice}</span>
        </td>
      </tr>
    );
  };

  const showDatePicker = housingAssociationService.housingUnitCosts.showDatePicker;

  return (
    <Container width="l" className="components-housing-unit-costs" data-test-id="components-housing-unit-costs">
      <h1>{`${language.title} - ${selectedDate}`}</h1>

      {showDatePicker && (
        <DateInput value={selectedDate} onChange={(newDate) => setSelectedDate(newDate)} />
      )}

      <div className="download">
        <ButtonLink text={language.downloadAsCSV} onClick={download} dataTestId="download-button" />
      </div>

      <Table
        breakpoint="600px"
        loading={housingUnitCostChanges === undefined}
        data-test-id="changes-table"
      >
        <thead>
          <tr>
            <th scope="col">{language.changeType}</th>
            <th scope="col">{language.lastName}</th>
            <th scope="col">{language.firstName}</th>
            <th scope="col">{language.phone}</th>
            <th scope="col">{ticketOwnerFieldName}</th>
            <th scope="col">{language.zones}</th>
            <th scope="col" style={{ textAlign: "right" }}>
              {language.montlyPrice}
            </th>
          </tr>
        </thead>
        <tbody>{housingUnitCostChanges?.map(renderChangeRow)}</tbody>
      </Table>
    </Container>
  );
};

export default HousingUnitCosts;