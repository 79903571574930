import { AgreementSettings } from "src/types/AuthCompany";
import { mapTicketRequestSearchResult } from "src/types/companyAgreement/ticketRequest";
import { mapZones } from "../../commonTypes/Zone";
import { addPaginationParameters, addQueryParameters } from "../../tools";
import { mapHousingAssociationInvoices } from "./housingAssociationInvoice";
import { mapHousingAssociationOrderSearchResult } from "./housingAssociationOrder";
import HousingAssociationPostContract from "./housingAssociationPostContract";
import { HousingAssociationTicketRequestJson } from "./housingAssociationTicketRequest";
import { CreateHousingAssociationWidgetContract, mapHousingAssociationWidget } from "./housingAssociationWidget";
import { mapHousingUnitCostChangeArray } from "./housingUnitCostChange";
import { mapHousingCostArray } from "./housingUnitCosts";

const housingAssociationApi = {
  auth: {
    widget: {
      createWidget: (body: CreateHousingAssociationWidgetContract) => ({
        method: "post" as const,
        url:"/api/auth/widget/create", 
        body, 
        mapper: mapHousingAssociationWidget,
      }),
    },
  },
  ticket: {
    getTicketsByCompanyId: (companyId: string, query: string, page: number, pageSize: number) => {
      return {
        method: "get" as const,
        url: `/api/ticket/by-company-id/${companyId}?${addPaginationParameters(page, pageSize)}&${addQueryParameters(query)}`,
        mapper: mapHousingAssociationOrderSearchResult,
      };
    },
    getTicketsByTicketId: (ticketId: string) => ({
      method: "get" as const,
      url: `/api/ticket/with-same-employee-id-as-this-ticket/${ticketId}`,
      mapper: mapHousingAssociationOrderSearchResult,
    }),
    create: (body: HousingAssociationPostContract) => ({
      method: "post" as const,
      url: `/api/ticket/create`,
      body,
    }),
  },
  widget: {
    getHousingAssociationWidgetByCompanyId: (companyId: string ) => ({
      method: "get" as const,
      url: `/api/widget/by-company-id/${companyId}`,
      mapper: mapHousingAssociationWidget,
    }),
  },
  settings: {
    post: (contract: AgreementSettings) => ({
      method: "post" as const,
      url: "/api/company-agreement/settings",
      body: contract,
    }),
  },
  ticketRequest: {
    getByCompanyId: (companyId: string) => {
      return {
        method: "get" as const,
        url: `/api/ticket-request/by-company-id/${companyId}`,
        mapper: mapTicketRequestSearchResult,
      };
    },
    reject: (ticketRequestId: string) => ({
      method: "delete" as const,
      url: `/api/ticket-request/${ticketRequestId}`,
    }),
    approve: (ticketRequest: HousingAssociationTicketRequestJson) => ({
      method: "post" as const,
      url: `/api/ticket-request/approve`,
      body: ticketRequest,
    }),
  },
  invoice: {
    getByAgreementAndDate: (agreementNumber: string, date: Date) => ({
      method: "get" as const,
      url: `/api/invoice/orders/${agreementNumber}/${encodeURIComponent(date.toISOString())}`,
      mapper: mapHousingCostArray,
    }),
    getChangesByAgreementNumberAndDate: (agreementNumber: string, oldDate: Date, newDate: Date) => ({
      method: "get" as const,
      url: `/api/invoice/changed-subscriptions?agreement=${agreementNumber}&oldDate=${encodeURIComponent(oldDate.toISOString())}&newDate=${encodeURIComponent(newDate.toISOString())}`,
      mapper: mapHousingUnitCostChangeArray,
    }),
    getByTicketId: (ticketId: string) => ({
      method: "get" as const,
      url: `/api/invoice/by-ticket-id/${ticketId}`,
      mapper: mapHousingAssociationInvoices,
    }),
  },
  zones: {
    getAll: () => ({
      method: "get" as const,
      url: "/api/zone/list",
      mapper: mapZones,
    }),
  },
};

export default housingAssociationApi;