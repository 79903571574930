import { useLanguageResource } from "@ruter-as/web-components-and-tools";
import { FormGroup, Radio, RadioList } from "@ruter-ds/rds-components";
import moment from "moment";
import React, { ChangeEvent, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useValidFreeTicketAgreementService } from "src/AuthContext";
import { UNKNOWN } from "../../../constants";
import { FreeTicketProfile, parseFreeTicketProfileFromString } from "../../../types/freeTicketAgreement/freeTicketProfile";
import { parseTicketHolderType, TicketHolderType } from "../../../types/freeTicketAgreement/ticketHolderType";
import { freeTicketLanguageResource } from "./lang-resource";

const TicketProfileInput: React.FC = () => {
  const freeTicketService = useValidFreeTicketAgreementService();
  const { register, setValue } = useFormContext();
  const lang = useLanguageResource(freeTicketLanguageResource);
  const profile = useWatch({ name: "profile" });
  const dateOfBirth = useWatch({ name: "dateOfBirth" });
  const ticketHolderType = useWatch({ name: "ticketHolderType" });

  useEffect(() => {
    register("profile");

    if (!profile) {
      setValue("profile", FreeTicketProfile.ADULT);
    }
    if (!ticketHolderType) {
      setValue("ticketHolderType", TicketHolderType.EMPLOYEE);
    }
  }, [register, setValue, profile, ticketHolderType]);

  if (!ticketHolderType || !profile) {
    return null;
  }

  let validValues: FreeTicketProfile[] = [];

  const parsedDate = moment(dateOfBirth, "DD.MM.YYYY", true);
  const parsedProfile = parseFreeTicketProfileFromString(profile);
  const parsedTicketHolderType = parseTicketHolderType(ticketHolderType);

  if (parsedDate.isValid() && parsedProfile !== UNKNOWN && parsedTicketHolderType) {
    validValues = freeTicketService.getValidProfiles(parsedDate.toDate(), parsedTicketHolderType);
    if (!validValues.some((x) => x === parsedProfile)) {
      setValue("profile", validValues[0]);
    }
  }

  const adultEnabled = validValues.length ? validValues.some((x) => x === FreeTicketProfile.ADULT) : true;
  const childEnabled = validValues.length ? validValues.some((x) => x === FreeTicketProfile.CHILD) : true;
  const discountRateEnabled = validValues.length ? validValues.some((x) => x === FreeTicketProfile.RETIRED) : true;

  return (
    <FormGroup>
      <RadioList
        radioName="profile"
        groupTitle={lang.ticketProfile}
        selectedValue={profile}
        data-test-id="profile-input"
        onChange={(event: ChangeEvent<HTMLInputElement>) => setValue("profile", event.target.value)}
      >
        <Radio value={FreeTicketProfile.ADULT} label={lang.adult} disabled={!adultEnabled} />
        <Radio value={FreeTicketProfile.RETIRED} label={lang.reducedRate} disabled={!discountRateEnabled} />
        <Radio value={FreeTicketProfile.CHILD} label={lang.child} disabled={!childEnabled} />
      </RadioList>
    </FormGroup>
  );
};

export default TicketProfileInput;
