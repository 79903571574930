import { apiClient, Button, ButtonGroup, Card, Container, Dropdown, FormGroup, Label, Message, Modal, ProgressRadial, useLanguageResource } from "@ruter-as/web-components-and-tools";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAuthContextAuthenticated, useValidFreeTicketAgreementService } from "src/AuthContext";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import { FreeTicket, mapFreeTicketSearchResult } from "../../../common/api/freeTicketApi/freeTicket";
import createAndDownloadCSV from "../../../common/csvFileCreator";
import readCsvFile from "../../../common/csvFileReader";
import { getExpiryDates } from "../../../common/expiryDate";
import formatter from "../../../common/formatter";
import successAlert from "../../../common/toastr";
import { TicketStatus } from "../../../types/freeTicketAgreement/ticketStatus";
import MediaType from "../../../types/mediaType";
import { SearchResult } from "../../../types/SearchResult";
import { CancelButton } from "../../common/buttons";
import PageHeaderComponent from "../../common/layout/PageHeader";
import { massChangeDepartmentLanguageResource } from "./lang-resource";
import "./MassChangeDepartment.scss";

interface InvalidFileRow {
  type: "invalid";
  employeeNumber: string;
  birthDate: string;
  department: string;
}

interface ValidRow {
  type: "valid";
  employeeNumber: string;
  birthDate: Date;
  department: string;
}

type FileRow = InvalidFileRow | ValidRow;

interface File {
  name: string;
  rows: FileRow[];
}

const MassChangeDepartment: React.FC = () => {
  const authContext = useAuthContextAuthenticated();
  const companyId = authContext.userData.selectedCompany.id;
  const freeTicketService = useValidFreeTicketAgreementService();
  if (!companyId) {
    throw new Error("company should be set");
  }

  const [showModal, setShowModal] = useState(false);
  const [ticketChangeNumber, setTicketChangeNumber] = useState<number>(0);
  const [tickets, setTickets] = useState<undefined | SearchResult<FreeTicket>>(undefined);
  const lang = useLanguageResource(massChangeDepartmentLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const formMethods = useForm<FormData>();
  const [file, setFile] = useState<File | null>(null);
  const dates = getExpiryDates(32, formLang.today);
  const [changeDate, setChangeDate] = useState(dates[0].date);

  const fetch = useCallback(async () => {
    const response = await apiClient.get(`/freeticket-api/ticket/by-company-id/${companyId}`, mapFreeTicketSearchResult);
    if (response.type === "success") {
      setTickets(response.result);
    } else {
      setTickets(() => {
        throw response.error;
      });
    }
  }, [companyId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  if (!tickets) {
    return null;
  }

  const activeAndPendingTicketsFilter = (freeTicket: FreeTicket): boolean => {
    return freeTicket.status === TicketStatus.active || freeTicket.status === TicketStatus.pending;
  };

  const getFileRowByFreeTicket = (freeTicket: FreeTicket): FileRow | undefined => {
    const isMatch = (row: FileRow): boolean => {
      if (row.type !== "valid") {
        return false;
      }

      const employeeIdMatch = row.employeeNumber.trim().toLocaleLowerCase() === freeTicket.employeeId.trim().toLocaleLowerCase();
      const freeTicketBirthDate = moment(freeTicket.employeeBirthDate).format("DD.MM.YYYY");
      const fileRowBirthDate = moment(row.birthDate).format("DD.MM.YYYY");

      return freeTicketBirthDate === fileRowBirthDate && employeeIdMatch;
    };

    return file?.rows.find(isMatch);
  };

  const fileValidFilter = (freeTicket: FreeTicket): boolean => {
    if (!file) {
      return false;
    }
    const matchingFileRow = getFileRowByFreeTicket(freeTicket);
    return Boolean(matchingFileRow);
  };

  const fileRequireChangeFilter = (freeTicket: FreeTicket): boolean => {
    if (!file) {
      return false;
    }
    const matchingFileRow = getFileRowByFreeTicket(freeTicket);
    if (!matchingFileRow) {
      return false;
    }

    return matchingFileRow.department.toLocaleLowerCase() !== freeTicket.invoiceReference?.toLocaleLowerCase();
  };

  const endDateFilter = (freeTicket: FreeTicket): boolean => {
    return freeTicket.expirationDate === null || freeTicket.expirationDate.getTime() > moment(changeDate).endOf("day").toDate().getTime();
  };

  const ticketsMatchingFile = tickets.matches.filter(fileValidFilter);
  const ticketsMatchingFileAndRequireChange = ticketsMatchingFile.filter(fileRequireChangeFilter);
  const ticketsMatchingFileAndRequireChangeAndEndDate = ticketsMatchingFileAndRequireChange
    .filter(activeAndPendingTicketsFilter)
    .filter(endDateFilter);
  const eligbleTickets = [...ticketsMatchingFileAndRequireChangeAndEndDate];

  const getAgreementNumberByName = (agreementName: string): string | undefined => {
    const agreement = freeTicketService.invoiceRefs.find((x) => x.invoiceReference.trim().toLowerCase() === agreementName.trim().toLowerCase());
    return agreement?.agreementNumber;
  };

  const changeDepartmentForTickets = async () => {
    let currentTicketNumber = 1;
    for (const ticket of eligbleTickets) {
      setTicketChangeNumber(currentTicketNumber);

      const timeOfChange = ticket.mediaType === MediaType.MOBILE_TICKET ? changeDate.toISOString() : null;
      const fileRow = getFileRowByFreeTicket(ticket);

      if (!fileRow) {
        throw new Error("could not find correspending file row");
      }

      const newAgreementNumber = getAgreementNumberByName(fileRow.department);

      if (!newAgreementNumber) {
        throw new Error(`could not find agreement with name=${fileRow.department}`);
      }

      const response = await apiClient.post(`/freeticket-api/ticket/${ticket.id}/update-agreement-number`, {
        agreementNumber: newAgreementNumber,
        timeOfChange,
      });

      if (response.error) {
        setTicketChangeNumber(() => {
          throw response.error;
        });
      }


      currentTicketNumber += 1;
    }
    successAlert(lang.ticketsChangeSuccessToastr.replace("${ticketCount}", eligbleTickets.length.toString()));
    await fetch();
    setShowModal(false);
    setFile(null);
    setTicketChangeNumber(0);
  };

  const showNoTicketsMessage = eligbleTickets.length === 0 && file;
  const showButton = eligbleTickets.length > 0 && file;

  const replaceDateInText = (template: string) => {
    return template.replace("${changeDate}", formatter.date.shortDate(changeDate));
  };

  const renderSummaryItem = (text: string, value: number, dataTestId: string, highLightPositiveNumber: boolean, bold: boolean) => {
    const className = `row ${highLightPositiveNumber && value > 0 ? "red " : ""} ${bold ? "bold" : ""}`;

    return (
      <div className={className}>
        <div className="label">{text}</div>
        <div className="value" data-test-id={dataTestId}>
          {value}
        </div>
      </div>
    );
  };

  const readFile = async (files: FileList | null) => {
    const validDepartmentName = freeTicketService.invoiceRefs.map((x) => x.invoiceReference.toLowerCase());
    const mapRow = (row: string[]): FileRow => {
      let valid = true;

      if (row[0].trim().length === 0) {
        valid = false;
      }
      const parsedDate = moment(row[1]?.trim(), "DD.MM.YYYY");
      if (!parsedDate.isValid()) {
        valid = false;
      }

      if (!validDepartmentName.some((x) => x.toLocaleLowerCase() === row[2]?.trim().toLowerCase())) {
        valid = false;
      }

      if (valid) {
        return {
          type: "valid",
          employeeNumber: row[0],
          birthDate: parsedDate.toDate(),
          department: row[2],
        };
      } else {
        return {
          type: "invalid",
          employeeNumber: row[0],
          birthDate: row[1],
          department: row[2],
        };
      }
    };

    const result = await readCsvFile(files);
    setFile({
      name: result.name,
      rows: result.data.map(mapRow),
    });
  };

  const downloadExample = () => {
    createAndDownloadCSV(
      `${lang.exampleFileName}.csv`,
      [lang.employeeNumber, lang.birthDate, lang.department],
      [["NO123", "02.04.1984", freeTicketService.invoiceRefs[0].invoiceReference]],
    );
  };

  const fileRowCount = file ? file.rows.length : 0;
  const fileInvalidRowCount = file ? file.rows.filter((x) => x.type === "invalid").length : 0;
  const fileValidRowCount = fileRowCount - fileInvalidRowCount;
  const fileChangeNotRequired = file ? ticketsMatchingFile.length - ticketsMatchingFileAndRequireChange.length : 0;
  const fileUnmatchedRows = file ? fileValidRowCount - ticketsMatchingFileAndRequireChange.length - fileChangeNotRequired : 0;

  return (
    <Container
      width="s"
      className="components-freeticketagreement-masschangedepartment"
      data-test-id="free-ticket-mass-change-department-page"
    >
      <PageHeaderComponent text={lang.title} loading={false} />

      <div>
        <div className="file-header">{lang.fileHeader}</div>
        <div className="file-description">
          <span>{lang.fileDescriptionPreLink}</span>
          <button className="file-example" data-test-id="download-example" onClick={downloadExample}>
            {lang.fileDesciptionLink}
          </button>
          <span>{lang.fileDescriptionPostLink}</span>
        </div>

        {!file && (
          <>
            <input id="file" type="file" value="" onChange={(event) => readFile(event.target.files)} />
            <label htmlFor="file">{lang.addFromCsv}</label>
          </>
        )}

        {file && (
          <>
            <Card className="summary" data-test-id="file-filter-tickets-calculation">
              <div className="header-row">
                <div className="file-name" data-test-id="file-name">
                  {file.name}
                </div>
                <button className="remove-file" data-test-id="remove-file-button" onClick={() => setFile(null)} type="button">
                  {lang.remove}
                </button>
              </div>
              {renderSummaryItem(lang.rowInFileCount, fileRowCount, "row-count", false, false)}
              {renderSummaryItem(lang.invalidRowCount, fileInvalidRowCount, "invalid-row-count", true, false)}
              {renderSummaryItem(lang.validRowCount, fileValidRowCount, "valid-row-count", false, false)}
              {renderSummaryItem(lang.unmatcedRows, fileUnmatchedRows, "unmatched-row-count", true, false)}
              {renderSummaryItem(lang.changeNotRequired, fileChangeNotRequired, "no-change-row-count", true, false)}
              {renderSummaryItem(
                lang.ticketsEligibleCount,
                ticketsMatchingFileAndRequireChange.length,
                "eligible-tickets-count",
                false,
                true,
              )}
            </Card>
            <FormProvider {...formMethods}>
              <div className="narrow">
                <FormGroup>
                  <Label text={lang.changeDate}>
                    <Dropdown name="changeDate" value={changeDate.toISOString()} onChange={(e) => setChangeDate(new Date(e.target.value))}>
                      {dates.map((d) => (
                        <option value={d.date.toISOString()} key={d.value}>
                          {d.text}
                        </option>
                      ))}
                    </Dropdown>
                  </Label>
                </FormGroup>
                <Card className="summary" data-test-id="change-date-filter-tickets-calculation">
                  {renderSummaryItem(lang.freeTicketCount, ticketsMatchingFileAndRequireChange.length, "all-tickets-count", false, false)}
                  {renderSummaryItem(
                    replaceDateInText(lang.ticketsFilteredByChangeDate),
                    ticketsMatchingFileAndRequireChange.length - eligbleTickets.length,
                    "removed-by-filter-count",
                    true,
                    false,
                  )}
                  {renderSummaryItem(lang.ticketsEligibleCount, eligbleTickets.length, "eligible-tickets-count", false, true)}
                </Card>
              </div>
            </FormProvider>
          </>
        )}
      </div>
      {showButton && file && (
        <Button
          variant="primary"
          type="button"
          text={lang.changeTickets}
          data-test-id="open-confirm-modal-button"
          onClick={() => setShowModal(true)}
        />
      )}
      {showNoTicketsMessage && (
        <Message skin="warning" data-test-id="no-tickets-for-agreement-and-date-message">
          <p>{lang.noTickets}</p>
        </Message>
      )}

      <Modal
        isOpen={showModal}
        title={lang.confirmModalTitle}
        handleClose={() => setShowModal(false)}
        data-test-id="confirm-modal"
        className="mass-change-department-confirm-modal"
      >
        {Boolean(ticketChangeNumber) && (
          <p data-test-id="confirm-status">
            {lang.confirmStatus
              .replace("${ticketNumber}", ticketChangeNumber.toString())
              .replace("${totalTickets}", eligbleTickets.length.toString())}
            <ProgressRadial type="Detirmate" value={ticketChangeNumber} min={0} max={eligbleTickets.length} />
          </p>
        )}
        {!ticketChangeNumber && (
          <p data-test-id="confirm-text">{lang.confirmModalText.replace("${number}", eligbleTickets.length.toString())}</p>
        )}
        {!ticketChangeNumber && (
          <ButtonGroup>
            <Button
              variant="primary"
              type="button"
              text={formLang.yes}
              data-test-id="confirm-button"
              onClick={changeDepartmentForTickets}
            />
            <CancelButton onClick={() => setShowModal(false)} data-test-id="cancel-button" />
          </ButtonGroup>
        )}
      </Modal>
    </Container>
  );
};

export default MassChangeDepartment;
