import { Button, ButtonGroup, TextFormInput, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { Container, Message } from "@ruter-ds/rds-components";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import apiClient from "src/common/apiClient/apiClient";
import HttpError from "src/common/apiClient/HttpError";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import { OnboardingNextStep } from "src/types/OnboardingNextStep";
import { onboardingLanguageResources } from "./lang-resource";

interface Props {
  onboardingId: string;
  nextStep: OnboardingNextStep.CONTRACT_SIGNED | OnboardingNextStep.PROVIDE_SIGNEE_INFO;
}

interface FormInput {
  code: string;
}

const ContractSignedVerifyPhone: React.FC<Props> = ({ onboardingId, nextStep }) => {
  const lang = useLanguageResource(onboardingLanguageResources);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const methods = useForm<FormInput>();
  const [wrongCode, setWrongCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const submitPhone = async (values: FormInput) => {
    setLoading(true);
    setWrongCode(false);
    try {
      await apiClient.post(`/onboarding/onboarding/verify-phone/${onboardingId}`, values);
      setSuccess(true);
    } catch (e) {
      if (e instanceof HttpError) {
        if (e.responseStatus === 400) {
          setWrongCode(true);
          setLoading(false);
          return;
        }
      }
      setWrongCode(() => { throw e; });
    }
    setLoading(false);
  };

  if (success) {
    return (
      <Container width="xs" data-test-id="verify-phone">
        <h1>{lang.verifyPhone.confirmationTitle}</h1>
        {nextStep === OnboardingNextStep.CONTRACT_SIGNED && (
          <div data-test-id="contract-signed-confirmation-message">{lang.verifyPhone.contractSignedConfirmationText}</div>
        )}
        {nextStep === OnboardingNextStep.PROVIDE_SIGNEE_INFO && (
          <div data-test-id="contract-not-signed-confirmation-message">{lang.verifyPhone.contractNotSignedConfirmationText}</div>
        )}
        <div style={{ marginTop: "1rem" }}>{lang.verifyPhone.phoneVerifiedConfirmationText}</div>

      </Container>
    );
  }

  return (
    <Container width="xs" data-test-id="verify-phone">
      <h1>{lang.verifyPhone.title}</h1>
      <p style={{ marginBottom: "1rem" }}>{lang.verifyPhone.text}</p>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submitPhone)}>
          <TextFormInput name="code" minLength={6} maxLength={6} required label={lang.verifyPhone.codeLabel} />
          {wrongCode && (
            <Message skin="danger" style={{ marginTop: "1rem" }} data-test-id="wrong-code-error-message">
              {lang.verifyPhone.wrongCodeErrorMessage}
            </Message>
          )}

          <ButtonGroup>
            <Button variant="primary" type="submit" text={formLang.save} loading={loading} />
          </ButtonGroup>
        </form>
      </FormProvider>
    </Container>
  );
};

export default ContractSignedVerifyPhone;