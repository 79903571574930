import { useLanguageResource } from "@ruter-as/web-components-and-tools";
import { FormGroup, Label, Select } from "@ruter-ds/rds-components";
import React, { ChangeEvent, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import { Zone } from "../../../common/api/commonTypes/Zone";

const ZONE_FROM_NAME = "zoneFrom";
const ZONE_TO_NAME = "zoneTo";

interface Props {
  zones: Zone[];
}

const ZoneInputBrakar: React.FC<Props> = ({ zones }) => {
  const { register, setValue } = useFormContext();
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const zoneFrom = useWatch({ name: ZONE_FROM_NAME });
  const zoneTo = useWatch({ name: ZONE_TO_NAME });

  useEffect(() => {
    register("zoneFrom");
    register("zoneTo");

    if (!zoneFrom) {
      setValue(ZONE_FROM_NAME, zones[0].name);
      setValue(ZONE_TO_NAME, "");
    }
  }, [register, zones, zoneFrom, setValue]);

  const changeFromZone = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(ZONE_FROM_NAME, e.target.value);
    setValue(ZONE_TO_NAME, "");
  };

  const changeToZone = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(ZONE_TO_NAME, e.target.value);
  };

  if (!zoneFrom) {
    return null;
  }

  return (
    <>
      <FormGroup data-test-id="brakar-zone-input-from-zone">
        <Label labelText={formLang.forZone} htmlFor="fromZone" />
        <Select value={zoneFrom} onChange={changeFromZone} name="fromZone" id="fromZone">
          {zones.map((zone) => (
            <option key={zone.name} value={zone.name}>
              {zone.name}
            </option>
          ))}
        </Select>
      </FormGroup>
      <FormGroup data-test-id="brakar-zone-input-to-zone">
        <Label labelText={formLang.toZone} htmlFor="fromZone" />
        <Select value={zoneTo} onChange={changeToZone} name="toZone" id="toZone">
          <option value="">&nbsp;</option>
          {zones.map((zone) => (
            <option key={zone.name} value={zone.name}>
              {zone.name}
            </option>
          ))}
        </Select>
      </FormGroup>
    </>
  );
};

export default ZoneInputBrakar;
