import { Container, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "src/AuthContext";
import bus from "../../../gfx/illustrasjon-buss-404.svg";
import CallToActionLink from "../../common/buttons/CallToActionLink";
import { userSetupErrorLanguageResource } from "./lang-resource";
import "./UserSetupError.scss";

const UserSetupError: React.FC = () => {
  const lang = useLanguageResource(userSetupErrorLanguageResource);
  const authContext = useAuthContext();
  const navigate = useNavigate();
  const contactUsUrl = authContext.features.urls.contactUs;

  return (
    <Container width="m" className="components-app-usersetuperror" data-test-id="user-setup-error-page">
      <img src={bus} alt="" />
      <h1>{lang.title}</h1>
      <div>{lang.text}</div>
      <div>{lang.text2}</div>
      <div className="actions">
        <CallToActionLink
          externalUrl={contactUsUrl.external}
          text={lang.clickForContact}
          showIcon={true}
        />
        <CallToActionLink text={lang.clickForHomePage} onClick={() => {
          if (authContext.authenticated) {
            authContext.logout();
          } else {
            navigate("/");
          }
        }} showIcon={true} />
      </div>
    </Container>
  );
};

export default UserSetupError;
