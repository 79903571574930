import { parser } from "@ruter-as/web-components-and-tools";
import { isAfter, startOfDay } from "date-fns";
import { hasValidAgreement } from "src/common/agreements";
import { AgreementSubTypeId } from "src/types/AgreementSubTypeId";
import { AuthCompany } from "src/types/AuthCompany";
import { Contact } from "src/types/Contact";
import MediaType from "src/types/mediaType";
import { CompanyRoleType } from "src/types/user/CompanyRoleType";
import { UserRoleType } from "src/types/user/UserRoleType";
import { EnvName } from "../EnvName";
import { HasNoService } from "./Services";

export interface HasSchoolTicketService {
  hasService: true
  agreementNumber: string
  allowOrder: boolean
  schoolStartDate: Date
  availableMediaTypes: MediaType[]
}

export type SchoolTicketService = HasSchoolTicketService | HasNoService;

const getAvaibleMediaTypes = (env: EnvName): MediaType[] => {
  const mediaTypes = [MediaType.TRAVEL_CARD];

  if (env !== "prod") {
    mediaTypes.push(MediaType.MOBILE_TICKET);
  }

  return mediaTypes;
};

export const calculateSchoolTicketService = (selectedCompany: AuthCompany, contact: Contact, env: EnvName): SchoolTicketService => {
  const agreement = selectedCompany.allAgreements.find(x => x.subTypeId === AgreementSubTypeId.SCHOOLTICKET);
  if (!agreement) {
    return { hasService: false };
  }

  if (
    !selectedCompany.roles.some(role => role === CompanyRoleType.SchoolTicketAdmin)
    && !contact.roles.some(role => role === UserRoleType.GlobalSchoolTicketAdmin)
  ) {
    return { hasService: false };
  }

  const schoolStartDate = startOfDay(parser.date.fromShortDateString("19.08.2024"));

  return {
    hasService: true,
    agreementNumber: agreement.id,
    allowOrder: hasValidAgreement([agreement]),
    schoolStartDate: env === "prod" && isAfter(schoolStartDate, new Date()) ? schoolStartDate : new Date(),
    availableMediaTypes: getAvaibleMediaTypes(env),
  };
};

export const getValidSchoolTicketService = (service: Partial<SchoolTicketService>): SchoolTicketService => ({
  hasService: true,
  agreementNumber: "",
  allowOrder: false,
  availableMediaTypes: [MediaType.TRAVEL_CARD],
  schoolStartDate: new Date(),
  ...service,
});
