import { Agreement, AgreementJson, CommonCompany, CommonCompanyJson, CompanyAddresses, mapAgreement, mapCommonCompany } from "./Company";
import { CompanyRoleType, parseCompanyRoleType } from "./user/CompanyRoleType";

export type WidgetAccessOptions = "DISABLED" | "ALLOW_TICKET_CREATION" | "ALLOW_TICKET_REQUESTS";

export interface AgreementSettings {
  agreementNumber: string;
  emailForTicketRequests: string | null;
  emailForInvoicePreview: string | null;
  widgetAccess: WidgetAccessOptions;
  employeeIdLabel: string;
}

interface AuthAgreementJson extends AgreementJson {
  settings: AgreementSettings;
}

export interface AuthAgreement extends Agreement {
  settings: AgreementSettings;
}

export interface AuthCompanyJson extends CommonCompanyJson {
  agreements: Array<AuthAgreementJson>;
  roles: Array<string>;
}

export interface AuthCompany extends CommonCompany {
  allAgreements: Array<AuthAgreement>;
  roles: Array<CompanyRoleType>;
}

export interface UserDataCompany {
  id: string,
  name: string,
  addresses: CompanyAddresses,
}

const mapAuthAgreement = (json: AuthAgreementJson): AuthAgreement => ({
  ...mapAgreement(json),
  settings: json.settings,
});

export const mapAuthCompany = (json: AuthCompanyJson): AuthCompany => {
  const roles = json.roles.map(parseCompanyRoleType).filter((x): x is CompanyRoleType => x !== "UNKNOWN");

  return {
    ...mapCommonCompany(json),
    allAgreements: json.agreements.map(mapAuthAgreement),
    roles: roles,
  };
};

export const getValidAuthCompanyJson = (): AuthCompanyJson => ({
  roles: ["COMPANY_ADMIN"],
  id: "1226629",
  type: "SIEBEL_CUSTOMER_NUMBER",
  organisationNumber: "111122223",
  name: "Steffens Kosmetikk",
  agreements: [
    {
      id: "1-3779173843",
      invoiceReference: "KIM",
      endDate: undefined,
      fromDate: undefined,
      subTypeId: "FREETICKET",
      invoicingInterval: "MONTHLY_AT_END",
      paymentDeadline: 30,
      settings: {
        agreementNumber: "1-3779173843",
        emailForTicketRequests: null,
        emailForInvoicePreview: null,
        widgetAccess: "DISABLED",
        employeeIdLabel: "Ansattnr.",
      },
    },
    {
      id: "1-3058717731",
      invoiceReference: "KÅRE",
      endDate: undefined,
      fromDate: undefined,
      subTypeId: "FREETICKET",
      invoicingInterval: "MONTHLY_AT_END",
      paymentDeadline: 30,
      settings: {
        agreementNumber: "1-3058717731",
        emailForTicketRequests: null,
        emailForInvoicePreview: null,
        widgetAccess: "DISABLED",
        employeeIdLabel: "Ansattnr.",
      },
    },
  ],
  visitAddressLine1: "Visit Address 1 It2",
  visitAddressLine2: "Visit Address 2 It2",
  visitAddressCity: "visit city it2",
  visitAddressCountry: null,
  visitAddressPostCode: "7921",
  billAddressLine1: "Bill Address 1 It2",
  billAddressLine2: "Bill Address 2 It2",
  billAddressCity: "bill city it2",
  billAddressCountry: null,
  billAddressPostCode: "7923",
  postAddressLine1: "Post Address 1 It2",
  postAddressLine2: "Post Address 2 It2",
  postAddressCity: "post city it2",
  postAddressCountry: null,
  postAddressPostCode: "7922",
});
