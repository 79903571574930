import { apiClient, Button, ButtonGroup, Container, EmailFormInput, FormGroup, Modal, RadioButtonFormInput, TextFormInput, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useAuthContextAuthenticated, useValidHousingAssociationService } from "src/AuthContext";
import housingAssociationApi from "src/common/api/companyAgreementApi/housingAssociation/housingAssociationApi";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import successAlert from "src/common/toastr";
import { SubmitButton } from "src/components/common/buttons";
import PageHeaderComponent from "src/components/common/layout/PageHeader";
import PageSubheaderComponent from "src/components/common/layout/PageSubheader";
import { CREATE_POLICY_ALLOW_TICKET_CREATION, CREATE_POLICY_ALLOW_TICKET_REQUESTS, CREATE_POLICY_DISABLED } from "src/constants";
import { AgreementSettings, WidgetAccessOptions } from "src/types/AuthCompany";
import { housingAssociationSettingsLanguageResource } from "./lang-resource";

export interface FormData {
  allow: WidgetAccessOptions;
  email: string | null;
  emailPayroll: string | null;
  ticketOwnerFieldName: string;
}

const HousingAssociationSettings: React.FC = () => {
  const authContext = useAuthContextAuthenticated();
  const housingAssociationService = useValidHousingAssociationService();
  const selectedCompany = authContext.userData.selectedCompany;
  const lang = useLanguageResource(housingAssociationSettingsLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const [saveSettingsIsLoading, setSaveSettingsIsLoading] = useState(false);
  const [confirmSaveIsLoading, setConfirmSaveIsLoading] = useState(false);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [openTicketRequests, setOpenTicketRequests] = useState(0);
  const [contractData, setContractData] = useState<FormData>();

  const { widgetAccess, emailForInvoicePreview, emailForTicketRequests, employeeIdLabel } = {
    ...housingAssociationService.widgetSettings,
  };

  const formMethods = useForm<FormData>({
    defaultValues: {
      allow: widgetAccess,
      emailPayroll: emailForInvoicePreview,
      email: emailForTicketRequests,
      ticketOwnerFieldName: employeeIdLabel,
    },
  });

  const allow = useWatch({ name: "allow", control: formMethods.control });


  const mapSettingsContract = (formData: FormData): AgreementSettings => {
    const agreementNumber = housingAssociationService.agreementNumber;
    const contract = {
      agreementNumber,
      emailForTicketRequests: formData.email,
      widgetAccess: formData.allow,
      emailForInvoicePreview: formData.emailPayroll,
      employeeIdLabel: formData.ticketOwnerFieldName,
    };
    return contract;
  };

  const saveSettings = async (formData: FormData) => {
    const contract = mapSettingsContract(formData);
    const response = await apiClient.request(housingAssociationApi.settings.post(contract));
    if (response.type === "success") {
      await authContext.userData.reload();
      setConfirmSaveIsLoading(false);
      setSaveSettingsIsLoading(false);
      successAlert(lang.settingsSaved);
    } else {
      setSaveSettingsIsLoading(() => {
        throw response.error;
      });
    }
  };

  const conditionalSave = async (formData: FormData) => {
    const currentAccess = housingAssociationService.widgetSettings.widgetAccess;
    const newAccess = formData.allow;
    setSaveSettingsIsLoading(true);

    if (currentAccess === CREATE_POLICY_ALLOW_TICKET_REQUESTS && currentAccess !== newAccess) {
      const companyId = selectedCompany.id;
      const response = await apiClient.request(housingAssociationApi.ticketRequest.getByCompanyId(companyId));
      if (response.type === "success") {
        if (response.result.totalEntries > 0) {
          setSaveSettingsIsLoading(false);
          setOpenTicketRequests(response.result.totalEntries);
          setContractData(formData);
          setConfirmModalIsOpen(true);
        } else {
          await saveSettings(formData);
        }
      } else {
        setSaveSettingsIsLoading(() => {
          throw response.error;
        });
      }
    } else {
      await saveSettings(formData);
    }
  };

  const confirmSave = async (formData: FormData) => {
    try {
      setConfirmSaveIsLoading(true);
      await saveSettings(formData);
      setConfirmModalIsOpen(false);
      setContractData(undefined);
    } catch (e) {
      setConfirmSaveIsLoading(() => {
        throw e;
      });
    }
  };

  const accessOptions = [
    { value: CREATE_POLICY_DISABLED, text: lang.notAllowed },
    { value: CREATE_POLICY_ALLOW_TICKET_CREATION, text: lang.allowedWithoutConfirmation },
    { value: CREATE_POLICY_ALLOW_TICKET_REQUESTS, text: lang.allowedWithConfirmation },
  ];

  return <Container width="s" className="housing-association-settings-page" data-test-id="housing-association-settings-page">
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(conditionalSave)}>
        <PageHeaderComponent text={lang.headerTitle} loading={false} />
        <PageSubheaderComponent text={lang.generalTitle} loading={false} />
        <TextFormInput label={lang.ticketOwnerFieldName} name="ticketOwnerFieldName" required minLength={1} />
        <PageSubheaderComponent text={lang.titleWidget} loading={false} />
        <FormGroup>
          <RadioButtonFormInput
            name="allow"
            options={accessOptions}
            required
            label={lang.orderTicketsByEmployees}
          />
        </FormGroup>
        {allow === CREATE_POLICY_ALLOW_TICKET_REQUESTS && (
          <EmailFormInput name="email" label={lang.emailForWidget} validationLabel={lang.simpleEmail} />
        )}
        <PageSubheaderComponent text={lang.titlePayroll} loading={false} />
        <EmailFormInput name="emailPayroll" label={lang.emailForPayroll} validationLabel={lang.simpleEmail} />
        <FormGroup>
          <SubmitButton submitting={saveSettingsIsLoading} text={formLang.save} />
        </FormGroup>
      </form>
    </FormProvider>

    <Modal
      isOpen={Boolean(confirmModalIsOpen && contractData)}
      handleClose={() => {
        setConfirmModalIsOpen(false);
      }}
      title={lang.confirmTitle}
      className="confirm-modal"
      data-test-id="confirm-modal"
    >
      <p>
        {openTicketRequests === 1
          ? lang.confirmTextSingle.replace("{count}", String(openTicketRequests))
          : lang.confirmTextPlural.replace("{count}", String(openTicketRequests))}
      </p>
      <ButtonGroup className="button-group">
        <Button
          text={lang.yes}
          data-test-id="confirm-button"
          loading={confirmSaveIsLoading}
          type="button"
          variant="primary"
          onClick={async () => {
            if (contractData) await confirmSave(contractData);
          }}
        />
        <Button
          type="button"
          text={lang.cancel}
          variant="cancel"
          data-test-id="cancel-button"
          onClick={() => {
            setConfirmModalIsOpen(false);
          }}
        />
      </ButtonGroup>
    </Modal>
  </Container>;
};

export default HousingAssociationSettings;