/* eslint-disable jsx-a11y/control-has-associated-label */
import { apiClient, Container, IconButton, Table, useLanguageResource } from "@ruter-as/web-components-and-tools";
import * as Sentry from "@sentry/react";
import moment from "moment";
import React, { useState } from "react";
import { useValidCompanyTicketService } from "src/AuthContext";
import companyAgreementApi from "src/common/api/companyAgreementApi/companyAgreementApi";
import successAlert, { failAlert } from "src/common/toastr";
import GenericError from "src/components/app/genericError/GenericError";
import { useTicketRequestContext } from "src/TicketRequestContext";
import getTicketOwnerFieldName from "../../../common/ticketOwnerFieldName";
import { TicketRequestJson } from "../../../types/companyAgreement/ticketRequest";
import { TicketZonesComponent } from "../../common/text/ticketZones/TicketZones";
import { ticketRequestLanguageResource } from "./lang-resource";
import "./TicketRequest.scss";

const TicketRequestPage: React.FC = () => {
  const companyTicketService = useValidCompanyTicketService();
  const ticketRequestContext = useTicketRequestContext();
  const language = useLanguageResource(ticketRequestLanguageResource);
  const ticketOwnerFieldName = getTicketOwnerFieldName(companyTicketService);
  const [rejectTicketRequestId, setRejectTicketRequestId] = useState<undefined | string>();
  const [approveTicketRequestId, setapproveTicketRequestId] = useState<undefined | string>();

  if (ticketRequestContext.state === "error") {
    return <GenericError />;
  }
  if (ticketRequestContext.state === "not-applicable") {
    throw new Error("Should not be able to navigate to this site");
  }

  const renderRow = (ticketRequest: TicketRequestJson) => {
    const rejectOrApproveInProgress = Boolean(rejectTicketRequestId || approveTicketRequestId);
    const rejectOnThisRowInProgress = rejectTicketRequestId === ticketRequest.id;
    const approveOnThisRowInProgress = approveTicketRequestId === ticketRequest.id;

    const formattedDate = moment(ticketRequest.startDate).format("L");
    const dateColumn = ticketRequest.expired ? `${formattedDate} (${language.expired})` : formattedDate;

    const rejectTicketRequest = async (ticketId: string) => {
      setRejectTicketRequestId(ticketId);
      const response = await apiClient.request(companyAgreementApi.ticketRequest.reject(ticketId));
      if (response.type === "success") {
        successAlert(language.requestRejected);
        if (ticketRequestContext.state === "ticket-request-allowed") {
          await ticketRequestContext.refetch();
        }
      } else {
        failAlert(language.requestRejectFailed);
        Sentry.captureException(response.error);
      }

      setRejectTicketRequestId(undefined);
    };

    const approveTicketRequest = async (ticketId: string) => {
      setapproveTicketRequestId(ticketId);
      const ticket = ticketRequestContext.requests.find(x => x.id === ticketId);
      if (!ticket) {
        return;
      }
      const response = await apiClient.request(companyAgreementApi.ticketRequest.approve(ticket));
      if (response.type === "success") {
        successAlert(language.requestApproved);
        if (ticketRequestContext.state === "ticket-request-allowed") {
          await ticketRequestContext.refetch();
        }
      } else {
        failAlert(language.requestRejectFailed);
        Sentry.captureException(response.error);
      }
      setapproveTicketRequestId(undefined);
    };

    return (
      <tr key={ticketRequest.id}>
        <td>{ticketRequest.firstName}</td>
        <td>{ticketRequest.lastName}</td>
        <td>{ticketRequest.employeeId}</td>
        <td>{ticketRequest.phone}</td>
        <td>
          <TicketZonesComponent nrOfZones={ticketRequest.nrOfZones} zones={ticketRequest.zones}  />
        </td>
        <td className={ticketRequest.expired ? "expired" : undefined}>{dateColumn}</td>
        <td className="actions">
          <IconButton
            disabled={rejectOrApproveInProgress}
            data-test-id="reject-button"
            variant="CrossIcon"
            onClick={() => rejectTicketRequest(ticketRequest.id)}
            loading={rejectOnThisRowInProgress}
            aria-label={language.rejectRequest}
            title={language.rejectRequest}
          />

          {!ticketRequest.expired && (
            <IconButton
              disabled={rejectOrApproveInProgress}
              data-test-id="approve-button"
              variant="BoxCheckedIcon"
              onClick={() => approveTicketRequest(ticketRequest.id)}
              loading={approveOnThisRowInProgress}
              aria-label={language.approveRequest}
              title={language.approveRequest}
            />
          )}
        </td>
      </tr>
    );
  };

  return (
    <Container width="l" data-test-id="ticket-request-page" className="ticket-request-page">
      <h1>{language.title}</h1>
      <Table breakpoint="600px" loading={ticketRequestContext.state === "loading"}>
        <thead>
          <tr>
            <th scope="col">{language.firstName}</th>
            <th scope="col">{language.lastName}</th>
            <th scope="col">{ticketOwnerFieldName}</th>
            <th scope="col">{language.phone}</th>
            <th scope="col">{language.zones}</th>
            <th scope="col">{language.startDate}</th>
            <th className="actions" />
          </tr>
        </thead>
        <tbody>{ticketRequestContext.requests.map(renderRow)}</tbody>
      </Table>
    </Container>
  );
};

export default TicketRequestPage;
