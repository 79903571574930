import { apiClient, Button, ButtonGroup, DropdownFormInput, FieldValidationError, Message, Modal, TextFormInput, useLanguageResource } from "@ruter-as/web-components-and-tools";
import * as Sentry from "@sentry/react";
import React, { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useValidCompanyAdmin } from "src/AuthContext";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import { AgreementInvoicingInterval } from "src/types/AgreementInvoicingInterval";
import { AgreementSubTypeId, parseAgreementSubTypeId } from "src/types/AgreementSubTypeId";
import { Agreement } from "src/types/Company";
import companyPageLanguageResource from "./lang-resource";

interface CallbackProps {
  type: "callback";
  onClose: () => void;
  agreement: Agreement;
  onSave: (agreement: Agreement) => void;
  companyAgreements: Agreement[];
}

interface SaveToBackendProps {
  type: "saveToBackend",
  onClose: () => void;
  agreement: Agreement;
  companyId: string;
  onSuccess: () => void;
  companyAgreements: Agreement[];
}

type Props = SaveToBackendProps | CallbackProps;

interface AgreementPutContract {
  invoiceReference: string;
  invoicingInterval: AgreementInvoicingInterval;
  paymentDeadlineDays: number;
}

interface AgreementPostContract extends AgreementPutContract {
  subType: AgreementSubTypeId;
}

interface FormFields {
  subType: string;
  invoiceReference: string;
  invoicingInterval: AgreementInvoicingInterval | "";
  paymentDeadlineDays: string;
}


const isValidAgreement = (subTypeId: AgreementSubTypeId, allAgreements: Agreement[], agreementId: string) => {
  const filteredAgreements = allAgreements.filter(x => x.id !== agreementId);
  const hasCompanyTicketAgreement = filteredAgreements.some(x => x.subTypeId === AgreementSubTypeId.SUBSCRIPTIONS);
  const hasFreeTicketAgreement = filteredAgreements.some(x => x.subTypeId === AgreementSubTypeId.FREETICKET);
  const hasHousingAssociationAgreement = filteredAgreements.some(x => x.subTypeId === AgreementSubTypeId.HOUSING_ASSOCIATION);
  const hasSchoolTicketAgreement = filteredAgreements.some(x => x.subTypeId === AgreementSubTypeId.SCHOOLTICKET);

  switch (subTypeId) {
    case AgreementSubTypeId.FREETICKET:
      return !(hasCompanyTicketAgreement || hasHousingAssociationAgreement || hasSchoolTicketAgreement);
    case AgreementSubTypeId.SUBSCRIPTIONS:
      return !(hasFreeTicketAgreement || hasHousingAssociationAgreement || hasSchoolTicketAgreement);
    case AgreementSubTypeId.HOUSING_ASSOCIATION:
      return !(hasCompanyTicketAgreement || hasFreeTicketAgreement || hasHousingAssociationAgreement || hasSchoolTicketAgreement);
    case AgreementSubTypeId.SCHOOLTICKET:
      return !(hasCompanyTicketAgreement || hasFreeTicketAgreement || hasHousingAssociationAgreement || hasSchoolTicketAgreement);
    default:
      return true;
  }
};


const EditAgreementModal: React.FC<Props> = (props) => {
  const { agreement, onClose, companyAgreements } = props;
  const companyAdmin = useValidCompanyAdmin();
  const lang = useLanguageResource(companyPageLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const [loading, setLoading] = useState(false);
  const [submitFailure, setSubmitFailure] = useState(false);
  const [agreementValidationError, setAgreementValidationError] = useState<AgreementSubTypeId>();

  const disableAgreementSubTypeId = Boolean(props.type === "saveToBackend" && agreement.id);

  const formMethods = useForm<FormFields>({
    defaultValues: {
      subType: agreement.subTypeId,
      invoiceReference: agreement.invoiceReference,
      invoicingInterval: agreement.invoicingInterval === AgreementInvoicingInterval.OTHER ? "" : agreement.invoicingInterval,
      paymentDeadlineDays: agreement.paymentDeadline?.toString(),
    },
  });

  const saveAgreement = async (data: FormFields) => {
    if (data.invoicingInterval === "") {
      throw new Error("invoicinginterval is empty string, this should have been caught in validation");
    }
    setAgreementValidationError(undefined);
    const subTypeId = parseAgreementSubTypeId(data.subType);
    if (props.type === "callback") {
      const { onSave } = props;

      if (!isValidAgreement(subTypeId, companyAgreements, agreement.id)) {
        setAgreementValidationError(subTypeId);
        return;
      } else {
        onSave({
          endDate: null,
          fromDate: null,
          id: agreement.id,
          invoiceReference: data.invoiceReference,
          invoicingInterval: data.invoicingInterval,
          paymentDeadline: parseInt(data.paymentDeadlineDays),
          subTypeId,
        });
        return;
      }
    }

    const { companyId, onSuccess } = props;

    if (agreement.id) {
      setLoading(true);
      setSubmitFailure(false);
      const requestData: AgreementPutContract = {
        invoiceReference: data.invoiceReference,
        invoicingInterval: data.invoicingInterval,
        paymentDeadlineDays: parseInt(data.paymentDeadlineDays),
      };
      const result = await apiClient.put(`/onboarding/company/${companyId}/agreement/${agreement.id}`, requestData);

      if (result.type === "success") {
        onSuccess();
      } else {
        setLoading(false);
        setSubmitFailure(true);
        Sentry.captureException(result.error);
      }

    } else {
      if (!isValidAgreement(subTypeId, companyAgreements, agreement.id)) {
        setAgreementValidationError(subTypeId);
        return;
      }

      setLoading(true);
      setSubmitFailure(false);
      
      const requestData: AgreementPostContract = {
        subType: parseAgreementSubTypeId(data.subType),
        invoiceReference: data.invoiceReference,
        invoicingInterval: data.invoicingInterval,
        paymentDeadlineDays: parseInt(data.paymentDeadlineDays),
      };
      const result = await apiClient.post(`/onboarding/company/${companyId}/agreement/create`, requestData);

      if (result.type === "success") {
        onSuccess();
      } else {
        setLoading(false);
        setSubmitFailure(true);
        Sentry.captureException(result.error);
      }
    }
  };

  const agreementTypeOptions = useMemo(() => {
    const travelCardFixedPrice = { value: AgreementSubTypeId.TRAVELCARD_FIXED_PRICE, text: lang.travelCardFixedPrice };
    const companyAgreement = { value: AgreementSubTypeId.SUBSCRIPTIONS, text: lang.subscriptions };
    const ticketOffice = { value: AgreementSubTypeId.TICKETOFFICE, text: lang.ticketOffice };
    const freeTicket = { value: AgreementSubTypeId.FREETICKET, text: lang.freeTicket };
    const schoolTicket = { value: AgreementSubTypeId.SCHOOLTICKET, text: lang.schoolTicket };
    const noSubscriptions = { value: AgreementSubTypeId.NO_SUBSCRIPTIONS, text: lang.noSubscriptions };
    const travelCardSubscription = { value: AgreementSubTypeId.TRAVELCARD_SUBSCRIPTION, text: lang.travelCardSubscription };
    const housingAssociation = { value: AgreementSubTypeId.HOUSING_ASSOCIATION, text: lang.housingAssociation };
    const allAgreementOptions = [
      travelCardFixedPrice,
      companyAgreement,
      ticketOffice,
      freeTicket,
      schoolTicket,
      noSubscriptions,
      travelCardSubscription,
      housingAssociation,
    ];

    return allAgreementOptions.filter(option => companyAdmin.agreementOptions.includes(option.value));
  }, [
    lang.travelCardFixedPrice,
    lang.subscriptions, lang.ticketOffice,
    lang.freeTicket, lang.schoolTicket,
    lang.noSubscriptions,
    lang.travelCardSubscription,
    companyAdmin.agreementOptions,
    lang.housingAssociation,
  ]);

  const humanReadableAgreementName = (id: AgreementSubTypeId): string => {
    const option = agreementTypeOptions.find(x => x.value === id);

    if (!option) {
      throw new Error("");
    }

    return option.text;
  };


  const title = agreement.id ? lang.editAgreement.replace("$agreement$", agreement.id) : lang.newAgreement;

  return (
    <Modal isOpen={true} title={title} data-test-id="edit-agreement-modal" handleClose={onClose}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(saveAgreement)}>
          <DropdownFormInput name="subType" disabled={disableAgreementSubTypeId} label={lang.agreementType} required>
            {agreementTypeOptions.map(x => <option value={x.value} key={x.value}>{x.text}</option>)}
          </DropdownFormInput>
          <DropdownFormInput name="invoicingInterval" required label={lang.invoicingInterval}>
            <option value="" key={"default_key"}></option>
            <option value={AgreementInvoicingInterval.DAILY} key={AgreementInvoicingInterval.DAILY}>{lang.invoicingIntervalDaily}</option>
            <option value={AgreementInvoicingInterval.WEEKLY} key={AgreementInvoicingInterval.WEEKLY}>{lang.invoicingIntervalWeekly}</option>
            <option value={AgreementInvoicingInterval.MONTHLY_AT_END} key={AgreementInvoicingInterval.MONTHLY_AT_END}>{lang.invoicingIntervalMonthlyAtEnd}</option>
            <option value={AgreementInvoicingInterval.QUARTERLY} key={AgreementInvoicingInterval.QUARTERLY}>{lang.invoicingIntervalQuarterly}</option>
          </DropdownFormInput>
          <TextFormInput name="invoiceReference" label={lang.invoiceReference} />
          {submitFailure && (
            <Message skin="danger" data-test-id="submit-failure" style={{ marginTop: "2rem" }}>
              <p>{lang.submitFailure}</p>
            </Message>
          )}
          <ButtonGroup>
            <Button variant="primary" type="submit" text={formLang.save} loading={loading} />
            <Button variant="cancel" type="button" text={formLang.cancel} data-test-id="cancel-button" onClick={onClose} />
          </ButtonGroup>
          {agreementValidationError && <FieldValidationError text={lang.subTypeIdValidationError(humanReadableAgreementName(agreementValidationError))}/>}
        </form>
      </FormProvider>
    </Modal>
  );
};

export default EditAgreementModal;
