import HomeAkt from "src/components/home/HomeAkt";
import HomeBrakar from "src/components/home/HomeBrakar";
import HomeRuter from "src/components/home/HomeRuter";
import aktLogo from "src/gfx/akt-logo.svg";
import brakarLogo from "src/gfx/brakar-logo.png";
import ruterLogo from "src/gfx/ruterbedrift-logo.svg";
import { Tenant } from "src/types/features/Tenant";



export interface Theme {
  homePage: JSX.Element,
  title: string
  class: Class
  logo: string
}

type Class = "akt" | "brakar" | "ruter";

const getHomePage = (tenant: Tenant) => {
  switch (tenant) {
    case Tenant.Akt:
      return <HomeAkt />;
    case Tenant.Brakar:
      return <HomeBrakar />;
    case Tenant.Ruter:
      return <HomeRuter />;
  }
};

const getTitle = (tenant: Tenant) => {
  switch (tenant) {
    case (Tenant.Brakar):
      return "BrakarBedrift";
    case Tenant.Ruter:
      return "RuterBedrift";
    case Tenant.Akt:
      return "AktBedrift";
  }
};

const tenantClass = (tenant: Tenant) => {
  switch (tenant) {
    case Tenant.Brakar:
      return "brakar";
    case Tenant.Ruter:
      return "ruter";
    case Tenant.Akt:
      return "akt";
  }
};

const logo = (tenant: Tenant) => {
  switch (tenant) {
    case Tenant.Brakar:
      return brakarLogo;
    case Tenant.Ruter:
      return ruterLogo;
    case Tenant.Akt:
      return aktLogo;
  }
};

export const calculateTheme = (tenant: Tenant): Theme => {
  return {
    homePage: getHomePage(tenant),
    title: getTitle(tenant),
    class: tenantClass(tenant),
    logo: logo(tenant),
  };
};

export const getValidTheme = (theme: Partial<Theme> = {}): Theme => ({
  ...calculateTheme(Tenant.Ruter),
  ...theme,
});
