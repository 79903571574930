export enum Tenant {
  Brakar = "brakar",
  Ruter = "ruter",
  Akt = "akt",
}

export const parseTenant = (value: string): Tenant => {
  const parsed = Object.values(Tenant).find((x) => x === value);

  if (parsed === undefined) {
    return Tenant.Ruter;
  }
  return parsed;
};
