import { Card, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { UserDataContact } from "src/types/Contact";
import "./InfoCard.scss";
import myPageLanguageResource from "./lang-resource";

interface Props {
  contact: UserDataContact;
  orgNumber?: string;
  customerNumber?: string;
}

const ContactCard: React.FC<Props> = ({ contact }) => {
  const lang = useLanguageResource(myPageLanguageResource);

  return (
    <Card className="components-info-card">
      <div className="header">
        <h2>{lang.personalInfo}</h2>
      </div>
      <div className="body">
        <div className="info">
          <div className="label">{lang.name}</div>
          <div className="value" data-test-id="contact-name">{`${contact.firstName} ${contact.lastName}`}</div>
        </div>
        <div className="info">
          <div className="label">{lang.phone}</div>
          <div className="value" data-test-id="contact-phone">
            {contact.phone}
          </div>
        </div>
        <div className="info">
          <div className="label">{lang.email}</div>
          <div className="value" data-test-id="contact-email">
            {contact.email}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ContactCard;