import { apiClient, Button, ButtonGroup, DateFormInput, DropdownFormInput, FormGroup, Message, Modal, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { addMonths } from "date-fns";
import React, { useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useValidFreeTicketAgreementService } from "src/AuthContext";
import { FreeTicket } from "src/common/api/freeTicketApi/freeTicket";
import freeTicketApi from "src/common/api/freeTicketApi/freeTicketApi";
import { ensureExpiryDateIsNotInThePastAndFormatForBackend, getExpiryDates } from "src/common/expiryDate";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import { failAlert } from "src/common/toastr";
import { CancelButton } from "src/components/common/buttons";
import { freeTicketsFamilyLanguageResource } from "./lang-resource";

interface Props {
  onSuccess: () => Promise<void>;
  onCancel: () => void;
  cancelActiveTicket: FreeTicket | null;
}

interface CancelActiveTicketFormData {
  expiryDate: string;
}

const CancelActiveTicketModal: React.FC<Props> = ({ onSuccess, onCancel, cancelActiveTicket }) => {
  const freeTicketService = useValidFreeTicketAgreementService();
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const lang = useLanguageResource(freeTicketsFamilyLanguageResource);
  const [cancelActiveTicketInProgress, setCancelActiveTicketInProgress] = useState(false);
  const dates = getExpiryDates(32, formLang.today, 7);

  const formMethods = useForm<CancelActiveTicketFormData>({ defaultValues: { expiryDate: freeTicketService.showDateInput ? "" : dates[0].value } });
  const expiryDate = useWatch({ name: "expiryDate", control: formMethods.control });

  const postCancelActiveTicket = async (data: CancelActiveTicketFormData) => {
    if (!cancelActiveTicket) {
      throw new Error("no ticket to cancel");
    }
    setCancelActiveTicketInProgress(true);

    const expiry = ensureExpiryDateIsNotInThePastAndFormatForBackend(data.expiryDate, 0);
    const response = await apiClient.request(freeTicketApi.ticket.id(cancelActiveTicket.id).expiry.put(expiry));

    if (response.type !== "success") {
      failAlert(lang.cancelActiveTicketFail);
      setCancelActiveTicketInProgress(() => {
        throw response.error;
      });
    }

    setCancelActiveTicketInProgress(false);
    onSuccess();

  };

  return (
    <Modal
      isOpen={Boolean(cancelActiveTicket)}
      title={lang.cancelActiveTicket}
      handleClose={() => onCancel()}
      data-test-id="active-ticket-cancel-confirmation-window"
    >
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(postCancelActiveTicket)}>
          <FormGroup>
            {freeTicketService.showDateInput ? (
              <DateFormInput name="expiryDate" required minDate={new Date()} maxDate={addMonths(new Date(), 6)} label={lang.from} />
            ) : (
              <DropdownFormInput name="expiryDate" label={lang.from} required>
                {dates.map((d) => (
                  <option value={d.value} key={d.value}>
                    {d.text}
                  </option>
                ))}
              </DropdownFormInput>
            )}
          </FormGroup>
          <FormGroup>
            <Message skin="info" title={lang.cancelActiveTicketInfoTitle}>
              <p style={{ marginBottom: "1rem" }}>{lang.cancelActiveTicketInfoPrivacy.replace("$expiryDate$", expiryDate)}</p>
            </Message>
          </FormGroup>

          <FormGroup>
            <ButtonGroup>
              <Button
                type="submit"
                variant="primary"
                loading={cancelActiveTicketInProgress}
                text={formLang.yes}
                data-test-id="confirm-button"
              />
              <CancelButton onClick={() => onCancel()} data-test-id="cancel-button" />
            </ButtonGroup>
          </FormGroup>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default CancelActiveTicketModal;
