import { apiClient, ButtonGroup, FormGroup, Modal, TextFormInput, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import successAlert, { failAlert } from "src/common/toastr";
import { EditTicketFormData, SchoolTicketEditPreviewPostContract } from "src/types/schoolTicketAgreement/schoolTicketEdit";
import { SchoolTicketTravelCard } from "../../../../types/schoolTicketAgreement/schoolTicket";
import { CancelButton, SubmitButton } from "../../../common/buttons";
import { splitName } from "../../utils";
import "./EditTicket.scss";
import shoolTicketDetailModalLanguageResource from "./lang-resource";
interface Props {
  hide: () => void;
  submitted: () => void;
  ticket: SchoolTicketTravelCard;
}

const EditTicket = ({ hide, ticket, submitted }: Props) => {
  const lang = useLanguageResource(shoolTicketDetailModalLanguageResource);
  const [editTicketSubmitting, setEditTicketSubmitting] = useState(false);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const allowedActions = ticket.allowedActions;

  const editTicketFormMethods = useForm<EditTicketFormData>({
    defaultValues: {
      name: `${ticket.firstName || ""} ${ticket.lastName || ""}`.trim(),
      className: ticket.className,
      cardName: ticket.cardName,
      address: ticket.address,
    },
  });

  const mapPreviewContract = (formData: EditTicketFormData): SchoolTicketEditPreviewPostContract => {
    const contract: SchoolTicketEditPreviewPostContract = {};

    if (allowedActions.UPDATE_NAME) {
      const name = formData.name?.trim() || null;
      if (!name) {
        contract.firstName = null;
        contract.lastName = null;
      } else {
        const { firstName, lastName } = splitName(name);
        contract.firstName = firstName;
        contract.lastName = lastName;
      }
    }

    if (allowedActions.UPDATE_CLASS_NAME) {
      const className = formData.className?.trim() || null;
      contract.className = className;
    }

    if (allowedActions.UPDATE_CARD_NAME) {
      const cardName = formData.cardName?.trim() || null;
      contract.cardName = cardName;
    }

    if (allowedActions.UPDATE_ADDRESS) {
      const address = formData.address?.trim() || null;
      contract.address = address;
    }

    // if (allowedActions.UPDATE_STUDENTID) {
    //   const studentId = formData.studentId ? formData.studentId : null;
    //   contract.studentId = studentId || null;
    // }

    return contract;
  };

  const saveTicket = async (formData: EditTicketFormData) => {
    const postContract = mapPreviewContract(formData);
    setEditTicketSubmitting(true);
    const response = await apiClient.put(`/schoolticket-api/travel-card/${ticket.id}/`, postContract);
    if (response.type === "success") {
      setEditTicketSubmitting(false);
      successAlert(lang.editTicket.success);
      submitted();
    } else {
      failAlert(lang.editTicket.fail);
      setEditTicketSubmitting(() => {
        throw response.error;
      });
    }
  };

  const renderEditForm = () => (
    <FormProvider {...editTicketFormMethods}>
      <form onSubmit={editTicketFormMethods.handleSubmit(saveTicket)}>
        {ticket.allowedActions.UPDATE_CARD_NAME && <TextFormInput name="cardName" maxLength={50} label={lang.editTicket.cardName} />}
        {ticket.allowedActions.UPDATE_NAME && <TextFormInput name="name" maxLength={50} label={lang.editTicket.name} />}
        {ticket.allowedActions.UPDATE_CLASS_NAME && <TextFormInput name="className" maxLength={20} label={lang.editTicket.className} />}
        {ticket.allowedActions.UPDATE_ADDRESS && <TextFormInput name="address" maxLength={50} label={lang.editTicket.address} />}
        <FormGroup>
          <ButtonGroup>
            <SubmitButton submitting={editTicketSubmitting} text={formLang.save} />
            <CancelButton onClick={() => hide()} data-test-id="cancel-button" />
          </ButtonGroup>
        </FormGroup>
      </form>
    </FormProvider>
  );

  return (
    <Modal isOpen={true} title={lang.editTicket.title} handleClose={() => hide()} data-test-id="edit-ticket-window">
      {renderEditForm()}
    </Modal>
  );
};

export default EditTicket;
