import { formatter, Icon, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { format, isValid } from "date-fns";
import parsePhoneNumberFromString from "libphonenumber-js/max";
import { Dispatch, SetStateAction } from "react";
import FreeTicketZonesComponent from "src/components/common/text/ticketZones/TicketZonesField";
import { DATE_FORMAT, TICKET_ACTIVE } from "../../../constants";
import MediaType from "../../../types/mediaType";
import { SchoolTicket, SchoolTicketPhone } from "../../../types/schoolTicketAgreement/schoolTicket";
import TicketStatus2 from "../../common/text/ticketStatus/TicketStatus";
import { concatName } from "../utils";
import shoolTicketDetailLanguageResource, { ShoolTicketDetailLanguageResource } from "./lang-resource";
import "./MobileTicket.scss";
interface Props {
  ticket: SchoolTicketPhone;
  cancelActiveTicket: Dispatch<SetStateAction<SchoolTicket | null>>;
  resendTicket: Dispatch<SetStateAction<SchoolTicket | null>>;
  cancelPendingTicket: Dispatch<SetStateAction<SchoolTicket | null>>;
}

const formatPickedUp = (pickedUp: boolean, language: ShoolTicketDetailLanguageResource) => {
  if (pickedUp) {
    return language.yes;
  }
  return language.no;
};

export const formatStringOrNull = (value: string | null): string => {
  if (!value) {
    return "–";
  }
  return value;
};

export const formatDate = (expirationDate: Date | null): string => {
  if (expirationDate === null || !isValid(expirationDate)) {
    return "–";
  }
  return format(expirationDate, DATE_FORMAT);
};

const formatPhoneNumber = (phoneCountryCode?: string, phone?: string) => {
  if (!phone || !phone?.trim()) {
    return "–";
  }

  const countryCode = phoneCountryCode || "+47";

  let text = `${countryCode || ""} ${phone || ""}`;

  if (countryCode && phone) {
    const parsedNumber = parsePhoneNumberFromString(countryCode + phone);

    if (parsedNumber && parsedNumber.isValid()) {
      text = parsedNumber.formatInternational();
    }
  }
  return text;
};

export const formatCurrencyAmount = (ticket: SchoolTicket, amount: number | null | undefined): string => {
  if ((ticket.mediaType === MediaType.MOBILE_TICKET && ticket.status !== TICKET_ACTIVE) || amount === undefined || amount === null) {
    return "–";
  }

  return formatter.number.currency(amount, true);
};

const MobileTicket = ({ ticket, cancelActiveTicket, resendTicket, cancelPendingTicket }: Props) => {
  const lang = useLanguageResource(shoolTicketDetailLanguageResource);
  const fullName = concatName(ticket.firstName, ticket.lastName);
  const showCancelActiveTicketButton = !ticket.allowedActions.CANCEL;
  const showCancelPendingTicketButton = ticket.allowedActions.CANCEL;

  return (
    <div className="components-schoolticket-mobile" data-test-id="mobile-schoolticket">
      <div className={`status ${ticket.status}`} />
      <div className="employee-name">
        <div className="value" title={fullName} data-test-id="full-name">
          {fullName}
        </div>
      </div>
      <div className="info">
        <div className="row"></div>
        <div className="row">
          <div className="label">{lang.status}</div>
          <div className="value" data-test-id="status">
            <TicketStatus2 status={ticket.status as any} />
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.orderNumber}</div>
          <div className="value" data-test-id="ticket-id">
            {formatStringOrNull(ticket.id)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.phoneNumber}</div>
          <div className="value" data-test-id="phone">
            {formatPhoneNumber(ticket.phoneCountryCode || "", ticket.phone || "")}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.validFrom}</div>
          <div className="value" data-test-id="start-date">
            {formatDate(ticket.startDate)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.validTo}</div>
          <div className="value" data-test-id="expiration-date">
            {formatDate(ticket.expirationDate)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.pricePerMonth}</div>
          <div className="value" data-test-id="price-per-month">
            {formatCurrencyAmount(ticket, ticket.pricePerMonth)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.zones}</div>
          <div className="value" data-test-id="zones">
            <FreeTicketZonesComponent
              allZones={ticket.allZones}
              
              nrOfZones={ticket.nrOfZones || 0}
              zoneFrom={ticket.zoneFrom}
              zoneTo={ticket.zoneTo}
            />
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.pickedUp}</div>
          <div className="value" data-test-id="picked-up">
            {formatPickedUp(ticket.downloadedToPhone, lang)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.pickupCode}</div>
          <div className="value" data-test-id="pickup-code">
            {formatStringOrNull(ticket.pickupCode)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.appiId}</div>
          <div className="value" data-test-id="app-id">
            {formatStringOrNull(ticket.appId)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.invoiceRef}</div>
          <div className="value" data-test-id="department">
            {formatStringOrNull(ticket.invoiceReference)}
          </div>
        </div>
      </div>
      <div className="actions">
        <button type="button" disabled={!ticket.allowedActions.RESEND} onClick={() => resendTicket(ticket)} data-test-id="send-pickup-code">
          <Icon variant="CellPhoneArrowRightIcon" size="small" />
          <span>{lang.resendTicket}</span>
        </button>

        {showCancelActiveTicketButton && (
          <button
            type="button"
            disabled={!ticket.allowedActions.UPDATE_EXPIRY}
            data-test-id="cancel-active-ticket"
            onClick={() => {
              cancelActiveTicket(ticket);
            }}
          >
            <Icon variant="CrossIcon" size="small" />
            <span>{lang.cancelActiveTicket}</span>
          </button>
        )}

        {showCancelPendingTicketButton && (
          <button
            type="button"
            disabled={!ticket.allowedActions.CANCEL}
            data-test-id="cancel-pending-ticket"
            onClick={() => {
              cancelPendingTicket(ticket);
            }}
          >
            <Icon variant="CrossIcon" size="small" />
            <span>{lang.cancelPendingTicket}</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default MobileTicket;
