import { apiClient, Button, ButtonGroup, Container, IconButton, Modal, Table, TextFormInput, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAuthContextAuthenticated, useValidFreeTicketAgreementService } from "src/AuthContext";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import { TravelCardState } from "src/types/TravelCardState";
import formatter from "../../../common/formatter";
import { mapTravelCardRequestSearchResult, TravelCardRequest } from "../../../types/freeTicketAgreement/travelCardRequest";
import { CancelButton, SubmitButton } from "../../common/buttons";
import { freeTicketTravelCardLanguageResource } from "./lang-resource";
import "./TravelCardTodo.scss";

interface FormData {
  cardNumber: string;
}

const TravelCardTodoPage: React.FC = () => {
  const authContext = useAuthContextAuthenticated();
  const companies = authContext.userData.companies;
  const freeTicketService = useValidFreeTicketAgreementService();
  const lang = useLanguageResource(freeTicketTravelCardLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const [travelCards, setTravelCards] = useState<TravelCardRequest[] | null>(null);
  const [deactivateTicket, setDeactivateTicket] = useState<TravelCardRequest | null>(null);
  const [editTravelCardId, setEditTravelCardId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const formMethods = useForm<FormData>({
    defaultValues: {
      cardNumber: "",
    },
  });

  const getCompanyName = (companyId: string): string => {
    const company = companies.find((x) => x.id === companyId);

    if (!company) {
      return lang.unknown;
    }

    return company.name;
  };

  const getDepartment = (agreementNumber: string): string => {
    const invoiceRef = freeTicketService.invoiceRefs.find(x => x.agreementNumber === agreementNumber);
    if (invoiceRef) {
      return invoiceRef.invoiceReference;
    }

    return lang.unknown;
  };

  const fetch = async () => {
    const requestResponse = await apiClient.get("/freeticket-api/ticket/travel-card/requests", mapTravelCardRequestSearchResult);
    const expiredResponse = await apiClient.get("/freeticket-api/ticket/travel-card/past-expiry", mapTravelCardRequestSearchResult);

    if (requestResponse.error) {
      setTravelCards(() => {
        throw requestResponse.error;
      });
      return;
    }
    if (expiredResponse.error) {
      setTravelCards(() => {
        throw expiredResponse.error;
      });
      return;
    }

    const rows = [...requestResponse.result.matches, ...expiredResponse.result.matches].filter(
      (x) => x.state === TravelCardState.REQUESTED || x.state === TravelCardState.CREATED,
    );
    setTravelCards(rows);
    setLoading(false);
  };

  useEffect(() => {
    fetch();
  }, []);

  const changeStateToDeactivated = async (): Promise<void> => {
    setSubmitting(true);
    const response = await apiClient.put(`/freeticket-api/ticket/travel-card/${deactivateTicket?.id}/deactivate`);
    if (response.type === "success") {
      setSubmitting(false);
      setDeactivateTicket(null);
      await fetch();
    } else {
      setSubmitting(() => {
        throw response.error;
      });
    }
  };

  const changeStateToCreated = async (data: FormData): Promise<void> => {
    setSubmitting(true);
    const response = await apiClient.put(`/freeticket-api/ticket/travel-card/${editTravelCardId}/card-number/${data.cardNumber}`);
    if (response.type === "success") {
      setSubmitting(false);
      setEditTravelCardId(null);
      await fetch();
    } else {
      setSubmitting(() => {
        throw response.error;
      });
    }
  };

  const getStatus = (state: TravelCardState): string => {
    if (state === TravelCardState.REQUESTED) {
      return lang.createRequested;
    }
    if (state === TravelCardState.CREATED) {
      return lang.cancelRequested;
    }
    return "";
  };

  const renderRow = (row: TravelCardRequest) => (
    <tr key={row.id} data-test-id={row.id}>
      <td>{getCompanyName(row.companyId)}</td>
      <td>{getDepartment(row.agreementNumber)}</td>
      <td>{row.employeeId}</td>
      <td>{row.cardNumber}</td>
      <td>{row.firstName}</td>
      <td>{row.lastName}</td>
      <td>{formatter.date.shortDate(row.startDate)}</td>
      <td>{row.zoneFrom}</td>
      <td>{row.zoneTo || ""}</td>
      <td>{formatter.date.shortDate(row.dateOfBirth)}</td>
      <td>
        <div className="status-column">
          <div className={`status-icon ${row.state}`} />
          <div className="status-text">{getStatus(row.state)}</div>
        </div>
      </td>
      <td className="actions">
        {row.state === TravelCardState.REQUESTED && (
          <IconButton variant="PencilIcon" onClick={() => setEditTravelCardId(row.id)} data-test-id="change-status-to-create-button" aria-label={lang.editButton} />
        )}
        {row.state === TravelCardState.CREATED && (
          <IconButton variant="CrossIcon" onClick={() => setDeactivateTicket(row)} data-test-id="change-status-to-deactivated-button" aria-label={lang.deleteButton} />
        )}
      </td>
    </tr>
  );

  return (
    <Container width="full" className="components-freeticket-travelcard" data-test-id="components-freeticket-travelcard">
      <h1>{lang.title}</h1>

      <Table loading={loading} breakpoint="1000px">
        <thead>
          <tr>
            <th>{lang.company}</th>
            <th>{lang.department}</th>
            <th>{lang.employeeId}</th>
            <th>{lang.cardNumber}</th>
            <th>{formLang.firstName}</th>
            <th>{formLang.lastName}</th>
            <th>{lang.fromDate}</th>
            <th>{lang.fromZone}</th>
            <th>{lang.toZone}</th>
            <th>{lang.birthDate}</th>
            <th>{lang.status}</th>
            <th className="actions">&nbsp;</th>
          </tr>
        </thead>
        <tbody>{travelCards?.map(renderRow)}</tbody>
      </Table>

      <Modal
        isOpen={Boolean(deactivateTicket)}
        title={lang.deactivateTitle}
        handleClose={() => setDeactivateTicket(null)}
        data-test-id="change-status-to-deactivated-confirmation-window"
      >
        <p>{lang.confirmChangeToCancelled.replace("{cardnumber}", deactivateTicket?.cardNumber || "")}</p>
        <ButtonGroup>
          <Button
            variant="primary"
            loading={submitting}
            text={lang.yes}
            type="submit"
            onClick={changeStateToDeactivated}
          />
          <CancelButton onClick={() => setDeactivateTicket(null)} />
        </ButtonGroup>
      </Modal>

      <Modal
        isOpen={Boolean(editTravelCardId)}
        title={lang.createdTitle}
        handleClose={() => setEditTravelCardId(null)}
        data-test-id="change-status-to-created-confirmation-window"
      >
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(changeStateToCreated)}>
            <TextFormInput name="cardNumber" required minLength={9} maxLength={9} label={lang.cardNumber} />
            <ButtonGroup>
              <SubmitButton text={formLang.save} submitting={submitting} />
              <CancelButton onClick={() => setEditTravelCardId(null)} />
            </ButtonGroup>
          </form>
        </FormProvider>
      </Modal>
    </Container>
  );
};

// freeticket-api/ticket/travel-card/requests

export default TravelCardTodoPage;
