import { apiClient, Button, ButtonGroup, Message, Modal, TextFormInput, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import { CompanyAddresses } from "src/types/Company";
import { CompanyType } from "src/types/CompanyType";
import "./EditCompanyAddress.scss";
import editCompanyAddressesLanguageResource from "./lang-resource";

export interface EditAddressesFormFields {
  visitAddressLine1: string;
  visitAddressLine2: string;
  visitPostCode: string;
  visitCity: string;

  billAddressLine1: string;
  billAddressLine2: string;
  billPostCode: string;
  billCity: string;

  postAddressLine1: string;
  postAddressLine2: string;
  postPostCode: string;
  postCity: string;
}

interface CallbackProps {
  companyType: CompanyType
  type: "callback",
  companyAddresses: CompanyAddresses;
  onClose: () => void,
  onSave: (data: EditAddressesFormFields) => void,
}

interface SaveProps {
  type: "saveToBackend",
  companyType: CompanyType
  companyId: string,
  companyAddresses: CompanyAddresses;
  onClose: () => void;
  onSuccess: () => void;
}

type Props = CallbackProps | SaveProps;



interface AddressContract {
  addressLine1: string;
  addressLine2: string;
  postCode: string;
  postArea: string;
  countryCode: string;
}

interface RequestContract {
  visitAddress: AddressContract;
  billAddress: AddressContract;
  postAddress: AddressContract;
}

const EditCompanyAddress: React.FC<Props> = (props) => {
  const lang = useLanguageResource(editCompanyAddressesLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const [loading, setLoading] = useState(false);
  const [submitFailure, setSubmitFailure] = useState(false);
  const { companyAddresses, onClose, companyType } = props;

  const formMethods = useForm<EditAddressesFormFields>({
    defaultValues: {
      visitAddressLine1: companyAddresses.visit.line1,
      visitAddressLine2: companyAddresses.visit.line2,
      visitPostCode: companyAddresses.visit.postCode,
      visitCity: companyAddresses.visit.city,

      billAddressLine1: companyAddresses.bill.line1,
      billAddressLine2: companyAddresses.bill.line2,
      billPostCode: companyAddresses.bill.postCode,
      billCity: companyAddresses.bill.city,

      postAddressLine1: companyAddresses.post.line1,
      postAddressLine2: companyAddresses.post.line2,
      postPostCode: companyAddresses.post.postCode,
      postCity: companyAddresses.post.city,
    },
  });

  const saveAddresses = async (data: EditAddressesFormFields, componentProps: SaveProps) => {
    const { companyId, onSuccess } = componentProps;

    const requestBody: RequestContract = {
      billAddress: {
        addressLine1: data.billAddressLine1,
        addressLine2: data.billAddressLine2,
        postCode: data.billPostCode,
        postArea: data.billCity,
        countryCode: "NO",
      },
      postAddress: {
        addressLine1: data.postAddressLine1,
        addressLine2: data.postAddressLine2,
        postCode: data.postPostCode,
        postArea: data.postCity,
        countryCode: "NO",
      },
      visitAddress: {
        addressLine1: data.visitAddressLine1,
        addressLine2: data.visitAddressLine2,
        postCode: data.visitPostCode,
        postArea: data.visitCity,
        countryCode: "NO",
      },
    };


    setSubmitFailure(false);
    setLoading(true);
    const result = await apiClient.put(`/onboarding/company/${companyId}/address`, requestBody);
    if (result.type === "success") {
      onSuccess();
    } else {
      setSubmitFailure(true);
    }

    setLoading(false);
  };

  const onSubmit = async (data: EditAddressesFormFields) => {
    if (props.type === "callback") {
      const { onSave } = props;
      onSave(data);
    } else {
      saveAddresses(data, props);
    }
  };

  const renderAddress = (type: "bill" | "post" | "visit") => {
    let text = lang.billAddress;
    if (type === "post") {
      text = lang.postAddress;
    } else if (type === "visit") {
      text = lang.visitAddress;
    }

    return (
      <>
        <h3>{text}</h3>
        <TextFormInput name={`${type}AddressLine1`} validationLabel={lang.address1} required maxLength={200} />
        <TextFormInput name={`${type}AddressLine2`} validationLabel={lang.address2} maxLength={200} />
        <div className="zip-code-and-area">
          <div className="zip-code">
            <TextFormInput name={`${type}PostCode`} required numericOnly minLength={4} maxLength={4} label={lang.zipCode} />
          </div>
          <div className="area">
            <TextFormInput name={`${type}City`} required maxLength={200} label={lang.area} />
          </div>
        </div>
      </>
    );
  };

  return (
    <Modal
      isOpen={true}
      title={lang.title}
      handleClose={() => onClose()}
      className="edit-company-address-modal"
      data-test-id="edit-company-address-modal"
    >
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          {renderAddress("bill")}
          {renderAddress("post")}
          {renderAddress("visit")}
          {submitFailure && (
            <Message skin="danger" data-test-id="submit-failure" style={{ marginTop: "2rem" }}>
              <p>{lang.submitFailure}</p>
            </Message>
          )}
          <ButtonGroup>
            {companyType !== CompanyType.RUTER_CONTRACTOR && (<Button type="submit" variant="primary" text={formLang.save} loading={loading} />)}

            <Button type="button" variant="cancel" text={formLang.cancel} onClick={() => onClose()} data-test-id="cancel-button" />
          </ButtonGroup>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default EditCompanyAddress;
