import { UNKNOWN } from "../../constants";
import { CompanyRoleType, parseCompanyRoleType } from "./CompanyRoleType";

export const getValidCompanyRoleJson = (changes?: Partial<CompanyRoleJson>): CompanyRoleJson => ({
  companyId: "1226629",
  companyName: "Steffens Kosmetikk",
  companyType: "SIEBEL_CUSTOMER_NUMBER",
  roles: [CompanyRoleType.FreeTicketOrCompanyTicketAdmin.toString()],
  ...changes,
});

export interface CompanyRoleJson {
  companyId: string;
  companyName: string;
  companyType: string;
  roles: string[];
}

export interface CompanyRole {
  companyId: string;
  companyName: string;
  companyType: string;
  roles: (CompanyRoleType | typeof UNKNOWN)[];
}

export const mapCompanyRole = (json: CompanyRoleJson): CompanyRole => ({
  companyId: json.companyId,
  companyName: json.companyName,
  companyType: json.companyType,
  roles: json.roles.map(parseCompanyRoleType),
});
