import { parseUserRoleType, UserRoleType } from "./user/UserRoleType";

export interface Contact {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneCountryCode: string;
  phone: string;
  roles: Array<UserRoleType>;
}

export interface UserDataContact {
  firstName: string
  lastName: string
  phone: string
  email: string
}

export interface ContactJson {
  city: string;
  country: string;
  customerNumber: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  phone: string;
  phoneCountryCode: string;
  postCode: string;
  roles: string[];
  status: string;
  streetAddress: string;
  streetAddress2: string;
  type: string;
}

export const mapContact = (json: ContactJson): Contact => {
  const roles = json.roles.map(parseUserRoleType).filter((x): x is UserRoleType => x !== "UNKNOWN");

  return {
    id: json.id,
    firstName: json.firstName,
    lastName: json.lastName,
    email: json.email,
    phoneCountryCode: json.phoneCountryCode,
    phone: json.phone,
    roles: roles,
  };
};
