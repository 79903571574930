import { AuthAgreement } from "src/types/AuthCompany";

export interface InvoiceRef {
  agreementNumber: string
  invoiceReference: string
  endDate: Date | null
}

export const mapInvoiceRef = (agreement: AuthAgreement): InvoiceRef => ({
  agreementNumber: agreement.id,
  invoiceReference: agreement.invoiceReference,
  endDate: agreement.endDate,
});
