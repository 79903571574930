import { AuthContextValues } from "src/AuthContext";

interface ExternalUrl {
  type: "External",
  url: string
}

interface InternalUrl {
  type: "Internal",
  url: string
}

type UrlType = InternalUrl | ExternalUrl;

export const getInternalOrExternalContactUsUrl = (authContext: AuthContextValues): UrlType => {
  if (authContext.authenticated && authContext.features.urls.contactUs.internal) {
    return {
      type: "Internal",
      url: authContext.features.urls.contactUs.internal,
    };
  }

  return {
    type: "External",
    url: authContext.features.urls.contactUs.external,
  };
};