import { Zone } from "src/common/api/commonTypes/Zone";
import ZoneInput from "src/components/common/form-hooks/ZoneInput";
import ZoneInputBrakar from "src/components/common/form-hooks/ZoneInputBrakar";
import { ALL_ZONES_FROM, ALL_ZONES_TO } from "src/constants";
import { Tenant } from "src/types/features/Tenant";


export interface DefaultZones {
  toZone: string;
  fromZone: string
  allZonesFrom: string | null
  allZonesTo: string | null
}

const getDefaultZones = (tenant: Tenant): DefaultZones => {
  switch (tenant) {
    case Tenant.Ruter:
      return {
        toZone: "2Ø",
        fromZone: "1",
        allZonesFrom: ALL_ZONES_FROM,
        allZonesTo: ALL_ZONES_TO,
      };
    case Tenant.Akt:
      return {
        allZonesFrom: null,
        allZonesTo: null,
        fromZone: "Kristiansand",
        toZone: "Birkenes",
      };
    default:
      throw new Error(`Missing default zones for tenant: ${tenant}`);
  }
};


export const getZoneInput = (zones: Zone[], tenant: Tenant): JSX.Element => {

  switch (tenant) {
    case Tenant.Brakar:
      return <ZoneInputBrakar zones={zones} />;
    case Tenant.Akt:
      return <ZoneInput zones={zones} defaultZones={getDefaultZones(tenant)} />;
    case Tenant.Ruter:
      return <ZoneInput zones={zones} defaultZones={getDefaultZones(tenant)} />;
    default:
      throw new Error(`tenant: ${tenant} dont have specified Zone input`);
  }
};