import { Container, Dropdown, FormGroup, Label, Message, Table, apiClient, useLanguageResource } from "@ruter-as/web-components-and-tools";
import moment from "moment";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useAuthContextAuthenticated, useValidFreeTicketAgreementService } from "src/AuthContext";
import createAndDownloadCSV from "../../../common/csvFileCreator";
import formatter from "../../../common/formatter";
import { TaxBaseChange, mapTaxBaseChanges } from "../../../types/freeTicketAgreement/taxBaseChange";
import { TaxBaseChangeCause } from "../../../types/freeTicketAgreement/taxBaseChangeCause";
import { ButtonLink } from "../../common/buttons";
import InputSkeleton from "../../common/skeleton/InputSkeleton";
import "./TaxBaseChangesPage.scss";
import { taxBaseChangesLanguageResource } from "./lang-resource";

interface Option {
  value: string;
  text: string;
}

const ALL_VALUE = "0";

const TaxBaseChangesPage: React.FC = () => {
  const lang = useLanguageResource(taxBaseChangesLanguageResource);
  const authContext = useAuthContextAuthenticated();
  const selectedCompany = authContext.userData.selectedCompany;
  const freeTicketService = useValidFreeTicketAgreementService();

  const [taxBaseChanges, setTaxBaseChanges] = useState<null | TaxBaseChange[]>(null);
  const [tableLoading, setTableLoading] = useState(true);
  const [fullLoading, setFullLoading] = useState(true);
  const [departmentOptions, setDepartmentOptions] = useState<Option[] | null>(null);
  const [selectedDepartment, setSelectedDepartment] = useState(ALL_VALUE);

  const findInvoiceRef = useCallback(
    (agreementNumber: string) => {
      const agreement = freeTicketService.invoiceRefs.find((x) => x.agreementNumber === agreementNumber);
      return agreement?.invoiceReference || `${lang.missingDepartmentName} (${agreementNumber})`;
    },
    [lang.missingDepartmentName, freeTicketService],
  );

  const calculateDepartments = useCallback(
    (taxBaseChangesInput: TaxBaseChange[]) => {
      const options = taxBaseChangesInput
        .map((x) => x.agreementNumber)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((x) => ({ value: x, text: findInvoiceRef(x) }));

      options.sort((a, b) => {
        const createSortString = (item: Option) => {
          if (item.text === lang.all) {
            return "0";
          }
          return `z${item.text.toLowerCase()}`;
        };

        const sortA = createSortString(a);
        const sortB = createSortString(b);

        if (sortA < sortB) {
          return -1;
        }
        if (sortA > sortB) {
          return 1;
        }
        return 0;
      });

      setDepartmentOptions([{ value: ALL_VALUE, text: lang.all }, ...options]);
    },
    [lang.all, findInvoiceRef],
  );

  useEffect(() => {
    const fetch = async () => {
      const response = await apiClient.get(`/freeticket-api/tax-base/changes/by-company-id/${selectedCompany?.id}`, mapTaxBaseChanges);

      if (response.type === "success") {
        calculateDepartments(response.result);
        setTaxBaseChanges(response.result);
        setTableLoading(false);
        setFullLoading(false);
      } else {
        setTaxBaseChanges(() => {
          throw response.error;
        });
      }
    };
    fetch();
  }, [selectedCompany?.id, calculateDepartments]);

  const getTextForTaxBaseChangeCause = (changeCause: TaxBaseChangeCause): string => {
    let text = "";
    if (changeCause === TaxBaseChangeCause.PRICE_CHANGED) {
      text = lang.causePriceChange;
    } else if (changeCause === TaxBaseChangeCause.SUBSCRIBED) {
      text = lang.causeSubscribed;
    } else if (changeCause === TaxBaseChangeCause.UNSUBSCRIBED) {
      text = lang.causeUnsubscribed;
    }

    return text;
  };

  const download = () => {
    if (!taxBaseChanges) {
      return;
    }

    const csvModel = taxBaseChanges.map((x) => [
      getTextForTaxBaseChangeCause(x.changeType),
      x.employeeId,
      x.lastName,
      x.firstName,
      x.changeType === TaxBaseChangeCause.UNSUBSCRIBED || x.changeType === TaxBaseChangeCause.PRICE_CHANGED
        ? formatter.number.float(x.oldPrice, 2)
        : "",
      x.changeType === TaxBaseChangeCause.SUBSCRIBED || x.changeType === TaxBaseChangeCause.PRICE_CHANGED
        ? formatter.number.float(x.newPrice, 2)
        : "",
    ]);

    const headers = [lang.cause, lang.employeeNumber, lang.lastName, lang.firstName, lang.oldPrice, lang.newPrice];

    const agreementName = departmentOptions?.find((x) => x.value === selectedDepartment)?.text || "";
    const todaysDate = moment().format("DD.MM.YYYY");
    const fileName = lang.csvFileName.replace("{department}", agreementName).replace("{date}", todaysDate);

    createAndDownloadCSV(fileName, headers, csvModel);
  };

  const onDepartmentChange = async (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setSelectedDepartment(value);

    const url = (value === ALL_VALUE)
      ? `/freeticket-api/tax-base/changes/by-company-id/${selectedCompany?.id}`
      : `/freeticket-api/tax-base/changes/by-agreement-number/${value}`;

    setTableLoading(true);
    const response = await apiClient.get(url, mapTaxBaseChanges);
    if (response.type === "success") {
      setTaxBaseChanges(response.result);
      setTableLoading(false);
    } else {
      setTaxBaseChanges(() => {
        throw response.error;
      });
    }
  };

  const renderFilter = () => (
    <div className="filter">
      {fullLoading ? (
        <div className="inputs">
          <InputSkeleton />
        </div>
      ) : (
        <>
          <div className="inputs">
            <FormGroup>
              <Label text={lang.department}>
                <Dropdown name="agreement-select" onChange={onDepartmentChange} value={selectedDepartment}>
                  {departmentOptions?.map((a) => (
                    <option key={a.value} value={a.value}>
                      {a.text}
                    </option>
                  ))}
                </Dropdown>
              </Label>

            </FormGroup>
          </div>
          <div className="download">
            <ButtonLink text={lang.downloadAsCSV} onClick={download} dataTestId="download-button" />
          </div>
        </>
      )}
    </div>
  );

  const renderPrice = (row: TaxBaseChange) => {
    const showOldPrice = row.changeType === TaxBaseChangeCause.UNSUBSCRIBED || row.changeType === TaxBaseChangeCause.PRICE_CHANGED;
    const shownewPrice = row.changeType === TaxBaseChangeCause.SUBSCRIBED || row.changeType === TaxBaseChangeCause.PRICE_CHANGED;
    return (
      <td className="price-column">
        <span className="currency-prefix">kr </span>
        {showOldPrice ? <span className="old-price">{formatter.number.float(row.oldPrice, 2)}</span> : null}
        {shownewPrice ? <span className="new-price">{formatter.number.float(row.newPrice, 2)}</span> : null}
      </td>
    );
  };

  const renderRow = (row: TaxBaseChange) => (
    <tr>
      <td>{getTextForTaxBaseChangeCause(row.changeType)}</td>
      <td>{row.employeeId}</td>
      <td>{row.lastName}</td>
      <td>{row.firstName}</td>
      {renderPrice(row)}
    </tr>
  );

  const thisMonth = moment().toDate();
  const lastMonth = moment().add(-1, "month").toDate();
  const thisMonthAsString = formatter.date.monthAndYear(thisMonth.getFullYear(), thisMonth.getMonth() + 1);
  const lastMonthAsString = formatter.date.monthAndYear(lastMonth.getFullYear(), lastMonth.getMonth() + 1);

  return (
    <Container
      width="m"
      className="components-freeticketagreement-taxbasechanges"
      data-test-id="components-freeticketagreement-taxbasechanges"
    >
      <h1>{lang.title}</h1>
      <Message skin="info" data-test-id="description" style={{ maxWidth: "600px", marginBottom: "1rem" }}>
        {lang.description.replace("{period1}", thisMonthAsString).replace("{period2}", lastMonthAsString)}
      </Message>
      {renderFilter()}
      <Table loading={tableLoading || fullLoading}>
        <thead>
          <tr>
            <th>{lang.cause}</th>
            <th>{lang.employeeNumber}</th>
            <th>{lang.lastName}</th>
            <th>{lang.firstName}</th>
            <th className="price-column">{lang.priceDifference}</th>
          </tr>
        </thead>
        <tbody>{taxBaseChanges?.map(renderRow)}</tbody>
      </Table>
    </Container>
  );
};

export default TaxBaseChangesPage;
