import { UNKNOWN } from "../../constants";

export enum CompanyRoleType {
  TicketCounterAdmin = "TICKET_ADMIN",
  UserAdmin = "USER_ADMIN",
  FreeTicketOrCompanyTicketAdmin = "COMPANY_ADMIN",
  SchoolTicketAdmin = "SCHOOLTICKET_ADMIN",
}

export const parseCompanyRoleType = (value: string): CompanyRoleType | typeof UNKNOWN => {
  const parsed = Object.values(CompanyRoleType).find((x) => x === value);

  if (parsed === undefined) {
    return UNKNOWN;
  }
  return parsed;
};
