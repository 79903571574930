import { Navigate } from "react-router-dom";
import { useAuthContext } from "src/AuthContext";

const RootRedirect: React.FC = () => {
  const authContext = useAuthContext();


  if (!authContext.authenticated) {
    return <Navigate to="/home" />;
  }
  const { services } = authContext.features;

  if (authContext.features.services.freeTicket.hasService && authContext.features.services.freeTicket.hasTravelCardAdmin) {
    return <Navigate to="/fribilletter/reisekort" />;
  }

  if (authContext.features.travelCardAdmin.hasAccess) {
    return <Navigate to="/reisekort" />;
  }

  if (services.ticketCounter.hasService) {
    if (services.ticketCounter.allowedOrderTicket) {
      return <Navigate to="/billettluka" />;
    } else {
      return <Navigate to="/billettluka/ordrehistorikk" />;
    }
  }
  if (services.companyTicket.hasService) {
    return <Navigate to="/bedriftsavtale" />;
  }
  if (services.freeTicket.hasService) {
    return <Navigate to="/fribilletter" />;
  }
  if (services.schoolTicket.hasService) {
    return <Navigate to="/skole" />;
  }
  if (services.housingAssociation.hasService){
    return <Navigate to="/borettslag"/>;
  }

  return <Navigate to="/home" />;
};

export default RootRedirect;