enum AgreementSubTypeId {
  FREETICKET = "FREETICKET",
  NO_SUBSCRIPTIONS = "NO_SUBSCRIPTIONS",
  SCHOOLTICKET = "SCHOOLTICKET",
  SUBSCRIPTIONS = "SUBSCRIPTIONS",
  TICKETOFFICE = "TICKETOFFICE",
  TRAVELCARD_FIXED_PRICE = "TRAVELCARD_FIXED_PRICE",
  TRAVELCARD_SUBSCRIPTION = "TRAVELCARD_SUBSCRIPTION",
  HOUSING_ASSOCIATION = "HOUSING_COOPERATIVE_AGREEMENT",
  OTHER = "OTHER",
}

const parseAgreementSubTypeId = (value: string): AgreementSubTypeId => {
  const parsed = Object.values(AgreementSubTypeId).find((x) => x === value);

  if (parsed === undefined) {
    return AgreementSubTypeId.OTHER;
  }
  return parsed;
};

export { AgreementSubTypeId, parseAgreementSubTypeId };

