import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface HousingAssociationWidgetLanguageResource {
  title: string;
  chooseCompanies: string;
  chooseSize: string;
  pixels: string;
  sizeBoundaries: string;
  chooseTheme: string;
  themeLight: string;
  themeDark: string;
  copyCodeHeader: string;
  copyCode: string;
  reset: string;
  size: string;
  theme: string;
  preview: string;
  previewInfo: string;
  copiedToClipboard: string;
  linkTitle: string;
  copyUrl: string;
}

export const housingAssociationWidgetLanguageResource: LanguageResource<HousingAssociationWidgetLanguageResource> = {
  nb: {
    title: "Widget for bestilling av borettslagbillett",
    chooseCompanies: "1. Borettslag som billetten skal kobles til",
    chooseSize: "2. Velg størrelse (px)",
    pixels: "px",
    sizeBoundaries: "Minste bredde er 320 piksler. Største bredde er 600 piksler.",
    chooseTheme: "3. Velg farge",
    themeLight: "Lys",
    themeDark: "Mørk",
    copyCodeHeader: "4. Kopier koden",
    copyCode: "Kopier koden",
    reset: "Nullstill skjema",
    size: "Størrelse",
    theme: "Farge",
    preview: "Forhåndsvisning",
    copiedToClipboard: "Kode kopiert til utklippstavle!",
    previewInfo: "Det er ikke mulig å bestille billetter i denne forhåndsvisningen",
    linkTitle: "Lenke til bestillingskjema",
    copyUrl: "Kopier url",
  },
  en: {
    title: "Widget for ordering tickets",
    chooseCompanies: "1. Housing association to connect the ticket to",
    chooseSize: "2. Choose size (px)",
    pixels: "px",
    sizeBoundaries: "Minimum width is 320 pixels, maximum width is 600 pixels.",
    chooseTheme: "Choose theme",
    themeLight: "Light",
    themeDark: "Dark",
    copyCode: "Copy code",
    copyCodeHeader: "4. Copy code",
    reset: "Reset widget",
    size: "Size",
    theme: "Theme",
    preview: "Preview",
    copiedToClipboard: "Code copied to clipboard!",
    previewInfo: "It is not possible to order tickets in this preview",
    linkTitle: "Link to order form",
    copyUrl: "Copy url",
  },
};
