import { getValidHousingAssociationInvoiceLineJson, HousingAssociationInvoiceLine, HousingAssociationInvoiceLineJson } from "./housingAssociationInvoiceLine";

export interface HousingAssociationInvoiceJson {
  id: string;
  invoiceNumber: null;
  year: number;
  month: number;
  invoiceLine: HousingAssociationInvoiceLineJson;
}

export interface HousingAssociationInvoice {
  id: string;
  invoiceNumber: null;
  year: number;
  month: number;
  invoiceLine: HousingAssociationInvoiceLine;
}

const mapHousingAssociationInvoice = (json: HousingAssociationInvoiceJson): HousingAssociationInvoice => (json);

export const mapHousingAssociationInvoices = (json: HousingAssociationInvoiceJson[]): HousingAssociationInvoice[] => json.map(mapHousingAssociationInvoice);

export const getValidHousingAssociationInvoiceJson = (changes?: Partial<HousingAssociationInvoiceJson>): HousingAssociationInvoiceJson => ({
  id: "1-3369898706_2021_01",
  invoiceLine: getValidHousingAssociationInvoiceLineJson(),
  invoiceNumber: null,
  month: 1,
  year: 2021,
  ...changes,
});
