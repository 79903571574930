import { apiClient, ButtonGroup, CheckBoxForminput, Container, DropdownFormInput, FieldValidationError, formatter, parser, PhoneFormInput, TextFormInput, useLanguageResource } from "@ruter-as/web-components-and-tools";
import * as Sentry from "@sentry/react";
import parsePhoneNumberFromString from "libphonenumber-js/max";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContextAuthenticated, useValidCompanyTicketService } from "src/AuthContext";
import { Zone } from "src/common/api/commonTypes/Zone";
import companyAgreementApi from "src/common/api/companyAgreementApi/companyAgreementApi";
import CreateCompanyTicketFormData, { CreateCompanyTicketErrorType, QuaranteeenError, QuaranteeenErrorJson } from "src/common/api/companyAgreementApi/createTicketFormData";
import CreateticketPostContract from "src/common/api/companyAgreementApi/createTicketPostContract";
import { mapCompanyAgreementOrder } from "src/common/api/companyAgreementApi/order";
import { getExpiryDates } from "src/common/expiryDate";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import getTicketOwnerFieldName from "src/common/ticketOwnerFieldName";
import { CancelButton, SubmitButton } from "src/components/common/buttons";
import ProductPrice from "src/components/common/form/ProductPrice";
import { createTicketLanguageResource } from "./lang-resource";

const CreateTicketform: React.FC = () => {
  const authContext = useAuthContextAuthenticated();
  const companyTicketService = useValidCompanyTicketService();
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const navigate = useNavigate();
  const lang = useLanguageResource(createTicketLanguageResource);
  const ticketOwnerFieldName = getTicketOwnerFieldName(companyTicketService);
  const [zones, setZones] = useState<Zone[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [backendError, setBackendError] = useState<CreateCompanyTicketErrorType>();
  const dates = companyTicketService.hasIncreasedExpiryDate ? getExpiryDates(32, formLang.today) : getExpiryDates(6, formLang.today);

  useEffect(() => {
    const fetchZones = async () => {
      const response = await apiClient.request(companyAgreementApi.zones.getAll());
      if (response.type === "success") {
        setZones(response.result);
      } else {
        setZones(() => {
          throw response.error;
        });
      }
    };

    fetchZones();
    return () => { setZones(undefined); };
  }, []);

  const formMethods = useForm<CreateCompanyTicketFormData>({
    defaultValues: {
      employeeNumber: "",
      firstName: "",
      lastName: "",
      agreementNumber: companyTicketService.invoiceRefs[0].agreementNumber || "",
      phone: "",
      startDate: dates[0].value,
      zoneFrom: "1",
      zoneTo: "1",
    },
  });

  if (!zones) {
    return null;
  }

  const onSubmit = async (data: CreateCompanyTicketFormData) => {
    setLoading(true);
    const phone = parsePhoneNumberFromString(data.phone);

    if (!phone || !phone.isValid()) {
      throw new Error("invalid phone number should be impossible as it is validated by the form");
    }

    const backendContract: CreateticketPostContract = {
      agreementName: data.agreementNumber,
      employeeId: data.employeeNumber,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: phone.nationalNumber.toString(),
      phoneCountryCode: `+${phone.countryCallingCode}`,
      startDate: parser.date.fromShortDateString(data.startDate).toISOString(),
      zoneFrom: data.zoneFrom,
      zoneTo: data.zoneTo,
      addOns: [],
    };

    const response = await apiClient.request(companyAgreementApi.ticket.create(backendContract));

    if (response.type === "success") {
      navigate("/bedriftsavtale");
    } else if (response.type === "HttpError" && response.responseStatus === 409) {
      Sentry.captureException(response.error, { extra: { responseBody: response.responseBody } });
      const responseBodyJson = response.responseBody as QuaranteeenErrorJson;

      if (responseBodyJson.expectedQuaranteenEndDate && responseBodyJson.lastOrder?.expirationDate) {
        const responseBody: QuaranteeenError = {
          type: "QUARANTEEN_ERROR",
          expectedQuaranteenEndDate: new Date(responseBodyJson.expectedQuaranteenEndDate),
          lastOrder: mapCompanyAgreementOrder(responseBodyJson.lastOrder),
        };

        setBackendError(responseBody);
      } else {
        setBackendError({ type: "EXISTING_OPEN_TICKET_ERROR" });
      }
    } else {
      setLoading(() => {
        throw response.error;
      });
    }
    setLoading(false);
  };

  const getAgreementText = (invoiceReference: string, agreementNumber: string) => {
    if (invoiceReference) {
      return `${invoiceReference} (${agreementNumber})`;
    }
    return `${agreementNumber}`;
  };


  const options = dates.map((o) => ({
    value: o.value,
    text: o.text,
  }));

  const renderErrorMessage = (error: CreateCompanyTicketErrorType) => {
    if (error.type === "QUARANTEEN_ERROR") {
      const formattedLastOrderExpirationDate = error.lastOrder.expirationDate ? formatter.date.toShortDateString(error.lastOrder.expirationDate) : "–";
      const formattedExpectedQuaranteenEndDate = formatter.date.toShortDateString(error.expectedQuaranteenEndDate);
      return <FieldValidationError text={lang.gapToSmall(formattedLastOrderExpirationDate, formattedExpectedQuaranteenEndDate)} />;
    } else {
      return <FieldValidationError text={lang.errorExistingOpenTicket} />;
    }
  };

  return (
    <Container width="xs" data-test-id="create-ticket-form-page" className="create-ticket-form-page">
      <h1>{lang.title}</h1>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <PhoneFormInput name="phone" required label={formLang.phone} mobileOnly />
          <TextFormInput name="firstName" required minLength={2} maxLength={50} label={formLang.firstName} />
          <TextFormInput name="lastName" required minLength={2} maxLength={50} label={formLang.lastName} />
          <TextFormInput name="employeeNumber" required minLength={2} maxLength={20} label={ticketOwnerFieldName} />
          <DropdownFormInput name="agreementNumber" label={formLang.invoiceReference} required>
            {companyTicketService.invoiceRefs.map((option) => (
              <option value={option.agreementNumber} key={option.agreementNumber}>{getAgreementText(option.invoiceReference, option.agreementNumber)}</option>
            ))}
          </DropdownFormInput>
          {authContext.features.getZoneInput(zones)}
          <DropdownFormInput name="startDate" required label={formLang.startDate}>
            {options.map(option => <option value={option.value} key={option.value}>{option.text}</option>)}
          </DropdownFormInput>
          <ProductPrice />
          <CheckBoxForminput name="acceptPrivacy" label={lang.acceptPrivay} required />
          <Link to={"/vilkar/ruterbedrift"} style={{ marginTop: "1.5rem", display: "block" }}>{lang.readPolicies}</Link>
          {backendError && renderErrorMessage(backendError)}
          <ButtonGroup>
            <SubmitButton submitting={loading} text={lang.orderTicket} />
            <CancelButton onClick={() => { navigate("/bedriftsavtale"); }} />
          </ButtonGroup>
        </form>
      </FormProvider>
    </Container>
  );
};

export default CreateTicketform;