import { apiClient, Container, formatter, Table, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Zone } from "src/common/api/commonTypes/Zone";
import housingAssociationApi from "src/common/api/companyAgreementApi/housingAssociation/housingAssociationApi";
import { HousingAssociationInvoice, HousingAssociationInvoiceJson } from "src/common/api/companyAgreementApi/housingAssociation/housingAssociationInvoice";
import { HousingAssociationOrder } from "src/common/api/companyAgreementApi/housingAssociation/housingAssociationOrder";
import PriceColumn from "src/components/common/Table/PriceColumn/PriceColumn";
import { TicketZonesComponent } from "src/components/common/text/ticketZones/TicketZones";
import SkeletonTicket from "src/components/companyAgreement/employeeTickets/SkeletonTicket";
import { TICKET_ACTIVE, TICKET_CANCELLED, TICKET_EXPIRED, TICKET_PENDING } from "src/constants";
import { SearchResult } from "src/types/SearchResult";
import "./HousingUnit.scss";
import { housingUnitLanguageResource } from "./lang-resource";
import Ticket from "./Ticket";

interface PageData {
  orders: SearchResult<HousingAssociationOrder>;
  employeeInvoices: HousingAssociationInvoice[];
  zones: Zone[];
}

const getMonth = (month: number, year: number) => formatter.date.toMonthAndYear(new Date(year, month - 1, 1));

const renderPayrollRow = (data: HousingAssociationInvoice) => (
  <tr key={data.id}>
    <td>{getMonth(data.month, data.year)}</td>
    <td>{data.invoiceLine.ticketId}</td>
    <td>
      {data.invoiceLine.numberOfZones === 0 ? (
        "-"
      ) : (
        <TicketZonesComponent zones={data.invoiceLine.zones} nrOfZones={data.invoiceLine.numberOfZones}  />
      )}
    </td>
    <PriceColumn price={data.invoiceLine.price} />
  </tr>
);

const sortRows = (a: HousingAssociationInvoiceJson, b: HousingAssociationInvoiceJson) => {
  const sortA = new Date(a.year, a.month, 1).toISOString();
  const sortB = new Date(b.year, b.month, 1).toISOString();

  if (sortA > sortB) {
    return -1;
  }
  if (sortA < sortB) {
    return 1;
  }

  return 0;
};


const HousingUnit: React.FC = () => {
  const language = useLanguageResource(housingUnitLanguageResource);
  const { id } = useParams();
  const [pageData, setPageData] = useState<PageData | undefined>();
  const [loading, setLoading] = useState(true);

  const fetch = useCallback(async () => {
    setLoading(true);
    const [ordersResponse,  invoicesResponse, zonesResponse] = await Promise.all([
      apiClient.request(housingAssociationApi.ticket.getTicketsByTicketId(id || "")),
      apiClient.request(housingAssociationApi.invoice.getByTicketId(id || "")),
      apiClient.request(housingAssociationApi.zones.getAll()),
    ]);

    if (ordersResponse.type !== "success") { setPageData(() => { throw ordersResponse.error; }); return; }
    if (invoicesResponse.type !== "success") { setPageData(() => { throw invoicesResponse.error; }); return; }
    if (zonesResponse.type !== "success") { setPageData(() => { throw zonesResponse.error; }); return; }

    setPageData({
      employeeInvoices: invoicesResponse.result.sort(sortRows).slice(0, 5),
      orders: ordersResponse.result,
      zones: zonesResponse.result,
    });
    setLoading(false);

  }, [id]);

  useEffect(() => {
    fetch();
  }, [fetch]);


  const renderSkeleton = () => (
    <Container width="m" className="components-housing-unit">
      <div className="skeleton-h1" />
      <div className="skeleton-employee-info">
        <div className="content" />
      </div>
      <div className="skeleton-employee-info">
        <div className="content" />
      </div>
      <div className="skeleton-employee-info">
        <div className="content" />
      </div>
      <div className="skeleton-h2">
        <div className="content" />
      </div>
      <div className="tickets">
        {pageData?.orders.matches.map((ticket) => (
          <SkeletonTicket key={ticket.id} />
        ))}
      </div>
    </Container>
  );

  if (loading || pageData === undefined) {
    return renderSkeleton();
  }

  const activeTickets = pageData.orders.matches.filter((ticket) => ticket.status === TICKET_ACTIVE || ticket.status === TICKET_PENDING);
  const inactiveTickets = pageData.orders.matches.filter((ticket) => ticket.status === TICKET_CANCELLED || ticket.status === TICKET_EXPIRED);

  return (
    <Container width="l" className="components-housing-unit" data-test-id="components-housing-unit">
      <h1>{language.title(pageData.orders.matches[0].employeeId)}</h1>
      <h2>{language.tickets}</h2>
      <div className="tickets" data-test-id="active-tickets-container">
        {activeTickets.map((ticket) => (
          <Ticket key={ticket.id} zones={pageData.zones} ticket={ticket} onUpdateRequest={() => { fetch(); }} />
        ))}
      </div>

      <h2>{language.expired}</h2>
      <div className="tickets" data-test-id="deactive-tickets-container">
        {inactiveTickets.map((ticket) => (
          <Ticket key={ticket.id} zones={pageData.zones} ticket={ticket} onUpdateRequest={() => { fetch(); }} />
        ))}
      </div>

      <h2>{language.payroll}</h2>
      <Table>
        <thead>
          <tr>
            <th>{language.invoiceMonth}</th>
            <th>{language.orderNumber}</th>
            <th>{language.zones}</th>
            <th style={{ textAlign: "right" }}>{language.amount}</th>
          </tr>
        </thead>
        <tbody>{pageData.employeeInvoices.map(renderPayrollRow)}</tbody>
      </Table>

    </Container>
  );
};

export default HousingUnit;