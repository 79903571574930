import { Button, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React from "react";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";

interface Props {
  text?: string;
  disabled?: boolean;
  onClick: () => void;
}

const CancelButton: React.FC<Props> = ({ text, disabled = false, onClick }) => {
  const lang = useLanguageResource(formFieldsLanguageResource);
  const buttonText = text || lang.cancel;

  return (
    <Button
      variant="cancel"
      onClick={async () => {
        onClick();
      }}
      text={buttonText}
      disabled={disabled}
      data-test-id="cancel-button"
      type="button"
    />
  );
};

export default CancelButton;
