import { Button, ButtonGroup, EmailFormInput, FormGroup, Modal, TextFormInput, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { Container, Radio, RadioList } from "@ruter-ds/rds-components";
import React, { ChangeEvent, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useAuthContextAuthenticated, useValidCompanyTicketService } from "src/AuthContext";
import apiClient from "src/common/apiClient/apiClient";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import successAlert from "src/common/toastr";
import { SubmitButton } from "src/components/common/buttons";
import PageHeaderComponent from "src/components/common/layout/PageHeader";
import PageSubheaderComponent from "src/components/common/layout/PageSubheader";
import { CREATE_POLICY_ALLOW_TICKET_CREATION, CREATE_POLICY_ALLOW_TICKET_REQUESTS, CREATE_POLICY_DISABLED } from "src/constants";
import { AgreementSettings, WidgetAccessOptions } from "src/types/AuthCompany";
import { mapTicketRequestSearchResult } from "src/types/companyAgreement/ticketRequest";
import { settingsLanguageResource } from "./lang-resource";
import "./Settings.scss";
import { SettingsFormValues } from "./types";

interface AccessOptions {
  value: WidgetAccessOptions;
  text: string;
}

const Settings: React.FC = () => {
  const authContext = useAuthContextAuthenticated();
  const companyTicketService = useValidCompanyTicketService();
  const selectedCompany = authContext.userData.selectedCompany;
  const language = useLanguageResource(settingsLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const [saveSettingsIsLoading, setSaveSettingsIsLoading] = useState(false);
  const [confirmSaveIsLoading, setConfirmSaveIsLoading] = useState(false);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [openTicketRequests, setOpenTicketRequests] = useState(0);
  const [contractData, setContractData] = useState<SettingsFormValues>();

  const accessOptions: AccessOptions[] = [
    { value: CREATE_POLICY_DISABLED, text: language.notAllowed },
    { value: CREATE_POLICY_ALLOW_TICKET_CREATION, text: language.allowedWithoutConfirmation },
    { value: CREATE_POLICY_ALLOW_TICKET_REQUESTS, text: language.allowedWithConfirmation },
  ];


  const { widgetAccess, emailForInvoicePreview, emailForTicketRequests, employeeIdLabel } = {
    ...companyTicketService.widgetSettings,
  };

  const editSettingsFormMethod = useForm<SettingsFormValues>({
    defaultValues: {
      allow: widgetAccess,
      emailPayroll: emailForInvoicePreview,
      email: emailForTicketRequests,
      ticketOwnerFieldName: employeeIdLabel,
    },
  });

  const allow = useWatch({ name: "allow", control: editSettingsFormMethod.control });

  const mapSettingsContract = (formData: SettingsFormValues): AgreementSettings => {
    const agreementNumber = companyTicketService.agreementNumber;
    const contract = {
      agreementNumber,
      emailForTicketRequests: formData.email,
      widgetAccess: formData.allow,
      emailForInvoicePreview: formData.emailPayroll,
      employeeIdLabel: formData.ticketOwnerFieldName,
    };
    return contract;
  };

  const saveSettings = async (formData: SettingsFormValues) => {
    try {
      const postContract = mapSettingsContract(formData);
      await apiClient.post("/api/company-agreement/settings", postContract);
      await authContext.userData.reload();
      setConfirmSaveIsLoading(false);
      setSaveSettingsIsLoading(false);
      successAlert(language.settingsSaved);
    } catch (e) {
      setSaveSettingsIsLoading(() => {
        throw e;
      });
    }
  };

  const conditionalSave = async (formData: SettingsFormValues) => {
    const currentAccess = companyTicketService.widgetSettings.widgetAccess;
    const newAccess = formData.allow;
    setSaveSettingsIsLoading(true);

    if (currentAccess === CREATE_POLICY_ALLOW_TICKET_REQUESTS && currentAccess !== newAccess) {
      try {
        const companyId = selectedCompany.id;
        const result = await apiClient.get(`/api/ticket-request/by-company-id/${companyId}`, mapTicketRequestSearchResult);
        if (result.totalEntries > 0) {
          setSaveSettingsIsLoading(false);
          setOpenTicketRequests(result.totalEntries);
          setContractData(formData);
          setConfirmModalIsOpen(true);
        } else {
          await saveSettings(formData);
        }
      } catch (e) {
        setSaveSettingsIsLoading(() => {
          throw e;
        });
      }
    } else {
      await saveSettings(formData);
    }
  };

  const confirmSave = async (formData: SettingsFormValues) => {
    try {
      setConfirmSaveIsLoading(true);
      await saveSettings(formData);
      setConfirmModalIsOpen(false);
      setContractData(undefined);
    } catch (e) {
      setConfirmSaveIsLoading(() => {
        throw e;
      });
    }
  };

  const changeRadio = (value: string) => {
    accessOptions.map((option) => {
      if (value === option.value) editSettingsFormMethod.setValue("allow", option.value);
    });
  };

  return (
    <Container width="s" className="settings-page">
      <FormProvider {...editSettingsFormMethod}>
        <form onSubmit={editSettingsFormMethod.handleSubmit(conditionalSave)}>
          <PageHeaderComponent text={language.headerTitle} loading={false} />
          <PageSubheaderComponent text={language.generalTitle} loading={false} />
          <TextFormInput label={language.ticketOwnerFieldName} name="ticketOwnerFieldName" required minLength={1} />
          <PageSubheaderComponent text={language.titleWidget} loading={false} />
          <FormGroup>
            <RadioList
              groupTitle={language.orderTicketsByEmployees}
              required
              radioName={"allow"}
              onChange={(e: ChangeEvent<HTMLInputElement>) => changeRadio(e.target.value)}
              selectedValue={allow}
            >
              {accessOptions.map((option) => (
                <Radio key={option.value} value={option.value} label={option.text} name="allow" />
              ))}
            </RadioList>
          </FormGroup>
          {allow === CREATE_POLICY_ALLOW_TICKET_REQUESTS && (
            <EmailFormInput name="email" label={language.emailForWidget} validationLabel={language.simpleEmail} />
          )}
          <PageSubheaderComponent text={language.titlePayroll} loading={false} />
          <EmailFormInput name="emailPayroll" label={language.emailForPayroll} validationLabel={language.simpleEmail} />
          <FormGroup>
            <SubmitButton submitting={saveSettingsIsLoading} text={formLang.save} />
          </FormGroup>
        </form>
      </FormProvider>

      <Modal
        isOpen={Boolean(confirmModalIsOpen && contractData)}
        handleClose={() => {
          setConfirmModalIsOpen(false);
        }}
        title={language.confirmTitle}
        className="confirm-modal"
        data-test-id="confirm-modal"
      >
        <p>
          {openTicketRequests === 1
            ? language.confirmTextSingle.replace("{count}", String(openTicketRequests))
            : language.confirmTextPlural.replace("{count}", String(openTicketRequests))}
        </p>
        <ButtonGroup className="button-group">
          <Button
            text={language.yes}
            data-test-id="confirm-button"
            loading={confirmSaveIsLoading}
            type="button"
            variant="primary"
            onClick={async () => {
              if (contractData) await confirmSave(contractData);
            }}
          />
          <Button
            type="button"
            text={language.cancel}
            variant="cancel"
            data-test-id="cancel-button"
            onClick={() => {
              setConfirmModalIsOpen(false);
            }}
          />
        </ButtonGroup>
      </Modal>
    </Container>
  );
};

export default Settings;
