import { parsePayrollChangeType, PayrollChangeType } from "src/types/companyAgreement/PayrollChangeType";
import { getValidHousingAssociationInvoiceLineJson, HousingAssociationInvoiceLine, HousingAssociationInvoiceLineJson, mapHousingAssociationInvoiceLine } from "./housingAssociationInvoiceLine";

interface HousingUnitCostChangeJson {
  changeType: string;
  priceDifference: number;
  oldOrder: HousingAssociationInvoiceLineJson;
  newOrder: HousingAssociationInvoiceLineJson;
}

export interface HousingUnitCostChange {
  changeType: PayrollChangeType;
  priceDifference: number;
  oldOrder: HousingAssociationInvoiceLine;
  newOrder: HousingAssociationInvoiceLine;
}

const mapHousingUnitCostChange = (json: HousingUnitCostChangeJson): HousingUnitCostChange => ({
  changeType: parsePayrollChangeType(json.changeType),
  priceDifference: json.priceDifference,
  oldOrder: mapHousingAssociationInvoiceLine(json.oldOrder),
  newOrder: mapHousingAssociationInvoiceLine(json.newOrder),
});

export const mapHousingUnitCostChangeArray = (json: HousingUnitCostChangeJson[]): HousingUnitCostChange[] => json.map(mapHousingUnitCostChange);

export const getValidHousingUnitCostChangeJson = (changes?: Partial<HousingUnitCostChangeJson>): HousingUnitCostChangeJson => ({
  changeType: PayrollChangeType.SUBSCRIBED.toString(),
  priceDifference: 0,
  oldOrder: getValidHousingAssociationInvoiceLineJson(),
  newOrder: getValidHousingAssociationInvoiceLineJson(),
  ...changes,
});
