import { LanguageResource } from "@ruter-as/web-components-and-tools";

export interface HeaderLanguageResource {
  companyAgreement: string;
  employees: string;
  companyInvoice: string;
  companyPayroll: string;
  companyInfo: string;
  billetter: string;
  billettluka: string;
  billettlukaBestill: string;
  billettlukaOrdrehistorikk: string;
  billettlukaBilletthistorikk: string;
  billettlukaInnstillinger: string;
  billettlukaInfo: string;
  billettlukaBedriftenBetaler: string;
  housingAssociationService: string;
  housingAssociationTickets: string;
  housingUnitCosts: string
  housingAssociationSettings: string
  invoice: string;
  toolsInfo: string;
  myPage: string;
  logOut: string;
  changeLanguage: string;
  logIn: string;
  menu: string;
  close: string;
  widget: string;
  freeTicketAgreement: string;
  freeTickets: string;
  freeTicketMassOrders: string;
  freeTicketInfo: string;
  freeTicketTaxBase: string;
  freeTicketTaxBaseChanges: string;
  freeTicketInvoiceBase: string;
  freeTicketTravelCard: string;
  companySettings: string;
  ticketRequests: string;
  useradmin: string;
  settings: string;
  chosenCompany: string;
  noCompanyResult: string;
  searchForCompany: string;
  companies: string;
  schoolTicketAgreement: string;
  schoolTickets: string;
  schoolTicketsOrder: string;
  register: string;
  travelCardAdmin: string
  housingTicketRequests: string
}

export const headerLanguageResource: LanguageResource<HeaderLanguageResource> = {
  nb: {
    companyAgreement: "Bedriftsavtale",
    employees: "Bedriftsbilletter",
    companyInvoice: "Fakturaer",
    companyPayroll: "Lønnstrekk",
    companyInfo: "Info",
    billetter: "Billetter",
    billettluka: "Billettluka",
    billettlukaBestill: "Bestill",
    billettlukaOrdrehistorikk: "Ordrehistorikk",
    billettlukaBilletthistorikk: "Billetthistorikk",
    billettlukaInnstillinger: "Innstillinger",
    billettlukaInfo: "Info",
    billettlukaBedriftenBetaler: "Bedriften betaler",
    invoice: "Faktura",
    toolsInfo: "Verktøy",
    myPage: "Min side",
    logOut: "Logg ut",
    changeLanguage: "In english",
    logIn: "Logg inn",
    menu: "Meny",
    close: "Lukk",
    widget: "Widget",
    freeTickets: "Fribilletter",
    freeTicketAgreement: "Fribillettavtale",
    freeTicketMassOrders: "Masseoppdatering",
    freeTicketTaxBase: "Skattegrunnlag",
    freeTicketTaxBaseChanges: "Endringer i skattegrunnlag",
    settings: "Innstillinger",
    freeTicketInvoiceBase: "Fakturagrunnlag",
    freeTicketTravelCard: "Reisekort",
    freeTicketInfo: "Info",
    companySettings: "Innstillinger",
    ticketRequests: "Godkjenning",
    useradmin: "Brukere",
    chosenCompany: "Valgt bedrift",
    noCompanyResult: "Ingen bedrifter passer med søket ditt",
    searchForCompany: "Søk etter bedrift",
    companies: "Bedrifter",
    schoolTicketAgreement: "Skoleårskort",
    schoolTickets: "Oversikt skoleårskort",
    schoolTicketsOrder: "Bestill skoleårskort",
    register: "Registrer deg",
    travelCardAdmin: "Reisekort",
    housingAssociationService: "Borettslag",
    housingAssociationTickets: "Borettslagbilletter",
    housingUnitCosts: "Kostnader pr. boenhet",
    housingAssociationSettings: "Innstillinger",
    housingTicketRequests: "Godkjenning",
  },
  en: {
    companyAgreement: "Company agreement",
    employees: "Business tickets",
    companyInvoice: "Invoices",
    companyPayroll: "Payroll",
    companyInfo: "Info",
    billetter: "Tickets",
    billettluka: "Ticket counter",
    billettlukaBestill: "Order",
    billettlukaOrdrehistorikk: "Order history",
    billettlukaBilletthistorikk: "Ticket history",
    billettlukaInnstillinger: "Settings",
    billettlukaInfo: "Info",
    billettlukaBedriftenBetaler: "Company whitelist",
    invoice: "Invoice",
    toolsInfo: "Tools",
    myPage: "My page",
    logOut: "Log out",
    changeLanguage: "På norsk",
    logIn: "Log in",
    menu: "Menu",
    close: "Close",
    widget: "Widget",
    freeTickets: "Free-tickets",
    freeTicketAgreement: "Free-ticket agreement",
    freeTicketMassOrders: "Mass change",
    freeTicketTaxBase: "Tax base",
    freeTicketTaxBaseChanges: "Changes in tax base",
    freeTicketInvoiceBase: "Invoice base",
    freeTicketTravelCard: "Travel card",
    freeTicketInfo: "Info",
    companySettings: "Settings",
    ticketRequests: "Approval",
    useradmin: "Users",
    settings: "Settings",
    chosenCompany: "Chosen company",
    noCompanyResult: "No companies matches your search",
    searchForCompany: "Search for company",
    companies: "Companies",
    schoolTicketAgreement: "School",
    schoolTickets: "School-tickets",
    schoolTicketsOrder: "Order",
    register: "Register",
    travelCardAdmin: "Travelcard",
    housingAssociationService: "Housing association",
    housingAssociationTickets: "Tickets",
    housingUnitCosts: "Housing units costs",
    housingAssociationSettings: "Settings",
    housingTicketRequests: "Godkjenning",
  },
};
