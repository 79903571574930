import { apiClient, Button, ButtonGroup, Message, Modal, useLanguageResource } from "@ruter-as/web-components-and-tools";
import * as Sentry from "@sentry/react";
import React, { useState } from "react";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import { Agreement, Company } from "src/types/Company";
import companyPageLanguageResource from "./lang-resource";


interface Props {
  agreement: Agreement;
  company: Company;
  onClose: () => void;
  onSuccess: () => void;
}

const DeleteAgreementModal: React.FC<Props> = ({ agreement, company, onClose, onSuccess }) => {
  const lang = useLanguageResource(companyPageLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const [loading, setLoading] = useState(false);
  const [submitFailure, setSubmitFailure] = useState(false);

  const title = lang.deleteAgreement.replace("$agreement$", agreement.id);
  const confirmMessage = lang.deleteAgreementConfirm
    .replace("$agreement$", agreement.id)
    .replace("$invoiceReference$", agreement.invoiceReference);

  const deleteAgreement = async () => {
    setSubmitFailure(false);
    setLoading(true);

    const result = await apiClient.delete(`/onboarding/company/${company.id}/agreement/${agreement.id}`);
    if (result.type === "success") {
      onSuccess();
    } else {
      Sentry.captureException(result.error);
      setSubmitFailure(true);
    }
    setLoading(false);

  };

  return (
    <Modal isOpen={true} title={title} data-test-id="delete-agreement-modal" handleClose={onClose}>
      <p data-test-id="confirm-message">{confirmMessage}</p>
      {submitFailure && (
        <Message skin="danger" data-test-id="submit-failure" style={{ marginTop: "2rem" }}>
          <p>{lang.deleteFailure}</p>
        </Message>
      )}
      <ButtonGroup>
        <Button
          text={formLang.yes}
          variant="danger"
          type="button"
          onClick={deleteAgreement}
          loading={loading}
          data-test-id="confirm-button"
        />
        <Button text={formLang.cancel} variant="cancel" type="button" onClick={onClose} data-test-id="cancel-button" />
      </ButtonGroup>
    </Modal>
  );
};

export default DeleteAgreementModal;
