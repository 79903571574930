import { UNKNOWN } from "../../constants";

export enum UserRoleType {
  GlobalUserAdmin = "GLOBAL_USER_ADMIN",
  GlobalCompanyAdmin = "GLOBAL_COMPANY_ADMIN",
  GlobalTravelCardAdmin = "GLOBAL_TRAVEL_CARD_ADMIN",
  GlobalSchoolTicketAdmin = "GLOBAL_SCHOOLTICKET_ADMIN",
}

export const parseUserRoleType = (value: string): UserRoleType | typeof UNKNOWN => {
  const parsed = Object.values(UserRoleType).find((x) => x === value);

  if (parsed === undefined) {
    return UNKNOWN;
  }
  return parsed;
};
