import {
  apiClient,
  ButtonGroup,
  DateFormInput,
  DropdownFormInput,
  MediaFormInput,
  Message,
  Modal,
  PhoneFormInput,
  TextFormInput,
  useLanguageResource,
} from "@ruter-as/web-components-and-tools";
import { parsePhoneNumber } from "libphonenumber-js/max";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useAuthContextAuthenticated, useValidFreeTicketAgreementService } from "src/AuthContext";
import { EditTicketFormData, FreeTicketEditPostContract, FreeTicketEditPreviewPostContract } from "src/common/api/freeTicketApi/editFreeticket";
import freeTicketApi from "src/common/api/freeTicketApi/freeTicketApi";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import { ALL_ZONES_FROM } from "src/constants";
import { Zone } from "../../../common/api/commonTypes/Zone";
import { FreeTicket } from "../../../common/api/freeTicketApi/freeTicket";
import { getExpiryDates } from "../../../common/expiryDate";
import { FreeTicketProfile } from "../../../types/freeTicketAgreement/freeTicketProfile";
import { NewTicketBecauseOf } from "../../../types/freeTicketAgreement/newTicketBecauseOf";
import { TicketHolderType } from "../../../types/freeTicketAgreement/ticketHolderType";
import MediaType from "../../../types/mediaType";
import { CancelButton, SubmitButton } from "../../common/buttons";
import { freeTicketLanguageResource } from "../freeTicket/lang-resource";
import TicketProfileInput from "../freeTicket/TicketProfileInput";
import { freeTicketsFamilyLanguageResource } from "./lang-resource";
interface Props {
  hide: () => void;
  submitted: () => void;
  ticket: FreeTicket;
}

interface ConfirmFormData {
  timeOfChange: string;
}

const EditFreeTicketComponent: React.FC<Props> = ({ ticket, submitted, hide }) => {
  const authContext = useAuthContextAuthenticated();
  const freeTicketService = useValidFreeTicketAgreementService();
  const [editTicketSubmitting, setEditTicketSubmitting] = useState(false);
  const lang = useLanguageResource(freeTicketsFamilyLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const freeTicketLang = useLanguageResource(freeTicketLanguageResource);
  const [zones, setZones] = useState<null | Zone[]>(null);
  const [showConfirmation, setShowConfirmation] = useState<{ formData: EditTicketFormData; reasons: NewTicketBecauseOf[] } | null>(null);

  const dates = getExpiryDates(32, formLang.today, 7);

  useEffect(() => {
    const fetch = async () => {
      const response = await apiClient.request(freeTicketApi.distance.zones.get());
      if (response.type === "success") {
        setZones(response.result);
      } else {
        setZones(() => {
          throw response.error;
        });
      }
    };

    fetch();
  }, []);

  const ticketHolderTypes: { value: TicketHolderType; text: string }[] = [];

  if (!ticket.employeeBirthDate) {
    throw new Error("ticket.employeeBirthDate cannot be null");
  }

  if (freeTicketService.validateProfile(ticket.employeeBirthDate, TicketHolderType.EMPLOYEE, ticket.profile)) {
    ticketHolderTypes.push({ value: TicketHolderType.EMPLOYEE, text: lang.employee });
  }
  if (freeTicketService.validateProfile(ticket.employeeBirthDate, TicketHolderType.EMPLOYEE, ticket.profile)) {
    ticketHolderTypes.push({ value: TicketHolderType.FAMILY_MEMBER, text: lang.ticketHoldertypes });
  }
  if (freeTicketService.validateProfile(ticket.employeeBirthDate, TicketHolderType.EMPLOYEE, ticket.profile)) {
    ticketHolderTypes.push({ value: TicketHolderType.RETIRED, text: lang.reducedRate });
  }

  const findInvoiceRef = useCallback(
    (agreementNumberInput: string) => {
      const agreementFromInput = freeTicketService.invoiceRefs.find((x) => x.agreementNumber === agreementNumberInput);
      return agreementFromInput?.invoiceReference || `${lang.missingDepartmentName} (${agreementNumberInput})`;
    },
    [lang.missingDepartmentName, freeTicketService],
  );

  const agreementsOptions = freeTicketService.invoiceRefs
    .filter((x) => x.endDate === null || x.endDate > new Date())
    .map((x) => ({ value: x.agreementNumber, text: findInvoiceRef(x.agreementNumber) }));

  agreementsOptions.sort((a, b) => {
    if (a.text < b.text) {
      return -1;
    }
    if (a.text > b.text) {
      return 1;
    }
    return 0;
  });

  const editTicketFormMethods = useForm<EditTicketFormData>({
    defaultValues: {
      agreement: ticket.agreementNumber,
      firstName: ticket.firstName,
      lastName: ticket.lastName,
      employeeId: ticket.employeeId,
      ticketHolderType: ticket.ticketHolderType,
      dateOfBirth: moment(ticket.employeeBirthDate).format("DD.MM.YYYY"),
      profile: ticket.profile,
      zoneFrom: ticket.zoneFrom,
      zoneTo: ticket.zoneTo,
      mediaType: ticket.mediaType,
      phone: ticket.mediaType === MediaType.MOBILE_TICKET ? `${ticket.phoneCountryCode} ${ticket.phone}` : undefined,
    },
  });

  const confirmFormMethods = useForm<ConfirmFormData>({
    defaultValues: {
      timeOfChange: dates[0].value,
    },
  });

  const newMediaType = useWatch({ name: "mediaType", control: editTicketFormMethods.control });

  const mapPreviewContract = (formData: EditTicketFormData): FreeTicketEditPreviewPostContract => {
    let phone: string | undefined;
    let phoneCountryCode: string | undefined;
    let validAllZones = false;

    if (formData.phone?.trim()) {
      const parsedPhone = parsePhoneNumber(formData.phone);
      if (parsedPhone.isValid()) {
        phone = parsedPhone.nationalNumber.toString();
        phoneCountryCode = `+${parsedPhone.countryCallingCode}`;
      }
    }

    let zoneFrom = formData.zoneFrom;
    let zoneTo = formData.zoneTo;

    if (formData.zoneFrom === null || formData.zoneFrom === ALL_ZONES_FROM) {
      zoneFrom = null;
      zoneTo = null;
      validAllZones = true;
    }

    return {
      agreementNumber: formData.agreement,
      profile: formData.profile,
      ticketHolderType: formData.ticketHolderType,
      employeeId: formData.employeeId,
      lastName: formData.lastName,
      firstName: formData.firstName,
      dateOfBirth: moment(formData.dateOfBirth, "DD.MM.YYYY").toDate().toISOString(),
      mediaType: formData.mediaType,
      phoneCountryCode,
      phone,
      validAllZones,
      zoneFrom,
      zoneTo,
    };
  };

  const mapConfirmContract = (formData: EditTicketFormData, timeOfChange: Date): FreeTicketEditPostContract => ({
    ...mapPreviewContract(formData),
    timeOfChange: timeOfChange.toISOString(),
  });

  const saveTicket = async (formData: EditTicketFormData) => {
    const postContract = mapPreviewContract(formData);
    setEditTicketSubmitting(true);
    const response = await apiClient.request(freeTicketApi.ticket.id(ticket.id).updateMultipleFieldsPreview.post(postContract));

    if (response.type === "success") {
      if (!response.result.newTicketRequired) {
        const newTicketResponse = await apiClient.request(freeTicketApi.ticket.id(ticket.id).updateMultipleFields.post(postContract));

        if (newTicketResponse.type === "success") {
          await submitted();
        } else {
          setEditTicketSubmitting(() => {
            throw newTicketResponse.error;
          });
        }
      } else {
        setShowConfirmation({ formData, reasons: response.result.newTicketBecauseOf });
        setEditTicketSubmitting(false);
      }
    } else {
      setEditTicketSubmitting(() => {
        throw response.error;
      });
    }
  };

  const confirmTicket = async (data: ConfirmFormData) => {
    if (!showConfirmation) {
      throw new Error("showConfirmation cannot be null");
    }

    setEditTicketSubmitting(true);
    const postContract = mapConfirmContract(showConfirmation.formData, moment(data.timeOfChange, "DD.MM.YYYY").toDate());
    const response = await apiClient.request(freeTicketApi.ticket.id(ticket.id).updateMultipleFields.post(postContract));

    if (response.type === "success") {
      await submitted();
    } else {
      setEditTicketSubmitting(() => {
        throw response.error;
      });
    }
  };

  if (!zones) {
    return null;
  }

  const showMobile = ticket.mediaType === MediaType.TRAVEL_CARD && newMediaType === MediaType.MOBILE_TICKET;

  const renderEditForm = () => (
    <FormProvider {...editTicketFormMethods}>
      <form onSubmit={editTicketFormMethods.handleSubmit(saveTicket)}>
        {ticket.mediaType === MediaType.TRAVEL_CARD && (
          <MediaFormInput
            name="mediaType"
            label={freeTicketLang.chooseMediaType}
            option1={{ value: MediaType.MOBILE_TICKET, disabled: false }}
            option2={{ value: MediaType.TRAVEL_CARD, disabled: false }}
          />
        )}
        {showMobile && <PhoneFormInput name="phone" label={formLang.mobile} required mobileOnly />}
        <DropdownFormInput name="agreement" label={lang.invoiceRef}>
          {agreementsOptions.map((a) => (
            <option key={a.value} value={a.value}>
              {a.text}
            </option>
          ))}
        </DropdownFormInput>
        <TextFormInput name="firstName" required maxLength={50} label={formLang.firstName} />
        <TextFormInput name="lastName" required maxLength={50} label={formLang.lastName} />
        <TextFormInput name="employeeId" required maxLength={20} label={formLang.employeeNumber} />
        <DateFormInput name="dateOfBirth" label={formLang.birthDate} required maxDate={new Date()} />
        <DropdownFormInput name="ticketHolderType" required label={formLang.relation}>
          {ticketHolderTypes.map((o) => (
            <option key={o.value} value={o.value}>
              {o.text}
            </option>
          ))}
        </DropdownFormInput>
        <TicketProfileInput />
        {authContext.features.getZoneInput(zones)}

        <ButtonGroup>
          <SubmitButton submitting={editTicketSubmitting} text={formLang.save} />
          <CancelButton onClick={() => hide()} data-test-id="cancel-button" />
        </ButtonGroup>
      </form>
    </FormProvider>
  );

  const humanReadableProfile = (profile: FreeTicketProfile): string => {
    switch (profile) {
      case FreeTicketProfile.ADULT:
        return freeTicketLang.adult;
      case FreeTicketProfile.CHILD:
        return freeTicketLang.child;
      case FreeTicketProfile.RETIRED:
        return freeTicketLang.reducedRate;
    }
  };

  const renderReason = (reason: NewTicketBecauseOf) => {
    if (!showConfirmation?.reasons.some((x) => x === reason)) {
      return null;
    }

    let dataTestId = "";
    let name = "";
    let oldValue = "";
    let newValue = "";

    switch (reason) {
      case NewTicketBecauseOf.MEDIA_TYPE: {
        dataTestId = "mediaType";
        name = lang.mediaType;
        oldValue = ticket.mediaType === MediaType.TRAVEL_CARD ? freeTicketLang.travelcard : freeTicketLang.mobileTicket;
        newValue = showConfirmation.formData.mediaType === MediaType.TRAVEL_CARD ? freeTicketLang.travelcard : freeTicketLang.mobileTicket;
        break;
      }
      case NewTicketBecauseOf.PHONE_NUMBER: {
        dataTestId = "phone";
        name = formLang.phone;
        oldValue = "";
        newValue = `${showConfirmation.formData.phone}`;
        break;
      }
      case NewTicketBecauseOf.AGREEMENT: {
        dataTestId = "agreement";
        name = lang.invoiceRef;
        oldValue = findInvoiceRef(ticket.agreementNumber);
        newValue = findInvoiceRef(showConfirmation.formData.agreement);
        break;
      }
      case NewTicketBecauseOf.NAME: {
        dataTestId = "name";
        name = lang.name;
        oldValue = `${ticket.firstName} ${ticket.lastName}`;
        newValue = `${showConfirmation.formData.firstName} ${showConfirmation.formData.lastName}`;
        break;
      }
      case NewTicketBecauseOf.EMPLOYEE_ID: {
        dataTestId = "employeeId";
        name = formLang.employeeNumber;
        oldValue = ticket.employeeId;
        newValue = showConfirmation.formData.employeeId;
        break;
      }
      case NewTicketBecauseOf.EMPLOYEE_ID: {
        dataTestId = "employeeId";
        name = formLang.employeeNumber;
        oldValue = ticket.employeeId;
        newValue = showConfirmation.formData.employeeId;
        break;
      }
      case NewTicketBecauseOf.DATE_OF_BIRTH: {
        dataTestId = "dateOfBirth";
        name = formLang.birthDate;
        oldValue = moment(ticket.employeeBirthDate).format("DD.MM.YYYY");
        newValue = showConfirmation.formData.dateOfBirth;
        break;
      }
      case NewTicketBecauseOf.TICKET_HOLDER_TYPE: {
        dataTestId = "ticketHolderType";
        name = lang.ticketHolderType;
        oldValue = ticketHolderTypes.find((x) => x.value === ticket.ticketHolderType)?.text || "";
        newValue = ticketHolderTypes.find((x) => x.value === showConfirmation.formData.ticketHolderType)?.text || "";
        break;
      }
      case NewTicketBecauseOf.PROFILE_ID: {
        dataTestId = "profile";
        name = lang.profile;
        oldValue = humanReadableProfile(ticket.profile);
        newValue = humanReadableProfile(showConfirmation.formData.profile);
        break;
      }
      case NewTicketBecauseOf.ZONE: {
        dataTestId = "zone";
        name = lang.zones;
        oldValue = ticket.allZones ? lang.allZones : `${ticket.zoneFrom} - ${ticket.zoneTo}`;
        newValue = (showConfirmation.formData.zoneFrom === "Ruter" || showConfirmation.formData.zoneFrom === null) ? lang.allZones : `${showConfirmation.formData.zoneFrom} - ${showConfirmation.formData.zoneTo}`;
        break;
      }
      default:
        return null;
    }

    return (
      <li data-test-id={dataTestId}>
        <span>{name}: </span>
        <span className="old">{oldValue}</span>
        <span>{" -> "}</span>
        <span className="new">{newValue}</span>
      </li>
    );
  };

  const renderConfirmationForm = () => {
    if (!showConfirmation) {
      return null;
    }

    return (
      <FormProvider {...confirmFormMethods}>
        <form onSubmit={confirmFormMethods.handleSubmit(confirmTicket)} data-test-id="confirm-form">
          <Message skin="warning" style={{ marginBottom: "2rem" }}>
            {lang.changesRequireNewTicket}
            <ul>
              {renderReason(NewTicketBecauseOf.MEDIA_TYPE)}
              {renderReason(NewTicketBecauseOf.PHONE_NUMBER)}
              {renderReason(NewTicketBecauseOf.AGREEMENT)}
              {renderReason(NewTicketBecauseOf.NAME)}
              {renderReason(NewTicketBecauseOf.EMPLOYEE_ID)}
              {renderReason(NewTicketBecauseOf.DATE_OF_BIRTH)}
              {renderReason(NewTicketBecauseOf.TICKET_HOLDER_TYPE)}
              {renderReason(NewTicketBecauseOf.PROFILE_ID)}
              {renderReason(NewTicketBecauseOf.ZONE)}
            </ul>
          </Message>
          <DropdownFormInput name="timeOfChange" label={lang.from}>
            {dates.map((d) => (
              <option value={d.value} key={d.value}>
                {d.text}
              </option>
            ))}
          </DropdownFormInput>
          <ButtonGroup>
            <SubmitButton submitting={editTicketSubmitting} text={formLang.save} />
            <CancelButton onClick={() => hide()} data-test-id="cancel-button" />
          </ButtonGroup>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={true} title={lang.editTicket} handleClose={() => hide()} data-test-id="edit-ticket-window">
      {!Boolean(showConfirmation) && renderEditForm()}
      {Boolean(showConfirmation) && renderConfirmationForm()}
    </Modal>
  );
};

export default EditFreeTicketComponent;
