import { UNKNOWN } from "../../constants";
import { CompanyRole, CompanyRoleJson, getValidCompanyRoleJson, mapCompanyRole } from "./CompanyRole";
import { parseUserRoleType, UserRoleType } from "./UserRoleType";

interface UserRolesJson {
  userId: string;
  lastModifiedAt: string;
  roles: string[];
  companies: CompanyRoleJson[];
}

interface UserRoles {
  userId: string;
  lastModified: Date;
  roles: (UserRoleType | typeof UNKNOWN)[];
  companies: CompanyRole[];
}

export const mapUserRoles = (json: UserRolesJson): UserRoles => ({
  userId: json.userId,
  lastModified: new Date(json.lastModifiedAt),
  roles: json.roles.map(parseUserRoleType),
  companies: json.companies.map(mapCompanyRole),
});

export const GetValidUserRolesJson = (changes?: Partial<UserRolesJson>): UserRolesJson => ({
  userId: "ef1d2338-3560-47d3-a7ca-63b0e6e0b801",
  lastModifiedAt: "2020-12-09T07:28:54Z",
  roles: [UserRoleType.GlobalCompanyAdmin.toString()],
  companies: [getValidCompanyRoleJson()],
  ...changes,
});
