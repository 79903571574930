import { ticketCounterApi } from "@ruter-as/billettluke-frontend";
import { apiClient, Button, ButtonGroup, Message, Modal, ProgressDetails, Task, useLanguageResource } from "@ruter-as/web-components-and-tools";
import * as Sentry from "@sentry/react";
import React, { useCallback, useEffect, useState } from "react";
import { useValidInvoiceAccess } from "src/AuthContext";
import companyAgreementApi from "src/common/api/companyAgreementApi/companyAgreementApi";
import freeTicketApi from "src/common/api/freeTicketApi/freeTicketApi";
import invoiceApi from "src/common/api/invoice/invoiceApi";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import { AgreementSubTypeId } from "src/types/AgreementSubTypeId";
import { Company } from "src/types/Company";
import companyPageLanguageResource from "./lang-resource";

interface Props {
  company: Company;
  onClose: () => void;
  onSuccess: () => void;
}

const DeleteCompanyModal: React.FC<Props> = ({ company, onClose, onSuccess }) => {
  const invoiceAccess = useValidInvoiceAccess();
  const lang = useLanguageResource(companyPageLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const [loading, setLoading] = useState(false);
  const [submitFailure, setSubmitFailure] = useState(false);
  const [companyTicketTask, setCompanyTicketTask] = useState<Task>();
  const [freeTicketsTask, setFreeTicketTask] = useState<Task>();
  const [ticketOfficeTask, setTicketOfficeTask] = useState<Task>();
  const [invoiceTask, setInvoiceTask] = useState<Task>();
  const [deletable, setDeletable] = useState(false);

  const { allAgreements } = company;

  const groupedAgreements = {
    freeTicketAgreements: allAgreements.filter(x => x.subTypeId === AgreementSubTypeId.FREETICKET),
    companyTicketAgreements: allAgreements.filter(x => x.subTypeId === AgreementSubTypeId.SUBSCRIPTIONS),
    ticketOfficeAgreements: allAgreements.filter(x => x.subTypeId === AgreementSubTypeId.TICKETOFFICE),
  };

  const hasCompanyAgreement = groupedAgreements.companyTicketAgreements.length > 0;
  const hasFreeticketAgreement = groupedAgreements.freeTicketAgreements.length > 0;
  const hasTicketOfficeAgreement = groupedAgreements.ticketOfficeAgreements.length > 0;

  const handleCompanyTicketTask = useCallback(async () => {
    const response = await apiClient.request(companyAgreementApi.ticket.getEmployeeTicketsByCompanyId(company.id, "", 1, 1));
    if (response.type === "success" && response.result.totalEntries === 0) {
      setCompanyTicketTask({ type: "Indeterminate", description: lang.progressDetails.fetchingCompanyTickets, finished: true });
    } else {
      setCompanyTicketTask({ type: "Failed", description: lang.progressDetails.fetchingCompanyTickets });
    }
  }, [company.id, lang.progressDetails.fetchingCompanyTickets]);

  const handleFreeTicketsTask = useCallback(async () => {
    const response = await apiClient.request(freeTicketApi.ticket.byCompanyId.get(company.id, "", 1, 1));
    if (response.type === "success" && response.result.totalEntries === 0) {
      setFreeTicketTask({ type: "Indeterminate", description: lang.progressDetails.fetchingFreetickets, finished: true });
    } else {
      setFreeTicketTask({ type: "Failed", description: lang.progressDetails.fetchingFreetickets });
    }
  }, [lang.progressDetails.fetchingFreetickets, company.id]);

  const handleTicketOfficeTask = useCallback(async () => {
    const response = await apiClient.request(ticketCounterApi.ticket.getByCompanyId(company.id, "", 1, 1));
    if (response.type === "success") {
      if (response.result.totalEntries === 0) {
        setTicketOfficeTask({ type: "Indeterminate", description: lang.progressDetails.fetchingTicketOfficeTickets, finished: true });
      } else {
        setTicketOfficeTask({ type: "Failed", description: lang.progressDetails.fetchingTicketOfficeTickets });
      }
    } else {
      setTicketOfficeTask({ type: "Failed", description: lang.progressDetails.fetchingTicketOfficeTickets });
    }
  }, [lang.progressDetails.fetchingTicketOfficeTickets, company.id]);

  const handleInvoiceTask = useCallback(async () => {
    const response = invoiceAccess.hasInvoiceBaseOnly ? await apiClient.request(invoiceApi.invoiceAdminApi.invoiceBase.byCompanyId.get(company.id))
      : await apiClient.request(invoiceApi.api.invoice.getByCompanyId.ruter(company.id));

    if (response.type === "success" && response.result.length === 0) {
      setInvoiceTask({ type: "Indeterminate", description: lang.progressDetails.fetchingInvoices, finished: true });
    } else {
      setInvoiceTask({ type: "Failed", description: lang.progressDetails.fetchingInvoices });
    }
  }, [company.id, lang.progressDetails.fetchingInvoices, invoiceAccess.hasInvoiceBaseOnly]);


  useEffect(() => {
    const handleAllTasks = async () => {
      const requests = [handleInvoiceTask()];

      if (hasCompanyAgreement) {
        requests.push(handleCompanyTicketTask());
      }

      if (hasFreeticketAgreement) {
        requests.push(handleFreeTicketsTask());
      }

      if (hasTicketOfficeAgreement) {
        requests.push(handleTicketOfficeTask());
      }
      await Promise.all(requests);

      setDeletable(true);
    };

    setInvoiceTask({ description: lang.progressDetails.fetchingInvoices, type: "Indeterminate", finished: false });

    if (hasCompanyAgreement) {
      setCompanyTicketTask({ description: lang.progressDetails.fetchingCompanyTickets, finished: false, type: "Indeterminate" });
    }
    if (hasFreeticketAgreement) {
      setFreeTicketTask({ description: lang.progressDetails.fetchingFreetickets, finished: false, type: "Indeterminate" });
    }
    if (hasTicketOfficeAgreement) {
      setTicketOfficeTask({ description: lang.progressDetails.fetchingTicketOfficeTickets, finished: false, type: "Indeterminate" });
    }

    handleAllTasks();

  }, [
    allAgreements,
    lang.progressDetails,
    hasCompanyAgreement,
    hasTicketOfficeAgreement,
    hasFreeticketAgreement,
    handleInvoiceTask,
    handleCompanyTicketTask,
    handleFreeTicketsTask,
    handleTicketOfficeTask,
  ]);

  const getConfirmButtonIsDisabled = (): boolean => {
    if (!deletable) {
      return true;
    }

    if (companyTicketTask?.type === "Failed" ||
      freeTicketsTask?.type === "Failed" ||
      ticketOfficeTask?.type === "Failed" ||
      invoiceTask?.type === "Failed"
    ) {
      return true;
    }

    return false;
  };

  const deleteAgreement = async () => {
    setSubmitFailure(false);
    setLoading(true);
    const response = await apiClient.delete(`/onboarding/company/${company.id}`);

    if (response.type === "success") {
      setLoading(false);
      onSuccess();
    } else {
      Sentry.captureException(response.error);
      setLoading(false);
      setSubmitFailure(true);
    }
  };

  const tasks: Task[] = [
    ...invoiceTask ? [invoiceTask] : [],
    ...companyTicketTask ? [companyTicketTask] : [],
    ...freeTicketsTask ? [freeTicketsTask] : [],
    ...ticketOfficeTask ? [ticketOfficeTask] : [],
  ];

  return (
    <Modal isOpen={true} title={lang.deleteCompanyTitle(company.name)} data-test-id="delete-company-modal" handleClose={onClose}>
      <p data-test-id="confirm-message">{lang.deleteCompanyConfirmationMessage(company.name, company.organisationNumber)}</p>
      {tasks && <ProgressDetails tasks={tasks} />}
      {submitFailure && (
        <Message skin="danger" data-test-id="submit-failure" style={{ marginTop: "2rem" }}>
          <p>{lang.deleteFailure}</p>
        </Message>
      )}
      <ButtonGroup>
        <Button
          text={formLang.yes}
          variant="danger"
          type="button"
          onClick={deleteAgreement}
          loading={loading}
          data-test-id="confirm-button"
          disabled={getConfirmButtonIsDisabled()}
        />
        <Button text={formLang.cancel} variant="cancel" type="button" onClick={onClose} data-test-id="cancel-button" />
      </ButtonGroup>
    </Modal>
  );
};

export default DeleteCompanyModal;
