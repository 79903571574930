import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface HousingAssociationSettingsLanguageResource {
  headerTitle: string;
  titleWidget: string;
  titlePayroll: string;
  orderTicketsByEmployees: string;
  notAllowed: string;
  allowedWithConfirmation: string;
  allowedWithoutConfirmation: string;
  emailForWidget: string;
  settingsSaved: string;
  simpleEmail: string;
  emailForPayroll: string;
  confirmTitle: string;
  confirmTextSingle: string;
  confirmTextPlural: string;
  yes: string;
  cancel: string;
  generalTitle: string;
  ticketOwnerFieldName: string;
}

export const housingAssociationSettingsLanguageResource: LanguageResource<HousingAssociationSettingsLanguageResource> = {
  nb: {
    headerTitle: "Innstillinger for borettslagbilletter",
    titleWidget: "Innstillinger for widget",
    titlePayroll: "Innstillinger for lønnstrekk",
    orderTicketsByEmployees: "Ansatte kan søke om borettslagbilletter til seg selv via link/intranett",
    notAllowed: "Tillates ikke",
    allowedWithConfirmation: "Tillates, behandles av administrator",
    allowedWithoutConfirmation: "Tillates, godkjennes automatisk",
    emailForWidget: "Varsling til e-post når en beboer søker om billett (valgfritt)",
    settingsSaved: "Innstillinger lagret!",
    simpleEmail: "E-post",
    emailForPayroll: "Varsling til e-post når lønnstrekk er klart (valgfritt)",
    confirmTitle: "Bekreft",
    confirmTextSingle: "Du har {count} ubehandlet søknad, er du sikker på at du vil slå av søknadsbehandling?",
    confirmTextPlural: "Du har {count} ubehandle søknader, er du sikker på at du vil slå av søknadsbehandling?",
    yes: "Ja",
    cancel: "Avbryt",
    generalTitle: "Generelle instillinger",
    ticketOwnerFieldName: "Identifikator for eier av billett",
  },
  en: {
    headerTitle: "Settings for housing association tickets",
    titleWidget: "Settings for widget",
    titlePayroll: "Settings for payroll",
    orderTicketsByEmployees: "Home owners can apply for tickets to themselves via link/intranet",
    notAllowed: "Not allowed",
    allowedWithConfirmation: "Allowed, processed by admin",
    allowedWithoutConfirmation: "Allowed, approved automatically",
    emailForWidget: "Notification by email when a resident applies for ticket (optional)",
    settingsSaved: "Settings saved!",
    simpleEmail: "E-post",
    emailForPayroll: "Notification by email when payroll is ready for review (optional)",
    confirmTitle: "Confirm",
    confirmTextSingle: "You have {count} open ticket request, are you sure you want to turn of processing of ticket requests?",
    confirmTextPlural: "You have {count} open ticket requests, are you sure you want to turn of processing of ticket requests?",
    yes: "Yes",
    cancel: "Cancel",
    generalTitle: "General settings",
    ticketOwnerFieldName: "Field name for owner of a ticket",
  },
};
