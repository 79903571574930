import { Icon, useLanguageResource } from "@ruter-as/web-components-and-tools";
import parsePhoneNumberFromString from "libphonenumber-js/max";
import moment from "moment";
import React, { useState } from "react";
import TicketZonesField from "src/components/common/text/ticketZones/TicketZonesField";
import { FreeTicket, FreeTicketPhone } from "../../../common/api/freeTicketApi/freeTicket";
import formatter from "../../../common/formatter";
import { TICKET_ACTIVE, TICKET_PENDING } from "../../../constants";
import { FreeTicketProfile } from "../../../types/freeTicketAgreement/freeTicketProfile";
import { TicketHolderType } from "../../../types/freeTicketAgreement/ticketHolderType";
import { TicketStatus } from "../../../types/freeTicketAgreement/ticketStatus";
import MediaType from "../../../types/mediaType";
import TicketStatus2 from "../../common/text/ticketStatus/TicketStatus";
import EditFreeTicketComponent from "./EditFreeTicket";
import { FreeTicketsFamilyLanguageResource, freeTicketsFamilyLanguageResource } from "./lang-resource";
import "./MobileTicket.scss";

interface Props {
  ticket: FreeTicketPhone;
  cancelActiveTicket: React.Dispatch<React.SetStateAction<FreeTicket | null>>;
  cancelPendingTicket: React.Dispatch<React.SetStateAction<FreeTicket | null>>;
  resendTicket: React.Dispatch<React.SetStateAction<FreeTicket | null>>;
  sendPickupCode: React.Dispatch<React.SetStateAction<FreeTicket | null>>;
  refetch: () => Promise<void>;
}

export const formatStringOrNull = (value: string | null): string => {
  if (!value) {
    return "–";
  }
  return value;
};

export const formatDate = (expirationDate: Date | null): string => {
  const date = moment(expirationDate);

  if (expirationDate === null || !date.isValid()) {
    return "–";
  }
  return moment(expirationDate).format("L");
};

const formatPickedUp = (pickedUp: boolean, language: FreeTicketsFamilyLanguageResource) => {
  if (pickedUp) {
    return language.yes;
  }
  return language.no;
};

export const formatCurrencyAmount = (ticket: FreeTicket, amount: number | null | undefined): string => {
  if ((ticket.mediaType === MediaType.MOBILE_TICKET && ticket.status !== TICKET_ACTIVE) || amount === undefined || amount === null) {
    return "–";
  }

  return formatter.number.currency(amount, true);
};

const formatPhoneNumber = (phoneCountryCode?: string, phone?: string) => {
  if (!phone || !phone?.trim()) {
    return "–";
  }

  const countryCode = phoneCountryCode || "+47";

  let text = `${countryCode || ""} ${phone || ""}`;

  if (countryCode && phone) {
    const parsedNumber = parsePhoneNumberFromString(countryCode + phone);

    if (parsedNumber && parsedNumber.isValid()) {
      text = parsedNumber.formatInternational();
    }
  }
  return text;
};

export const formatProfile = (profile: FreeTicketProfile, lang: FreeTicketsFamilyLanguageResource): string => {
  if (profile === FreeTicketProfile.ADULT) {
    return lang.adult;
  }
  if (profile === FreeTicketProfile.CHILD) {
    return lang.child;
  }
  if (profile === FreeTicketProfile.RETIRED) {
    return lang.reducedRate;
  }
  return "–";
};

export const formatTicketHolderType = (ticketHolderType: TicketHolderType, lang: FreeTicketsFamilyLanguageResource): string => {
  if (ticketHolderType === TicketHolderType.EMPLOYEE) return lang.employee;
  if (ticketHolderType === TicketHolderType.FAMILY_MEMBER) return lang.ticketHoldertypes;
  if (ticketHolderType === TicketHolderType.RETIRED) return lang.retired;
  if (ticketHolderType === TicketHolderType.UNKNOWN) return lang.unknown;
  return "–";
};

const MobileTicket: React.FC<Props> = ({ ticket, cancelActiveTicket, cancelPendingTicket, sendPickupCode, resendTicket, refetch }) => {
  const lang = useLanguageResource(freeTicketsFamilyLanguageResource);
  const disableCancelActiveTicketButton = ticket.status !== TICKET_ACTIVE || Boolean(ticket.expirationDate);
  const disableCancelPendingTicketButton = ticket.status !== TICKET_PENDING || Boolean(ticket.expirationDate);
  const enableSendPickupCode =
    (ticket.status === TicketStatus.active || ticket.status === TicketStatus.pending) && ticket.pickupCode && !ticket.downloadedToPhone;
  const enableResendTicket =
    (ticket.status === TicketStatus.active || ticket.status === TicketStatus.pending) && !ticket.expirationDate && ticket.downloadedToPhone;
  const enableEditButton = ticket.status === TicketStatus.active || ticket.status === TicketStatus.pending;

  const showCancelActiveTicketButton = ticket.status !== TICKET_PENDING;
  const showCancelPendingTicketButton = ticket.status === TICKET_PENDING;

  const [showEditTicket, setShowEditTicket] = useState(false);

  const fullName = `${ticket.firstName || ""} ${ticket.lastName || ""}`.trim();

  return (
    <div className="components-agreement-freeticket-mobile" data-test-id="mobile-free-ticket">
      <div className={`status ${ticket.status}`} />
      <div className="employee-name">
        <div className="value" title={fullName} data-test-id="full-name">
          {fullName}
        </div>
      </div>
      <div className="info">
        <div className="row">
          <div className="label">{lang.relationType}</div>
          <div className="value" data-test-id="relation">
            {formatTicketHolderType(ticket.ticketHolderType, lang)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.status}</div>
          <div className="value" data-test-id="status">
            <TicketStatus2 status={ticket.status} />
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.orderNumber}</div>
          <div className="value" data-test-id="ticket-id">
            {formatStringOrNull(ticket.id)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.mobileNumber}</div>
          <div className="value" data-test-id="phone">
            {formatPhoneNumber(ticket.phoneCountryCode || "", ticket.phone || "")}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.birthDate}</div>
          <div className="value" data-test-id="employee-birth-date">
            {formatDate(ticket.employeeBirthDate)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.validFrom}</div>
          <div className="value" data-test-id="start-date">
            {moment(ticket.startDate).format("L")}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.validTo}</div>
          <div className="value" data-test-id="expiration-date">
            {formatDate(ticket.expirationDate)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.pickedUp}</div>
          <div className="value" data-test-id="picked-up">
            {formatPickedUp(ticket.downloadedToPhone, lang)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.pickupCode}</div>
          <div className="value" data-test-id="pickup-code">
            {formatStringOrNull(ticket.pickupCode)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.appiId}</div>
          <div className="value" data-test-id="app-id">
            {formatStringOrNull(ticket.appId)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.pricePerMonth}</div>
          <div className="value" data-test-id="price">
            {formatCurrencyAmount(ticket, ticket.pricePerMonth)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.taxAmount}</div>
          <div className="value" data-test-id="tax-amount">
            {formatCurrencyAmount(ticket, ticket.taxAmount)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.zones}</div>
          <div className="value" data-test-id="zones">
            <TicketZonesField
              allZones={ticket.allZones}
              
              nrOfZones={ticket.nrOfZones || 0}
              zoneFrom={ticket.zoneFrom}
              zoneTo={ticket.zoneTo}
            />
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.profile}</div>
          <div className="value" data-test-id="profile">
            {formatProfile(ticket.profile, lang)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.invoiceRef}</div>
          <div className="value" data-test-id="department">
            {formatStringOrNull(ticket.invoiceReference)}
          </div>
        </div>
      </div>
      <div className="actions">
        <button type="button" disabled={!enableEditButton} onClick={() => setShowEditTicket(true)} data-test-id="edit-ticket-button">
          <Icon variant="PencilIcon" size="small" />
          <span>{lang.editTicket}</span>
        </button>

        <button
          type="button"
          disabled={!enableSendPickupCode && !enableResendTicket}
          onClick={() => (enableResendTicket ? resendTicket(ticket) : sendPickupCode(ticket))}
          data-test-id="send-pickup-code"
        >
          <Icon variant="CellPhoneArrowRightIcon" size="small" />
          <span>{enableResendTicket ? lang.resendTicket : lang.sendPickupCode}</span>
        </button>

        {showCancelActiveTicketButton && (
          <button
            type="button"
            disabled={disableCancelActiveTicketButton}
            data-test-id="cancel-active-ticket"
            onClick={() => cancelActiveTicket(ticket)}
          >
            <Icon variant="CrossIcon" size="small" />
            <span>{lang.cancelActiveTicket}</span>
          </button>
        )}
        {showCancelPendingTicketButton && (
          <button
            type="button"
            disabled={disableCancelPendingTicketButton}
            data-test-id="cancel-pending-ticket"
            onClick={() => cancelPendingTicket(ticket)}
          >
            <Icon variant="CrossIcon" size="small" />
            <span>{lang.cancelPendingTicket}</span>
          </button>
        )}
      </div>
      {showEditTicket && (
        <EditFreeTicketComponent
          submitted={() => {
            setShowEditTicket(false);
            refetch();
          }}
          ticket={ticket}
          hide={() => setShowEditTicket(false)}
        />
      )}
    </div>
  );
};

export default MobileTicket;
