import { CompanyAgreementOrder, getValidOrder as getValidOrderJson, mapCompanyAgreementOrder, OrderJson } from "./order";

interface PayrollJson {
  month: number;
  year: number;
  orderLinePrice: number;
  order: OrderJson;
}

export interface Payroll {
  month: number;
  year: number;
  orderLinePrice: number;
  order: CompanyAgreementOrder;
}

const mapPayroll = (json: PayrollJson): Payroll => ({
  month: json.month,
  year: json.year,
  orderLinePrice: json.orderLinePrice,
  order: mapCompanyAgreementOrder(json.order),
});

export const mapPayrollArray = (json: PayrollJson[]): Payroll[] => json.map(mapPayroll);

export const getValidPayrollJson = (changes?: Partial<PayrollJson>): PayrollJson => ({
  month: 1,
  year: 2020,
  orderLinePrice: 1000,
  order: getValidOrderJson(),
  ...changes,
});
