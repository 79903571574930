// ticket status
export const TICKET_PENDING = "pending";
export const TICKET_EXPIRED = "expired";
export const TICKET_ACTIVE = "active";
export const TICKET_CANCELLED = "cancelled";

// All zones
export const ALL_ZONES_FROM = "Ruter";
export const ALL_ZONES_TO = "";
export const ZONE_1 = "RUT:TariffZone:227";

// Create Ticket Policies
export const CREATE_POLICY_ALLOW_TICKET_REQUESTS = "ALLOW_TICKET_REQUESTS";
export const CREATE_POLICY_DISABLED = "DISABLED";
export const CREATE_POLICY_ALLOW_TICKET_CREATION = "ALLOW_TICKET_CREATION";

export const UNKNOWN = "UNKNOWN";

// Date format
export const DATE_FORMAT = "dd.MM.yyyy";
