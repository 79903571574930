import { mapUser } from "src/components/userAdmin/users/user";
import { AuthCompanyJson, mapAuthCompany } from "src/types/AuthCompany";
import { ContactJson, mapContact } from "src/types/Contact";
import { mapUserRoles } from "src/types/user/UserRoles";
import { UserCreateContract } from "./userCreateContract";


const userApi = {
  auth: {
    getContact: () => ({ method: "get" as const, url: "/user-api/auth/contact", mapper: (mapContact) }),
    getCompanies: () => ({ method: "get" as const, url: "/user-api/auth/companies", mapper: (companiesJson: AuthCompanyJson[]) => companiesJson.map((companyJson) => mapAuthCompany(companyJson)) }),
    getContactNoMapping: () => ({ method: "get" as const, url: "/user-api/auth/contact", mapper: (json: ContactJson) => json }),
    getCompaniesNoMapping: () => ({ method: "get" as const, url: "/user-api/auth/companies", mapper: (json: AuthCompanyJson[]) => json }),
    getUserById: (userId: string) => ({ method: "get" as const, url: `/user-api/user/${userId}`, mapper: mapUser }),
    getRolesByUserId: (userId: string) => ({ method: "get" as const, url: `/user-api/user/${userId}/roles`, mapper: mapUserRoles }),
    createUser: (contract: UserCreateContract) => ({ method: "post" as const, url: "/user-api/user/create", body: contract, mapper: mapUser }),
  },
};

export default userApi;
