import { apiClient } from "@ruter-as/web-components-and-tools";
import { useEffect, useState } from "react";
import { useAuthContextAuthenticated, useValidHousingAssociationService } from "src/AuthContext";
import housingAssociationApi from "src/common/api/companyAgreementApi/housingAssociation/housingAssociationApi";
import { CreateHousingAssociationWidgetContract, HousingAssociationWidget } from "src/common/api/companyAgreementApi/housingAssociation/housingAssociationWidget";

const useHousingWidget = () => {
  const authContext = useAuthContextAuthenticated();
  const housingAssociationService = useValidHousingAssociationService();
  const { selectedCompany } = authContext.userData;
  const [widget, setWidget] = useState<HousingAssociationWidget | undefined>(undefined);

  if (!selectedCompany) {
    throw new Error("selectedCompany is not set");
  }

  const { id: selectedCompanyId } = selectedCompany;

  useEffect(() => {
    const ensureWidget = async () => {
      const response = await apiClient.request(housingAssociationApi.widget.getHousingAssociationWidgetByCompanyId(selectedCompanyId));
      if (response.type === "success") {
        setWidget(response.result);
      } else if (response.type === "HttpError" && response.responseStatus === 404) {
        const body: CreateHousingAssociationWidgetContract = {
          agreementNumbers: [housingAssociationService.agreementNumber],
          format: "horisontal",
          width: "320",
          colourScheme: "light",
          logo: "",
          employeeIdHelpText: "",
          employeeIdRegex: "",
        };
        const postResponse = await apiClient.request(housingAssociationApi.auth.widget.createWidget(body));

        if (postResponse.type === "success") {
          setWidget(postResponse.result);
        } else {
          setWidget(() => { throw postResponse.error; });
        }

      } else {
        setWidget(() => { throw response.error; });
      }
    };
    ensureWidget();
  }, [selectedCompanyId, housingAssociationService]);

  if (widget && !widget.clientId) {
    throw new Error("clientId can not be undefined");
  }

  if (widget && !widget.clientSecret) {
    throw new Error("clientSecret can not be undefined");
  }


  return widget;
};

export default useHousingWidget;