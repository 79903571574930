import { SearchResult } from "@ruter-as/billettluke-frontend";
import { apiClient, Button, Container, SearchInput, Table, TablePagination, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContextAuthenticated, useValidHousingAssociationService } from "src/AuthContext";
import housingAssociationApi from "src/common/api/companyAgreementApi/housingAssociation/housingAssociationApi";
import { HousingAssociationOrder } from "src/common/api/companyAgreementApi/housingAssociation/housingAssociationOrder";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import getTicketOwnerFieldName from "src/common/ticketOwnerFieldName";
import usePaginationAndQuery from "src/common/usePaginationAndQuery";
import GotoColumn from "src/components/common/Table/GotoColumn/GotoColumn";
import StatusColumn from "src/components/common/Table/StatusColumn/StatusColumn";
import "./HousingAssociationTickets.scss";
import housingAssociationTicketsLanguageResource from "./lang-resource";


const HousingAssociationTickets: React.FC = () => {
  const authContext = useAuthContextAuthenticated();
  const housingAssociationService = useValidHousingAssociationService();
  const { selectedCompany } = authContext.userData;
  const companyId = selectedCompany.id;
  const lang = useLanguageResource(housingAssociationTicketsLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const navigate = useNavigate();
  const ticketOwnerFieldName = getTicketOwnerFieldName(housingAssociationService);
  const [searchResult, setSearchResult] = useState<SearchResult<HousingAssociationOrder>>();
  const { setQuery, query, throttledQuery, pagination } = usePaginationAndQuery();
  const { page, pageSize } = pagination;

  useEffect(() => {
    const fetch = async () => {
      setSearchResult(undefined);
      const response = await apiClient.request(housingAssociationApi.ticket.getTicketsByCompanyId(companyId, throttledQuery, page, pageSize));

      if (response.type === "success") {
        setSearchResult(response.result);
      } else {
        setSearchResult(() => { throw response.error; });
      }
    };
    fetch();
  }, [companyId, page, pageSize, throttledQuery]);

  if (!authContext.userData.selectedCompany.id) {
    return null;
  }

  const renderRow = (row: HousingAssociationOrder) => (
    <tr key={row.id} onClick={() => navigate(`/borettslag/boenhet/${row.id}`)}>
      <td>{row.firstName}</td>
      <td>{row.lastName}</td>
      <td>{row.employeeId}</td>
      <td>{row.phone}</td>
      <StatusColumn status={row.status} />
      <GotoColumn />
    </tr>
  );


  return <Container width="l" data-test-id="housing-association-tickets" className="components-housing-association-tickets">
    <h1>{lang.title}</h1>
    <div className="search-and-new-ticket">
      <SearchInput
        placeholder={lang.searchPlaceholder}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <Button
        type="button"
        variant="primary"
        data-test-id="create-ticket-button"
        text={lang.newTicket}
        onClick={() => navigate("/borettslag/opprett")}
        disabled={!housingAssociationService.allowOrderTicket} />
    </div>

    <div>
      <Table breakpoint="600px" loading={!searchResult}>
        <thead>
          <tr>
            <th scope="col">{formLang.firstName}</th>
            <th scope="col">{formLang.lastName}</th>
            <th scope="col">{ticketOwnerFieldName}</th>
            <th scope="col">{formLang.phone}</th>
            <th scope="col">{formLang.status}</th>
            <th className="goto-column" />
          </tr>
        </thead>
        <tbody>{searchResult?.matches.map(renderRow)}</tbody>
      </Table>

      <TablePagination pagination={pagination} totalRecords={searchResult?.totalEntries} />
    </div>

  </Container>;
};

export default HousingAssociationTickets;