import { Card, Container, NumberFormInput, RadioButtonFormInput, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAuthContextAuthenticated, useValidCompanyTicketService } from "src/AuthContext";
import successAlert from "../../../common/toastr";
import { ButtonLink, WhiteButton } from "../../common/buttons";
import widgetLanguageResource from "./lang-resource";
import useWidget from "./useWidget";
import "./WidgetPage.scss";
import WidgetSkeleton from "./WidgetSkeleton";

interface Form {
  theme: "light" | "dark";
  size: string;
}

const WidgetPage: React.FC = () => {
  const lang = useLanguageResource(widgetLanguageResource);
  const authContext = useAuthContextAuthenticated();
  const companyTicketService = useValidCompanyTicketService();
  const { selectedCompany } = authContext.userData;
  const widget = useWidget();
  const formMethods = useForm<Form>({ defaultValues: { theme: "light", size: "320" }, mode: "onChange" });

  const size = formMethods.watch("size");
  const theme = formMethods.watch("theme");

  if (!selectedCompany) {
    throw new Error("selectedCompany is not set");
  }

  const { name: selectedCompanyName } = selectedCompany;

  if (!widget) {
    return (
      <Container width="l" data-test-id="widget-page" className="components-companyagreement-widget">
        <WidgetSkeleton />
      </Container>
    );
  }


  const getIframeUrl = (preview: boolean): string => {
    return companyTicketService.widgetUrls.getIframeUrl(widget, preview, theme);
  };

  const getValidSize = () => {
    let validSize = parseInt(size, 10);
    if (Number.isNaN(validSize)) {
      validSize = 320;
    }

    if (validSize < 320) {
      validSize = 320;
    }
    if (validSize > 600) {
      validSize = 600;
    }

    return validSize;
  };

  const reset = (): void => {
    formMethods.setValue("theme", "light");
    formMethods.setValue("size", "320");
  };

  const getCode = (): string => {
    return `<iframe src="${getIframeUrl(false)}" allowTransparancy="true" frameborder="0" scrolling="no" height="1000" width="${getValidSize()}"></iframe>`;
  };

  const copyCode = () => {
    navigator.clipboard.writeText(getCode());
    successAlert(lang.copiedToClipboard);
  };

  return (
    <Container width="l" data-test-id="widget-page" className="components-companyagreement-widget">
      <div className="configuration">
        <h1>{lang.title}</h1>
        <h2>{lang.chooseCompanies}</h2>
        <div>{selectedCompanyName}</div>
        <h2>{lang.chooseSize}</h2>
        <FormProvider {...formMethods}>
          <form>
            <NumberFormInput
              validationLabel={lang.size}
              name="size"
              required
              min={320}
              max={600}
            />
            <h2>{lang.chooseTheme}</h2>
            <RadioButtonFormInput name="theme" validationLabel={lang.theme} options={[{ value: "light", text: lang.themeLight }, { value: "dark", text: lang.themeDark }]}></RadioButtonFormInput>
          </form>
        </FormProvider>
        <h2>{lang.copyCodeHeader}</h2>
        <Card className="code-card" data-test-id="code-card">
          <textarea id="code-textarea" value={getCode()} readOnly />
        </Card>
        <div className="buttons">
          <ButtonLink text={lang.reset} onClick={reset} dataTestId="reset-button" />
          <WhiteButton text={lang.copyCode} onClick={copyCode} dataTestId="copy-code-button" />
        </div>
      </div>
      <div className="widget">
        <h1>{lang.preview}</h1>
        <p>{lang.previewInfo}</p>
        <div style={{ width: `${getValidSize() + 32}px`, marginTop: "1rem" }}>
          <Card>
            <iframe
              title={lang.preview}
              src={getIframeUrl(true)}
              frameBorder="0"
              scrolling="no"
              height="830"
              width={getValidSize()}
              data-test-id="iframe"
            />
          </Card>
        </div>
      </div>
    </Container>
  );
};

export default WidgetPage;

