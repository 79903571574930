import { apiClient, Container, formatter, Table, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuthContextAuthenticated, useValidCompanyTicketService } from "src/AuthContext";
import { Zone } from "src/common/api/commonTypes/Zone";
import companyAgreementApi from "src/common/api/companyAgreementApi/companyAgreementApi";
import { CompanyAgreementOrder } from "src/common/api/companyAgreementApi/order";
import { ButtonLink } from "src/components/common/buttons";
import { ActiveSubscription } from "src/types/backendContracts/companyAgreementApi/ActiveSubscription";
import { SearchResult } from "src/types/SearchResult";
import { EmployeeInvoice, InvoiceEmployeeJson } from "../../../common/api/companyAgreementApi/EmployeeInvoice";
import getTicketOwnerFieldName from "../../../common/ticketOwnerFieldName";
import { TICKET_ACTIVE, TICKET_CANCELLED, TICKET_EXPIRED, TICKET_PENDING } from "../../../constants";
import PriceColumn from "../../common/Table/PriceColumn/PriceColumn";
import { TicketZonesComponent } from "../../common/text/ticketZones/TicketZones";
import AddOn from "./AddOn";
import ChangeInfoModal from "./ChangeInfoModal";
import "./EmployeeTicketsPage.scss";
import employeeTicketsLanguageResource from "./lang-resource";
import SkeletonTicket from "./SkeletonTicket";
import Ticket from "./Ticket";

interface PageData {
  orders: SearchResult<CompanyAgreementOrder>;
  order: CompanyAgreementOrder;
  employeeInvoices: EmployeeInvoice[];
  zones: Zone[];
  activeSubscriptions: ActiveSubscription[];
}

const getMonth = (month: number, year: number) => formatter.date.toMonthAndYear(new Date(year, month - 1, 1));

const renderPayrollRow = (data: EmployeeInvoice) => (
  <tr key={data.id}>
    <td>{getMonth(data.month, data.year)}</td>
    <td>{data.invoiceLine.ticketId}</td>
    <td>
      {data.invoiceLine.numberOfZones === 0 ? (
        "-"
      ) : (
        <TicketZonesComponent zones={data.invoiceLine.zones} nrOfZones={data.invoiceLine.numberOfZones} />
      )}
    </td>
    <PriceColumn price={data.invoiceLine.price} />
  </tr>
);

const sortRows = (a: InvoiceEmployeeJson, b: InvoiceEmployeeJson) => {
  const sortA = new Date(a.year, a.month, 1).toISOString();
  const sortB = new Date(b.year, b.month, 1).toISOString();

  if (sortA > sortB) {
    return -1;
  }
  if (sortA < sortB) {
    return 1;
  }

  return 0;
};

const EmployeeTicketsPage: React.FC = () => {
  const authContext = useAuthContextAuthenticated();
  const companyTicketService = useValidCompanyTicketService();
  const language = useLanguageResource(employeeTicketsLanguageResource);
  const ticketOwnerFieldName = getTicketOwnerFieldName(companyTicketService);
  const { id } = useParams();
  const [pageData, setPageData] = useState<PageData | undefined>();
  const [changeInfo, setChangeInfo] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetch = useCallback(async () => {
    setLoading(true);
    const [ordersResponse, orderResponse, invoicesResponse, zonesResponse, addOnResponse] = await Promise.all([
      apiClient.request(companyAgreementApi.ticket.getEmployeeTicketsByTicketId(id || "")),
      apiClient.request(companyAgreementApi.ticket.getByTicketId(id || "")),
      apiClient.request(companyAgreementApi.invoice.getByTicketId(id || "")),
      apiClient.request(companyAgreementApi.zones.getAll()),
      apiClient.request(companyAgreementApi.addOn.getByTicketId(id || "")),
    ]);

    if (ordersResponse.type !== "success") { setPageData(() => { throw ordersResponse.error; }); return; }
    if (orderResponse.type !== "success") { setPageData(() => { throw orderResponse.error; }); return; }
    if (invoicesResponse.type !== "success") { setPageData(() => { throw invoicesResponse.error; }); return; }
    if (zonesResponse.type !== "success") { setPageData(() => { throw zonesResponse.error; }); return; }
    if (addOnResponse.type !== "success") { setPageData(() => { throw addOnResponse.error; }); return; }

    setPageData({
      activeSubscriptions: addOnResponse.result,
      employeeInvoices: invoicesResponse.result.sort(sortRows).slice(0, 5),
      order: orderResponse.result,
      orders: ordersResponse.result,
      zones: zonesResponse.result,
    });
    setLoading(false);

  }, [id]);

  useEffect(() => {
    fetch();
  }, [fetch]);


  const renderSkeleton = () => (
    <Container width="m" className="components-employeetickets">
      <div className="skeleton-h1" />
      <div className="skeleton-employee-info">
        <div className="content" />
      </div>
      <div className="skeleton-employee-info">
        <div className="content" />
      </div>
      <div className="skeleton-employee-info">
        <div className="content" />
      </div>
      <div className="skeleton-h2">
        <div className="content" />
      </div>
      <div className="tickets">
        {pageData?.orders.matches.map((ticket) => (
          <SkeletonTicket key={ticket.id} />
        ))}
      </div>
    </Container>
  );

  if (loading || pageData === undefined) {
    return renderSkeleton();
  }

  const activeTickets = pageData.orders.matches.filter((ticket) => ticket.status === TICKET_ACTIVE || ticket.status === TICKET_PENDING);
  const inactiveTickets = pageData.orders.matches.filter((ticket) => ticket.status === TICKET_CANCELLED || ticket.status === TICKET_EXPIRED);

  return (
    <Container width="l" className="components-employeetickets" data-test-id="components-employeetickets">
      <h1>{`${pageData.order.firstName} ${pageData.order.lastName}`}</h1>

      <div className="top">
        <div className="left">
          <div className="employee-info">
            <span className="label">{ticketOwnerFieldName}</span>
            <span className="value" data-test-id="employee-id-value">{pageData.order.employeeId}</span>
          </div>
          <div className="employee-info">
            <span className="label">{language.mobileNumber}</span>
            <span className="value" data-test-id="employee-phone-value">{pageData.order.phone}</span>
          </div>
          <div className="employee-info">
            <span className="label">{language.companyName}</span>
            <span className="value" data-test-id="employee-company-name-value">{authContext.userData.selectedCompany.name}</span>
          </div>
          <ButtonLink text={language.changeInfo} onClick={() => setChangeInfo(true)} dataTestId="change-employee-info-button" />
        </div>
      </div>

      <h2>{language.tickets}</h2>
      <div className="tickets" data-test-id="active-tickets-container">
        {activeTickets.map((ticket) => (
          <Ticket key={ticket.id} zones={pageData.zones} ticket={ticket} onUpdateRequest={() => { fetch(); }} />
        ))}
        <div className="tickets" data-test-id="employee-ticket-subscriptions">
          {!loading && pageData.activeSubscriptions.length > 0 && (
            <>
              <div className="tickets" data-test-id="employee-ticket-subscriptions">
                {pageData.activeSubscriptions.map((addOn) => (
                  <AddOn key={addOn.type} addOn={addOn} orderId={id || ""} />
                ))}
              </div>
            </>
          )}
        </div>
      </div>

      <h2>{language.expired}</h2>
      <div className="tickets" data-test-id="deactive-tickets-container">
        {inactiveTickets.map((ticket) => (
          <Ticket key={ticket.id} zones={pageData.zones} ticket={ticket} onUpdateRequest={() => { fetch(); }} />
        ))}
      </div>

      <h2>{language.payroll}</h2>
      <Table>
        <thead>
          <tr>
            <th>{language.invoiceMonth}</th>
            <th>{language.orderNumber}</th>
            <th>{language.zones}</th>
            <th style={{ textAlign: "right" }}>{language.amount}</th>
          </tr>
        </thead>
        <tbody>{pageData.employeeInvoices.map(renderPayrollRow)}</tbody>
      </Table>

      {changeInfo && <ChangeInfoModal ticket={pageData.order} onSuccess={() => { setChangeInfo(false); fetch(); }} onClose={() => setChangeInfo(false)} />}
    </Container>
  );
};

export default EmployeeTicketsPage;
