import { formatter, Icon, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React, { useState } from "react";
import { Zone } from "src/common/api/commonTypes/Zone";
import { HousingAssociationOrder } from "src/common/api/companyAgreementApi/housingAssociation/housingAssociationOrder";
import { FormFieldsLanguageResource, formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import { TICKET_ACTIVE, TICKET_CANCELLED, TICKET_EXPIRED, TICKET_PENDING } from "../../../constants";
import TicketStatus from "../../common/text/ticketStatus/TicketStatus";
import { TicketZonesComponent } from "../../common/text/ticketZones/TicketZones";
import { housingUnitLanguageResource } from "./lang-resource";
import CancelActiveTicketModal from "./modal/CancelActiveTicketModal";
import CancelPendingTicketModal from "./modal/CancelPendingTicketModal";
import ChangeZoneModal from "./modal/ChangeZoneModal";
import ResendPickupConfirmationModal from "./modal/ResendPickupConfirmationModal";
import ResendTicketConfirmationModal from "./modal/ResendTicketConfirmationModal";
import "./Ticket.scss";

const formatExpirationDate = (expirationDate: Date | null) => {
  if (expirationDate === null) {
    return "–";
  }
  return formatter.date.toShortDateString(expirationDate);
};

const formatPicketUp = (pickedUp: boolean, language: FormFieldsLanguageResource) => {
  if (pickedUp) {
    return language.yes;
  }
  return language.no;
};

const calculatePrice = (ticket: HousingAssociationOrder) => {
  if (ticket.status !== TICKET_ACTIVE) {
    return "–";
  }

  const price = ticket.pricePerMonth;

  return formatter.number.currency(price, false);
};

const formatAppId = (appId: string) => {
  if (appId) {
    return appId;
  }

  return "–";
};

const formatPickupCode = (pickupCode: string) => {
  if (pickupCode) {
    return pickupCode;
  }

  return "–";
};

interface Props {
  ticket: HousingAssociationOrder;
  onUpdateRequest: () => void;
  zones: Zone[];
}

const Ticket: React.FC<Props> = ({ ticket, onUpdateRequest, zones  }) => {
  const language = useLanguageResource(housingUnitLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const [changeZoneTicket, setChangeZoneTicket] = useState(false);
  const [resendTicket, setResendTicket] = useState(false);
  const [sendPickupCodeTicket, setSendPickupCodeTicket] = useState(false);
  const [cancelActiveTicket, setCancelActiveTicket] = useState(false);
  const [cancelPendingTicket, setCancelPendingTicket] = useState(false);

  const disableChangeZoneButton = ticket.status !== TICKET_ACTIVE || Boolean(ticket.expirationDate);
  const disableCancelActiveTicketButton = ticket.status !== TICKET_ACTIVE || Boolean(ticket.expirationDate);
  const enableSendPickupCode =
    ticket.status !== TICKET_EXPIRED &&
    ticket.status !== TICKET_CANCELLED &&
    ticket.pickupCode &&
    !ticket.downloadedToPhone;
  const showResendTicketButton =
    ticket.status !== TICKET_EXPIRED &&
    ticket.status !== TICKET_CANCELLED &&
    !ticket.expirationDate &&
    ticket.downloadedToPhone;

  const showSendPickupCode = !showResendTicketButton;

  const showCancelActiveTicketButton = ticket.status !== TICKET_PENDING;
  const showCancelPendingTicketButton = ticket.status === TICKET_PENDING;

  return (
    <div className="housing-unit-ticket" data-test-id="housing-unit-ticket" data-test-ticket-id={ticket.id}>
      <div className={`status ${ticket.status}`} />
      <div className="employee-name">
        <div className="value" data-test-id="full-name">
          {`${ticket.firstName} ${ticket.lastName}`}
        </div>
      </div>
      <div className="info">
        <div className="row">
          <div className="label">{language.status}</div>
          <div className="value" data-test-id="ticket-status-value">
            <TicketStatus status={ticket.status as any} />
          </div>
        </div>
        <div className="row">
          <div className="label">{language.orderId}</div>
          <div className="value" data-test-id="ticket-id-value">{ticket.id}</div>
        </div>
        <div className="row">
          <div className="label">{formLang.phone}</div>
          <div className="value" data-test-id="ticket-phone-value">{ticket.phone}</div>
        </div>
        <div className="row">
          <div className="label">{language.validFrom}</div>
          <div className="value" data-test-id="ticket-start-date-value">{formatter.date.toShortDateString(ticket.startDate)}</div>
        </div>
        <div className="row">
          <div className="label">{language.validTo}</div>
          <div className="value" data-test-id="ticket-expiration-date-value">{formatExpirationDate(ticket.expirationDate)}</div>
        </div>
        <div className="row">
          <div className="label">{language.pickedUp}</div>
          <div className="value" data-test-id="ticket-downloaded-to-phone-value">{formatPicketUp(ticket.downloadedToPhone, formLang)}</div>
        </div>
        <div className="row">
          <div className="label">{language.pickupCode}</div>
          <div className="value" data-test-id="ticket-pickup-code-value">{formatPickupCode(ticket.pickupCode)}</div>
        </div>
        <div className="row">
          <div className="label">{language.appId}</div>
          <div className="value" data-test-id="ticket-app-id-value">{formatAppId(ticket.appId)}</div>
        </div>
        <div className="row">
          <div className="label">{language.pricePerMonth}</div>
          <div className="value" data-test-id="ticket-price-value">{calculatePrice(ticket)}</div>
        </div>
        <div className="row">
          <div className="label">{language.zones}</div>
          <div className="value" data-test-id="ticket-zone-value">
            <TicketZonesComponent  nrOfZones={ticket.nrOfZones} zones={ticket.zones} />
          </div>
        </div>
        <div className="row">
          <div className="label">{language.invoiceReference}</div>
          <div className="value" data-test-id="ticket-invoice-ref-value">{ticket.invoiceReference}</div>
        </div>
      </div>
      <div className="actions">
        <button type="button" onClick={() => setChangeZoneTicket(true)} disabled={disableChangeZoneButton} data-test-id="ticket-change-zone-button">
          <Icon variant="ArrowsSwapIcon" size="small" />
          <span>{language.changeZone}</span>
        </button>
        {showResendTicketButton && (
          <button
            data-test-id="resend-ticket-button"
            type="button"
            onClick={() => { setResendTicket(true); }}
          >
            <Icon variant="CellPhoneArrowRightIcon" size="small" />
            <span>{language.resendTicket}</span>
          </button>
        )}
        {showSendPickupCode && (
          <button
            data-test-id="send-pickup-code-button"
            type="button"
            disabled={!enableSendPickupCode}
            onClick={() => { setSendPickupCodeTicket(true); }}
          >
            <Icon variant="CellPhoneArrowRightIcon" size="small" />
            <span>{language.sendPickupCode}</span>
          </button>
        )}
        {showCancelActiveTicketButton && (
          <button
            data-test-id="cancel-active-ticket-button"
            type="button"
            onClick={() => setCancelActiveTicket(true)}
            disabled={disableCancelActiveTicketButton}
          >
            <Icon variant="CrossIcon" size="small" />
            <span>{language.cancelActiveTicket}</span>
          </button>
        )}
        {showCancelPendingTicketButton && (
          <button
            type="button"
            onClick={() => setCancelPendingTicket(true)}
            data-test-id="cancel-pending-ticket-button"
          >
            <Icon variant="CrossIcon" size="small" />
            <span>{language.cancelPendingTicket}</span>
          </button>
        )}
      </div>

      {changeZoneTicket && <ChangeZoneModal zones={zones} ticket={ticket} onSuccess={onUpdateRequest} onClose={() => setChangeZoneTicket(false)} />}
      {cancelActiveTicket && <CancelActiveTicketModal ticket={ticket} onSuccess={onUpdateRequest} onClose={() => setCancelActiveTicket(false)} />}
      {cancelPendingTicket && <CancelPendingTicketModal ticket={ticket} onSuccess={onUpdateRequest} onClose={() => setCancelPendingTicket(false)} />}
      {sendPickupCodeTicket && <ResendPickupConfirmationModal ticket={ticket} onSuccess={onUpdateRequest} onClose={() => setSendPickupCodeTicket(false)} />}
      {resendTicket && <ResendTicketConfirmationModal ticket={ticket} onSuccess={onUpdateRequest} onClose={() => setResendTicket(false)} />}
    </div>
  );
};

export default Ticket;
