import { apiClient, Button, Card, Container, Dropdown, FormGroup, Icon, Label, Modal, Table, useLanguageResource } from "@ruter-as/web-components-and-tools";
import jschardet from "jschardet";
import parsePhoneNumberFromString from "libphonenumber-js/max";
import moment from "moment";
import Papa from "papaparse";
import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useValidFreeTicketAgreementService } from "src/AuthContext";
import freeTicketApi from "src/common/api/freeTicketApi/freeTicketApi";
import { ColumnValue, FreeTicketMassOrderRow, MassOrderPostContract, PhoneBackendContract, Recipient, RecipientWithFileIndex, TravelCardNumberBackendContract } from "src/common/api/freeTicketApi/freeTicketMassOrderCreate";
import { MassOrderValidation, RecipientErrorType } from "src/common/api/freeTicketApi/freeTicketMassOrderValidation";
import createAndDownloadCSV from "src/common/csvFileCreator";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import { validationMessagesLanguageResource } from "src/common/validation-messages-language-resource";
import { ProfileValidationResult } from "src/types/features/profileResolver";
import { ensureExpiryDateIsNotInThePastAndFormatForBackend, getExpiryDates } from "../../../common/expiryDate";
import { FreeTicketProfile } from "../../../types/freeTicketAgreement/freeTicketProfile";
import { TicketHolderType } from "../../../types/freeTicketAgreement/ticketHolderType";
import MediaType from "../../../types/mediaType";
import { massOrderLanguageResource } from "./lang-resource";
import "./MassOrderCreate.scss";


interface ExplainError {
  rowNumber: number;
  columnName?: string;
  value?: string;
  validationMessage: string;
}
interface File {
  name: string;
  rows: FreeTicketMassOrderRow[];
}


const MassOrderCreate: React.FC = () => {
  const freeTicketService = useValidFreeTicketAgreementService();
  const lang = useLanguageResource(massOrderLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const langValidation = useLanguageResource(validationMessagesLanguageResource);
  const formMethods = useForm<FormData>();
  const [file, setFile] = useState<File | null>(null);
  const [explainError, setExplainError] = useState<ExplainError | null>(null);
  const [saving, setSaving] = useState(false);
  const [zones, setZones] = useState<string[]>([]);
  const navigate = useNavigate();
  const dates = getExpiryDates(32, formLang.today);

  useEffect(() => {
    const fetchZones = async () => {
      const response = await apiClient.request(freeTicketApi.distance.zones.get());

      if (response.type === "success") {
        setZones([...response.result.map((zone) => zone.name), ...(freeTicketService.massOrder.hasAllZones ? ["Alle"] : [])]);
      } else {
        setZones(() => {
          throw response.error;
        });
      }
    };
    fetchZones();

  }, [freeTicketService.massOrder.hasAllZones]);

  const findInvoiceRef = useCallback(
    (agreementNumberInput: string) => {
      const agreementFromInput = freeTicketService.invoiceRefs.find((x) => x.agreementNumber === agreementNumberInput);
      return agreementFromInput?.invoiceReference || `${lang.missingDepartmentName} (${agreementNumberInput})`;
    },
    [lang.missingDepartmentName, freeTicketService],
  );

  const agreementsOptions = freeTicketService.invoiceRefs.map((x) => ({ value: x.agreementNumber, text: findInvoiceRef(x.agreementNumber) }));
  agreementsOptions.sort((a, b) => {
    if (a.text < b.text) {
      return -1;
    }
    if (a.text > b.text) {
      return 1;
    }
    return 0;
  });

  const [startDate, setStartDate] = useState(dates[0].value);
  const [agreement, setAgreement] = useState(agreementsOptions[0].value);

  const parseText = (value: string, columnName: string): ColumnValue<string> => {
    if (!value) {
      return {
        valid: false,
        value,
        columnName,
        validationMessage: langValidation.required.replace("$fieldLabel$", columnName),
      };
    }
    if (value.length < 2) {
      return {
        valid: false,
        value,
        columnName,
        validationMessage: langValidation.minLength.replace("$fieldLabel$", columnName).replace("$minLength$", "2"),
      };
    }
    if (value.length > 100) {
      return {
        valid: false,
        value,
        columnName,
        validationMessage: langValidation.maxLength.replace("$fieldLabel$", columnName).replace("$maxLength$", "100"),
      };
    }

    return {
      valid: true,
      value,
      backendValue: value,
    };
  };

  const parseBirthDate = (value: string, columnName: string): ColumnValue<string> => {
    const date = moment(value, "DD.MM.YYYY", true);
    if (!value) {
      return {
        valid: false,
        value,
        columnName,
        validationMessage: langValidation.required.replace("$fieldLabel$", columnName),
      };
    }
    if (!date.isValid()) {
      return {
        valid: false,
        value,
        columnName,
        validationMessage: langValidation.invalidDate.replace("$fieldLabel$", columnName),
      };
    }
    if (date.toDate().getTime() >= new Date().getTime()) {
      return {
        valid: false,
        value,
        columnName,
        validationMessage: langValidation.maxDate.replace("$fieldLabel$", columnName).replace("$minDate$", date.format("L")),
      };
    }

    return {
      valid: true,
      value,
      backendValue: date.toDate().toISOString(),
    };
  };

  const parseTicketHolderType = (value: string, columnName: string): ColumnValue<TicketHolderType> => {
    if (!value) {
      return {
        valid: false,
        value,
        columnName,
        validationMessage: langValidation.required.replace("$fieldLabel$", columnName),
      };
    }

    const map: { [index: string]: TicketHolderType | undefined } = {
      ansatt: TicketHolderType.EMPLOYEE,
      pensjonist: TicketHolderType.RETIRED,
      familie: TicketHolderType.FAMILY_MEMBER,
    };

    const backendValue = map[value.toLowerCase()];

    if (!backendValue) {
      return {
        valid: false,
        value,
        columnName,
        validationMessage: lang.relationValidationMessage,
      };
    }

    return {
      valid: true,
      value,
      backendValue,
    };
  };

  const parseZones = (
    value: string,
    otherValue: string,
    columnName: string,
    otherColumnName: string,
    zoneFrom: boolean,
  ): ColumnValue<string> => {

    if (value.toLowerCase() !== "alle" && otherValue.toLowerCase() === "alle") {
      return {
        valid: false,
        value,
        columnName,
        validationMessage: lang.zonesAllValidationMessage
          .replace("$otherZone$", otherColumnName.toLowerCase())
          .replace("$thisZone$", columnName.toLowerCase()),
      };
    }

    if (!value) {
      return {
        valid: false,
        value,
        columnName,
        validationMessage: langValidation.required.replace("$fieldLabel$", columnName),
      };
    }

    if (value.toLowerCase() === "alle") {
      if (!freeTicketService.massOrder.hasAllZones) {
        return {
          valid: false,
          columnName,
          value,
          validationMessage: lang.zoneIsNotValid.replace("$fieldName$", value),
        };
      }
      const backendValue = (freeTicketService.massOrder.hasSpecialBackendValue && zoneFrom) ? "Ruter" : "";
      return {
        valid: true,
        backendValue,
        value,
      };
    }

    if (value.toLowerCase() !== "alle" && !zones.some((x) => x.toLowerCase() === value.toLowerCase())) {
      const firstValues = zones.slice(0, -1).join(", ");
      const lastValue = zones.slice(-1).join();

      return {
        valid: false,
        value,
        columnName,
        validationMessage: lang.zonesValidationMessage
          .replace("$fieldName$", columnName)
          .replace("$firstValues$", firstValues)
          .replace("$lastValue$", lastValue),
      };
    }

    const backendValue = zones.find((x) => x.toLowerCase() === value.toLowerCase());

    if (!backendValue) throw new Error("Backend value is undefined");

    return {
      valid: true,
      value,
      backendValue,
    };
  };

  const parseProfile = (
    value: string,
    birtDateAsString: string,
    ticketHolderType: TicketHolderType | undefined,
    columnName: string,
  ): ColumnValue<FreeTicketProfile> => {
    if (!value) {
      return {
        valid: false,
        value,
        columnName,
        validationMessage: langValidation.required.replace("$fieldLabel$", columnName),
      };
    }

    const map: { [index: string]: FreeTicketProfile | undefined } = {
      voksen: FreeTicketProfile.ADULT,
      barn: FreeTicketProfile.CHILD,
      honnør: FreeTicketProfile.RETIRED,
    };

    const backendValue = map[value.toLowerCase()];

    if (!backendValue) {
      return {
        valid: false,
        value,
        columnName,
        validationMessage: lang.profileValidationMessage,
      };
    }

    const birthDate = moment(birtDateAsString, "DD.MM.YYYY", true);

    if (ticketHolderType && birthDate.isValid()) {
      const validationResult = freeTicketService.validateProfile(birthDate.toDate(), ticketHolderType, backendValue);

      if (validationResult !== ProfileValidationResult.Valid) {
        let validationMessage = lang.unknownRecipientError.replace(": $error$", "");

        if (validationResult === ProfileValidationResult.PersonWhoHaveTurned20CannotHaveChildTicket) {
          validationMessage = lang.profileCannotBeChildWhenAgeIs20OrMoreValidationMessage;
        }
        if (validationResult === ProfileValidationResult.PersonWhoHaveTurned67ShouldHaveReducedRateTicket) {
          validationMessage = lang.profileMustBeRetiredIfPassengerIs67OrOlderValdiationMessage;
        }
        if (validationResult === ProfileValidationResult.PersonThatTurns19ThisYearOrIsYoungerShouldHaveChildTicket) {
          validationMessage = lang.profileMustBeChildWhenUserIsYoungerThan20ThisYearValidationMessage;
        }
        if (validationResult === ProfileValidationResult.RetiredOrEmployeeCannotHaveChildTicket) {
          validationMessage = lang.profileCannotBeChildWhenTicketholderTypeIsRetiredOrEmployeeValidationMessage;
        }
        if (validationResult === ProfileValidationResult.EmployeeShouldHaveAdultTicket) {
          validationMessage = lang.profileMustBeAdultWhenTicketHolderTypeIsEmployeeValidationMessage;
        }
        if (validationResult === ProfileValidationResult.FamilyMemberUnder18ShouldHaveChildTicket) {
          validationMessage = lang.profileMustBeChildWhenTicketHolderTypeIsFamilyAndIfPassengerIsUnder18ValidationMessage;
        }
        if (validationResult === ProfileValidationResult.FamilyMemberWhoIs18OrOlderShouldHaveAdultTicket) {
          validationMessage = lang.profileMustBeAdultWhenTicketHolderTypeIsFamilyAndIfPassengerIsOver18ValidationMessage;
        }
        if (validationResult === ProfileValidationResult.FamilyMemberCannotBeRetired) {
          validationMessage = lang.profileCannotBeRetiredWhenTicketHolderTypeIsFamilyMemberValidationMessage;
        }
        if (validationResult === ProfileValidationResult.RetiredShouldHaveReducedRateTicket) {
          validationMessage = lang.profileShouldBeRetiredWhenTicketHolderTypeIsRetiredValidationMessage;
        }

        return {
          valid: false,
          value,
          columnName,
          validationMessage,
        };
      }
    }

    return {
      valid: true,
      value,
      backendValue,
    };
  };

  const parseMediaType = (value: string, columnName: string): ColumnValue<MediaType> => {
    if (!value) {
      return {
        valid: false,
        value,
        columnName,
        validationMessage: langValidation.required.replace("$fieldLabel$", columnName),
      };
    }

    const map: { [index: string]: MediaType | undefined } = {
      reisekort: MediaType.TRAVEL_CARD,
      mobil: MediaType.MOBILE_TICKET,
    };

    const backendValue = map[value.toLowerCase()];

    if (!backendValue) {
      return {
        valid: false,
        value,
        columnName,
        validationMessage: lang.mediaTypeValidationMessage,
      };
    }

    return {
      valid: true,
      value,
      backendValue,
    };
  };

  const parseTravelCardNumber = (travelCardValue: string, mediaType: ColumnValue<MediaType>, columnName: string): ColumnValue<TravelCardNumberBackendContract> => {

    if (!freeTicketService.hasTravelCardNumber) {
      return {
        valid: true,
        value: travelCardValue,
        backendValue: {
          value: "",
        },
      };
    }

    if (mediaType.valid && mediaType.backendValue === MediaType.MOBILE_TICKET) {
      return {
        valid: true,
        value: travelCardValue,
        backendValue: {
          value: "",
        },
      };
    }

    if (!travelCardValue) {
      return {
        valid: false,
        value: travelCardValue,
        columnName,
        validationMessage: langValidation.required.replace("$fieldLabel$", columnName),
      };
    }

    if (!(travelCardValue.length === 16 || travelCardValue.length === 10 || travelCardValue.length === 9)) {
      return {
        valid: false,
        value: travelCardValue,
        columnName,
        validationMessage: lang.equalLengthValidation.replace("$fieldLabel$", columnName),
      };
    }

    return {
      valid: true,
      value: travelCardValue,
      backendValue: {
        value: travelCardValue,
      },
    };
  };

  const parseMobileNumber = (
    phoneValue: string,
    mediaType: ColumnValue<MediaType>,
    columnName: string,
  ): ColumnValue<PhoneBackendContract> => {

    if (mediaType.valid && mediaType.backendValue === MediaType.TRAVEL_CARD) {
      return {
        valid: true,
        value: phoneValue,
        backendValue: {
          phoneCountryCode: "",
          phone: "",
        },
      };
    }

    if (!phoneValue) {
      return {
        valid: false,
        value: phoneValue,
        columnName,
        validationMessage: langValidation.required.replace("$fieldLabel$", columnName),
      };
    }

    const phone = phoneValue.indexOf("+") === -1 ? `+47${phoneValue}` : phoneValue;
    const parsed = parsePhoneNumberFromString(phone);

    if (!parsed || !parsed.isValid()) {
      return {
        valid: false,
        value: phoneValue,
        columnName,
        validationMessage: langValidation.invalidInternationalPhoneNumber.replace("$fieldLabel$", columnName),
      };
    }

    const type = parsed.getType();

    const validMobileTypes = [undefined, "MOBILE", "FIXED_LINE_OR_MOBILE", "PERSONAL_NUMBER"];
    if (validMobileTypes.indexOf(type) === -1) {
      return {
        valid: false,
        value: phoneValue,
        columnName,
        validationMessage: langValidation.invalidInternationalMobileNumber.replace("$fieldLabel$", columnName),
      };
    }

    return {
      valid: true,
      value: parsed.formatInternational(),
      backendValue: {
        phoneCountryCode: `+${parsed.countryCallingCode}`,
        phone: parsed.nationalNumber.toString(),
      },
    };
  };

  const mapRow = (columns: string[], index: number): FreeTicketMassOrderRow => {
    let
      employeeIdRaw = "",
      firstNameRaw = "",
      lastNameRaw = "",
      birthDateRaw = "",
      relationRaw = "",
      phoneNumberRaw = "",
      travelCardNumberRaw = "",
      mediaTypeRaw = "",
      zoneFromRaw = "",
      zoneToRaw = "",
      profileRaw = "";
    if (freeTicketService.hasTravelCardNumber) {
      [
        employeeIdRaw = "",
        firstNameRaw = "",
        lastNameRaw = "",
        birthDateRaw = "",
        relationRaw = "",
        phoneNumberRaw = "",
        travelCardNumberRaw = "",
        mediaTypeRaw = "",
        zoneFromRaw = "",
        zoneToRaw = "",
        profileRaw = "",
      ] = columns;
    } else {
      [
        employeeIdRaw = "",
        firstNameRaw = "",
        lastNameRaw = "",
        birthDateRaw = "",
        relationRaw = "",
        phoneNumberRaw = "",
        mediaTypeRaw = "",
        zoneFromRaw = "",
        zoneToRaw = "",
        profileRaw = "",
      ] = columns;
    }

    const employeeId = parseText(employeeIdRaw.trim(), lang.employeeId);
    const firstName = parseText(firstNameRaw.trim(), lang.firstName);
    const lastName = parseText(lastNameRaw.trim(), lang.lastName);
    const birthDate = parseBirthDate(birthDateRaw.trim(), lang.birthDate);
    const relation = parseTicketHolderType(relationRaw.trim(), lang.relationType);
    const mediaType = parseMediaType(mediaTypeRaw.trim(), lang.mediaType);
    const phoneNumber = parseMobileNumber(phoneNumberRaw.trim(), mediaType, lang.phoneNumber);
    const travelCardNumber = parseTravelCardNumber(travelCardNumberRaw.trim(), mediaType, lang.travelCardNumber);
    const zoneFrom = parseZones(zoneFromRaw.trim(), zoneToRaw.trim() || "", lang.fromZone, lang.toZone, true);
    const zoneTo = parseZones(zoneToRaw.trim(), zoneFromRaw.trim() || "", lang.toZone, lang.fromZone, false);
    const profile = parseProfile(profileRaw.trim(), birthDateRaw.trim(), relation.valid ? relation.backendValue : undefined, lang.profile);

    const isValid =
      employeeId.valid &&
      firstName.valid &&
      lastName.valid &&
      birthDate.valid &&
      relation.valid &&
      phoneNumber.valid &&
      travelCardNumber.valid &&
      mediaType.valid &&
      zoneFrom.valid &&
      zoneTo.valid &&
      profile.valid;


    return {
      isValid,
      rowNumber: index + 1,
      rowValidationMessage: undefined,
      employeeId,
      firstName,
      lastName,
      birthDate,
      relation,
      phoneNumber,
      travelCardNumber,
      zoneFrom,
      zoneTo,
      profile,
      mediaType,
    };
  };

  const mapToRecipientsWithIndex = (rows: FreeTicketMassOrderRow[]): RecipientWithFileIndex[] => {
    const recipients: RecipientWithFileIndex[] = [];
    rows.forEach((r, fileIndex) => {
      if (
        r.profile.valid &&
        r.mediaType.valid &&
        r.relation.valid &&
        r.employeeId.valid &&
        r.zoneFrom.valid &&
        r.zoneTo.valid &&
        r.firstName.valid &&
        r.lastName.valid &&
        r.birthDate.valid &&
        r.phoneNumber.valid &&
        r.travelCardNumber.valid &&
        r.isValid &&
        r.rowValidationMessage === undefined
      ) {
        const validAllZones = r.zoneFrom.backendValue === "Ruter" || !r.zoneFrom.backendValue;
        const recipient: RecipientWithFileIndex = {
          profile: r.profile.backendValue,
          mediaType: r.mediaType.backendValue,
          ticketHolderType: r.relation.backendValue,
          employeeId: r.employeeId.backendValue,
          firstName: r.firstName.backendValue,
          lastName: r.lastName.backendValue,
          dateOfBirth: r.birthDate.backendValue,
          zoneFrom: (!validAllZones || freeTicketService.massOrder.hasSpecialBackendValue) ? r.zoneFrom.backendValue : undefined,
          zoneTo: (!validAllZones || freeTicketService.massOrder.hasSpecialBackendValue) ? r.zoneTo.backendValue : undefined,
          validAllZones,
          fileIndex,
        };

        if (r.mediaType.backendValue === MediaType.TRAVEL_CARD && r.travelCardNumber.backendValue.value) {
          recipient.cardNumber = r.travelCardNumber.backendValue.value;
        }

        if (r.mediaType.backendValue === MediaType.MOBILE_TICKET && r.phoneNumber.backendValue.phone) {
          recipient.phone = r.phoneNumber.backendValue.phone;
        }

        if (r.mediaType.backendValue === MediaType.MOBILE_TICKET && r.phoneNumber.backendValue.phoneCountryCode) {
          recipient.phoneCountryCode = r.phoneNumber.backendValue.phoneCountryCode;
        }

        if (freeTicketService.singleZoneHasAdditionalZone) {
          if (r.zoneFrom.backendValue === r.zoneTo.backendValue) {
            if (r.zoneFrom.backendValue === "Kristiansand" || r.zoneFrom.backendValue === "Vennesla") {
              recipient.zoneFrom = "Kristiansand";
              recipient.zoneTo = "Vennesla";
            }
          }
        }

        recipients.push(recipient);
      }
    });
    return recipients;
  };

  const mapToPostcontract = (recipientsWithFileIndex: RecipientWithFileIndex[]): MassOrderPostContract => {
    const recipients: Recipient[] = recipientsWithFileIndex.map((x) => ({ ...x, fileIndex: undefined }));

    const contract: MassOrderPostContract = {
      agreementNumber: agreement,
      startDate: ensureExpiryDateIsNotInThePastAndFormatForBackend(startDate).toISOString(),
      recipients,
    };

    return contract;
  };

  const applyBackendValidations = (
    rows: FreeTicketMassOrderRow[],
    recipients: RecipientWithFileIndex[],
    backendValidation: MassOrderValidation,
  ): FreeTicketMassOrderRow[] => {
    if (backendValidation.errorCount > 0) {
      backendValidation.recipientErrors.forEach((recipientError) => {
        const errorRow = rows[recipients[recipientError.indexInInput].fileIndex];

        if (recipientError.errors.length > 0) {
          if (recipientError.errors[0] === RecipientErrorType.CONFLICTING_TICKET) {
            errorRow.rowValidationMessage = lang.existingTicketValidationMessage;
            errorRow.isValid = false;
          } else {
            errorRow.rowValidationMessage = lang.unknownRecipientError.replace("$error$", recipientError.errors[0]);
            errorRow.isValid = false;
          }
        }
      });
    }

    return rows;
  };

  const readFile = async (files: FileList | null) => {
    const binaryReader = new FileReader();
    const textReader = new FileReader();

    if (files === null) {
      return;
    }

    const csvFile = files[0];
    const { name } = csvFile;

    binaryReader.onload = async (e) => {
      const result = e.target?.result;

      if (result && typeof result === "string") {
        const encodingResult = jschardet.detect(result);
        textReader.readAsText(csvFile, encodingResult.encoding);
      }
    };

    textReader.onload = async (e) => {
      const result = e.target?.result;
      if (result && typeof result === "string") {
        const decodedCsv = Papa.parse<string[]>(result, {
          delimiter: ";",
        });
        let rows = decodedCsv.data.map(mapRow);

        if (!rows) {
          return;
        }

        const recipientsWithFileIndex = mapToRecipientsWithIndex(rows);

        const contract = mapToPostcontract(recipientsWithFileIndex);
        const response = await apiClient.request(freeTicketApi.ticket.validateCreateMultiple.post(contract));

        if (response.type === "success") {
          rows = applyBackendValidations(rows, recipientsWithFileIndex, response.result);
        } else if (response.type === "HttpError" && response.responseStatus === 422) {
          rows = applyBackendValidations(rows, recipientsWithFileIndex, response.responseBody as MassOrderValidation);
        } else {
          setFile(() => { throw response.error; });
          return;
        }
        setFile({
          name,
          rows,
        });
      }
    };

    binaryReader.readAsBinaryString(csvFile);
  };

  const save = async () => {
    setSaving(true);

    if (!file) {
      return;
    }

    const recipientsWithFileIndex = mapToRecipientsWithIndex(file.rows);
    const contract = mapToPostcontract(recipientsWithFileIndex);
    const response = await apiClient.request(freeTicketApi.ticket.createMultiple.post(contract));
    if (response.type === "success") {
      setSaving(false);
      setFile(null);
      navigate(-1);
    } else {
      setSaving(() => { throw response.error; });
    }


  };

  const downloadExample = () => {
    const columnNameRow = freeTicketService.hasTravelCardNumber ? [lang.employeeId, lang.firstName, lang.lastName, lang.birthDate, lang.relationType, lang.phoneNumber, lang.travelCardNumber, lang.mediaType, lang.fromZone, lang.toZone, lang.profile] : [lang.employeeId, lang.firstName, lang.lastName, lang.birthDate, lang.relationType, lang.phoneNumber, lang.mediaType, lang.fromZone, lang.toZone, lang.profile];
    const exampleData = freeTicketService.hasTravelCardNumber ? [["NO123", "test", "lastname", "02.04.1984", "Ansatt", "45859654", "", "Mobil", "Kristiansand", "Birkenes", "Voksen"], ["NY023", "fornavn", "etternavn", "02.04.1984", "Ansatt", "", "123456789", "Reisekort", "alle", "alle", "Voksen"]] : [["NO123", "test", "lastname", "02.04.1984", "Ansatt", "45859654", "Mobil", "1", "1", "Voksen"]];
    createAndDownloadCSV(`${lang.exampleFileName}.csv`, columnNameRow, exampleData);
  };

  const renderFile = () => {
    if (!file) {
      return null;
    }

    const validRows = file.rows.filter((x) => x.isValid).length;
    const invalidRows = file.rows.length - validRows;

    const validRowsText = lang.validRowsCount.replace("$count$", validRows.toString());
    const invalidRowsText = lang.invalidRowsCount.replace("$count$", invalidRows.toString());
    const orderText = lang.order.replace("$count$", validRows.toString());

    return (
      <>
        <h2>{lang.summary}</h2>
        <Card className="file">
          <div className="header-row">
            <div className="file-name" data-test-id="file-name">
              {file.name}
            </div>
            <button className="remove-file" onClick={() => setFile(null)} type="button">
              {lang.remove}
            </button>
          </div>
          <div className="valid-rows" data-test-id="valid-rows">
            {validRowsText}
          </div>
          <div className="invalid-rows" data-test-id="invalid-rows">
            {invalidRowsText}
          </div>

          <Button
            variant="primary"
            type="button"
            text={orderText}
            data-test-id="order-button"
            loading={saving}
            onClick={save}
          />
        </Card>
      </>
    );
  };

  const renderRowNumber = (rowNumber: number, validationMessage: string | undefined) => {
    if (validationMessage) {
      const explainErrorValue = {
        rowNumber,
        validationMessage,
      };

      return (
        <td>
          <button
            type="button"
            className="explain-error-button"
            data-test-id="explain-error-button"
            onClick={() => setExplainError(explainErrorValue)}
          >
            <Icon size="small" variant="QuestionmarkCircleIcon" />
          </button>
          <span className="surround-error">&quot;</span>
          {rowNumber}
          <span className="surround-error">&quot;</span>
        </td>
      );
    }

    return <td>{rowNumber}</td>;
  };

  const renderColumn = (columnValue: ColumnValue<unknown>, rowNumber: number) => {
    if (columnValue.valid) {
      return <td>{columnValue.value}</td>;
    }

    const explainErrorValue = {
      columnName: columnValue.columnName,
      validationMessage: columnValue.validationMessage,
      rowNumber,
      value: columnValue.value,
    };

    return (
      <td>
        <button
          type="button"
          className="explain-error-button"
          data-test-id="explain-error-button"
          onClick={() => setExplainError(explainErrorValue)}
        >
          <Icon size="small" variant="QuestionmarkCircleIcon" />
        </button>
        <span className="surround-error">&quot;</span>
        {columnValue.value}
        <span className="surround-error">&quot;</span>
      </td>
    );
  };

  const renderRow = (row: FreeTicketMassOrderRow) => {
    if (freeTicketService.hasTravelCardNumber) {
      return (
        <tr key={row.rowNumber}>
          {renderRowNumber(row.rowNumber, row.rowValidationMessage)}
          {renderColumn(row.employeeId, row.rowNumber)}
          {renderColumn(row.firstName, row.rowNumber)}
          {renderColumn(row.lastName, row.rowNumber)}
          {renderColumn(row.birthDate, row.rowNumber)}
          {renderColumn(row.relation, row.rowNumber)}
          {renderColumn(row.phoneNumber, row.rowNumber)}
          {renderColumn(row.travelCardNumber, row.rowNumber)}
          {renderColumn(row.mediaType, row.rowNumber)}
          {renderColumn(row.zoneFrom, row.rowNumber)}
          {renderColumn(row.zoneTo, row.rowNumber)}
          {renderColumn(row.profile, row.rowNumber)}
        </tr>
      );
    }
    return (
      <tr key={row.rowNumber}>
        {renderRowNumber(row.rowNumber, row.rowValidationMessage)}
        {renderColumn(row.employeeId, row.rowNumber)}
        {renderColumn(row.firstName, row.rowNumber)}
        {renderColumn(row.lastName, row.rowNumber)}
        {renderColumn(row.birthDate, row.rowNumber)}
        {renderColumn(row.relation, row.rowNumber)}
        {renderColumn(row.phoneNumber, row.rowNumber)}
        {renderColumn(row.mediaType, row.rowNumber)}
        {renderColumn(row.zoneFrom, row.rowNumber)}
        {renderColumn(row.zoneTo, row.rowNumber)}
        {renderColumn(row.profile, row.rowNumber)}
      </tr>
    );
  };

  const renderTable = (rows: FreeTicketMassOrderRow[]) => {

    const renderTableRow = () => {
      if (freeTicketService.hasTravelCardNumber) {
        return (
          <tr>
            <th>{lang.rowNumber}</th>
            <th>{lang.employeeId}</th>
            <th>{lang.firstName}</th>
            <th>{lang.lastName}</th>
            <th>{lang.birthDate}</th>
            <th>{lang.relationType}</th>
            <th>{lang.phoneNumber}</th>
            <th>{lang.travelCardNumber}</th>
            <th>{lang.mediaType}</th>
            <th>{lang.fromZone}</th>
            <th>{lang.toZone}</th>
            <th>{lang.profile}</th>
          </tr>
        );
      }
      return (
        <tr>
          <th>{lang.rowNumber}</th>
          <th>{lang.employeeId}</th>
          <th>{lang.firstName}</th>
          <th>{lang.lastName}</th>
          <th>{lang.birthDate}</th>
          <th>{lang.relationType}</th>
          <th>{lang.phoneNumber}</th>
          <th>{lang.mediaType}</th>
          <th>{lang.fromZone}</th>
          <th>{lang.toZone}</th>
          <th>{lang.profile}</th>
        </tr>
      );
    };
    return (
      <Table>
        <thead>
          {renderTableRow()}
        </thead>
        <tbody>{rows.map(renderRow)}</tbody>
      </Table>
    );
  };

  if (zones.length === 0) return null;
  return (
    <Container width="l" className="components-freeTicketAgreement-massorder">
      <h1>{lang.title}</h1>
      <FormProvider {...formMethods}>
        <div className="narrow">
          <FormGroup>
            <Label text={lang.agreement}>
              <Dropdown
                name="agreement"
                value={agreement}
                onChange={(e) => setAgreement(e.target.value)}
              >
                {agreementsOptions.map((x) => (
                  <option key={x.value} value={x.value}>
                    {x.text}
                  </option>
                ))}
              </Dropdown>
            </Label>
          </FormGroup>
          <FormGroup>
            <Label text={formLang.startDate}>
              <Dropdown
                name="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              >
                {dates.map((d) => (
                  <option value={d.value} key={d.value}>
                    {d.text}
                  </option>
                ))}
              </Dropdown>
            </Label>
          </FormGroup>

          <div className="file-header">{lang.fileHeader}</div>
          <div className="file-description">
            <span>{lang.fileDescriptionPreLink}</span>
            <button className="file-example" data-test-id="download-example" onClick={downloadExample}>
              {lang.fileDesciptionLink}
            </button>
            <span>{lang.fileDescriptionPostLink}</span>
          </div>

          {!file && (
            <FormGroup style={{ marginTop: "1rem" }}>
              <input id="file" type="file" value="" onChange={(event) => readFile(event.target.files)} />
              <label htmlFor="file">{lang.addFromCsv}</label>
            </FormGroup>
          )}
          {renderFile()}
        </div>
      </FormProvider>

      {file && (
        <>
          <h2>{lang.invalidRowsHeader}</h2>
          <div className="invalid-rows-help-text">{lang.invalidRowsHelpText}</div>
          {renderTable(file.rows.filter((x) => !x.isValid))}
        </>
      )}

      {file && (
        <>
          <h2>{lang.validRowsHeader}</h2>
          {renderTable(file.rows.filter((x) => x.isValid))}
        </>
      )}

      <Modal
        isOpen={Boolean(explainError)}
        title={lang.errorExplainTitle}
        handleClose={() => setExplainError(null)}
        className="mass-order-error-explan-modal"
      >
        <div className="error-row">
          <div className="label">{lang.rowNumber}</div>
          <div className="value" data-test-id="row-number-value">
            {explainError?.rowNumber}
          </div>
        </div>

        {explainError?.columnName !== undefined && (
          <div className="error-row">
            <div className="label">{lang.columnName}</div>
            <div className="value" data-test-id="column-name-value">
              {explainError?.columnName}
            </div>
          </div>
        )}
        {explainError?.value !== undefined && (
          <div className="error-row">
            <div className="label">{lang.value}</div>
            <div className="value" data-test-id="error-value">
              <span className="surround-error">&quot;</span>
              {explainError?.value}
              <span className="surround-error">&quot;</span>
            </div>
          </div>
        )}

        <div className="error-row">
          <div className="label">{lang.validationMessage}</div>
          <div className="value" data-test-id="validation-message">
            {explainError?.validationMessage}
          </div>
        </div>
      </Modal>
    </Container>
  );
};

export default MassOrderCreate;
