import { hasValidAgreement } from "src/common/agreements";
import { AgreementSubTypeId } from "src/types/AgreementSubTypeId";
import { AuthCompany } from "src/types/AuthCompany";
import { Contact } from "src/types/Contact";
import { FreeTicketProfile } from "src/types/freeTicketAgreement/freeTicketProfile";
import { TicketHolderType } from "src/types/freeTicketAgreement/ticketHolderType";
import { CompanyRoleType } from "src/types/user/CompanyRoleType";
import { UserRoleType } from "src/types/user/UserRoleType";
import { getValidProfiles, ProfileValidationResult, validateProfile } from "../profileResolver";
import { Tenant } from "../Tenant";
import { InvoiceRef, mapInvoiceRef } from "./InvoiceRef";
import { HasNoService } from "./Services";


export type FreeTicketService = HasFreeTicketService | HasNoService;

interface MassOrder {
  hasAllZones: boolean
  hasSpecialBackendValue: boolean
}

const calculateMassOrder = (tenant: Tenant): MassOrder => {
  return {
    hasAllZones: tenant === Tenant.Ruter || tenant === Tenant.Akt,
    hasSpecialBackendValue: tenant === Tenant.Ruter,
  };
};

export interface HasFreeTicketService {
  hasService: true,
  invoiceRefs: InvoiceRef[]
  allowOrderTicket: boolean
  hasTravelCardAdmin: boolean
  hasFreeticketOrCompanyTicketAdmin: boolean
  showDateInput: boolean
  massOrder: MassOrder
  hasTravelCardNumber: boolean
  singleZoneHasAdditionalZone: boolean
  allowOrderReplacementCard: boolean
  validateProfile: (birthDate: Date, ticketHolderType: TicketHolderType, freeTicketProfile: FreeTicketProfile,) => ProfileValidationResult
  getValidProfiles: (birthDate: Date, ticketHolderType: TicketHolderType,) => FreeTicketProfile[]
}

export const calculateFreeTicketService = (selectedCompany: AuthCompany, contact: Contact, tenant: Tenant): FreeTicketService => {
  const agreements = selectedCompany.allAgreements.filter(x => x.subTypeId === AgreementSubTypeId.FREETICKET);

  if (agreements.length === 0) {
    return { hasService: false };
  }
  const isCompanyAdmin = contact.roles.some(role => role === UserRoleType.GlobalCompanyAdmin)
    || selectedCompany.roles.some(role => role === CompanyRoleType.FreeTicketOrCompanyTicketAdmin);
  const hasTravelCardAdminAndTenantBrakar = contact.roles.some(role => role === UserRoleType.GlobalTravelCardAdmin) && tenant === Tenant.Brakar;

  if (!isCompanyAdmin && !hasTravelCardAdminAndTenantBrakar) {
    return { hasService: false };
  }

  return {
    hasService: true,
    invoiceRefs: agreements.map(mapInvoiceRef),
    allowOrderTicket: hasValidAgreement(agreements),
    hasFreeticketOrCompanyTicketAdmin: isCompanyAdmin,
    hasTravelCardAdmin: hasTravelCardAdminAndTenantBrakar,
    showDateInput: tenant === Tenant.Ruter,
    massOrder: calculateMassOrder(tenant),
    hasTravelCardNumber: tenant === Tenant.Akt,
    singleZoneHasAdditionalZone: tenant === Tenant.Akt,
    allowOrderReplacementCard: tenant !== Tenant.Akt,
    getValidProfiles: (birthDate: Date, ticketHolderType: TicketHolderType) => getValidProfiles(birthDate, ticketHolderType, tenant),
    validateProfile: (
      birthDate: Date,
      ticketHolderType: TicketHolderType,
      freeTicketProfile: FreeTicketProfile,
    ) => validateProfile(birthDate, ticketHolderType, freeTicketProfile, tenant),
  };

};
