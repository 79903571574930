import { AuthCompany } from "../AuthCompany";
import { Contact } from "../Contact";
import { CompanyRoleType } from "../user/CompanyRoleType";
import { UserRoleType } from "../user/UserRoleType";

type UserAdminType = "none" | "local" | "global";

export interface UserAdmin {
  hasAdminRights: boolean
  hasGlobalAdminRights: boolean
}


const calculateUserAdminType = (contact: Contact, selectedCompany: AuthCompany): UserAdminType => {
  if (contact.roles.some((r) => r === UserRoleType.GlobalUserAdmin)) {
    return "global";
  }
  if (selectedCompany.roles.some((x) => x === CompanyRoleType.UserAdmin)) {
    return "local";
  }
  return "none";
};


export const calculateUserAdmin = (selectedCompany: AuthCompany, contact: Contact): UserAdmin => {
  const type = calculateUserAdminType(contact, selectedCompany);
  return {
    hasAdminRights: type === "global" || type === "local",
    hasGlobalAdminRights: type === "global",
  };
};

