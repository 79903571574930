import { getValidHousingAssociationOrder, HousingAssociationOrder, HousingAssociationOrderJson, mapHousingAssociationOrder } from "./housingAssociationOrder";

interface HousingUnitCostJson {
  month: number;
  year: number;
  orderLinePrice: number;
  order: HousingAssociationOrderJson;
}

export interface HousingUnitCost {
  month: number;
  year: number;
  orderLinePrice: number;
  order: HousingAssociationOrder;
}

const mapHousingCost = (json: HousingUnitCostJson): HousingUnitCost => ({
  month: json.month,
  year: json.year,
  orderLinePrice: json.orderLinePrice,
  order: mapHousingAssociationOrder(json.order),
});

export const mapHousingCostArray = (json: HousingUnitCostJson[]): HousingUnitCost[] => json.map(mapHousingCost);

export const getValidHousingUnitCostJson = (changes?: Partial<HousingUnitCostJson>): HousingUnitCostJson => ({
  month: 1,
  year: 2020,
  orderLinePrice: 1000,
  order: getValidHousingAssociationOrder(),
  ...changes,
});
