import moment from "moment";
import { Tenant } from "src/types/features/Tenant";
import { FreeTicketProfile } from "../../types/freeTicketAgreement/freeTicketProfile";
import { TicketHolderType } from "../../types/freeTicketAgreement/ticketHolderType";


export enum ProfileValidationResult {
  Valid = "Valid",
  PersonWhoHaveTurned67ShouldHaveReducedRateTicket = "PersonWhoHaveTurned67ShouldHaveReducedRateTicket",
  RetiredOrEmployeeCannotHaveChildTicket = "RetiredOrEmployeeCannotHaveChildTicket",
  PersonThatTurns19ThisYearOrIsYoungerShouldHaveChildTicket = "PersonThatTurns19ThisYearOrIsYoungerShouldHaveChildTicket",
  PersonWhoHaveTurned20CannotHaveChildTicket = "PersonWhoHaveTurned20CannotHaveChildTicket",
  EmployeeShouldHaveAdultTicket = "EmployeeShouldHaveAdultTicket",
  FamilyMemberCannotBeRetired = "FamilyMemberCannotBeRetired",
  FamilyMemberUnder18ShouldHaveChildTicket = "FamilyMemberUnder18ShouldHaveChildTicket",
  FamilyMemberWhoIs18OrOlderShouldHaveAdultTicket = "FamilyMemberWhoIs18OrOlderShouldHaveAdultTicket",
  RetiredShouldHaveReducedRateTicket = "RetiredShouldHaveReducedRateTicket",
}

export const validateProfile = (
  birthDate: Date,
  ticketHolderType: TicketHolderType,
  freeTicketProfile: FreeTicketProfile,
  tenant: Tenant,
): ProfileValidationResult => {
  const isUnder18 = moment().diff(birthDate, "year") < 18;
  const turns20ThisYearButIs19 = moment().endOf("year").diff(birthDate, "year") === 20 && moment().diff(birthDate, "year") === 19;
  const personIs67OrOlder = moment().diff(birthDate, "year") >= 67;
  const personIsTwentyOrOlder = moment().diff(birthDate, "year") >= 20;

  if (tenant === Tenant.Ruter) {
    if (ticketHolderType === TicketHolderType.RETIRED || ticketHolderType === TicketHolderType.EMPLOYEE) {
      if (personIs67OrOlder) {
        if (freeTicketProfile === FreeTicketProfile.RETIRED) {
          return ProfileValidationResult.Valid;
        }
        return ProfileValidationResult.PersonWhoHaveTurned67ShouldHaveReducedRateTicket;
      }
      if (freeTicketProfile === FreeTicketProfile.CHILD) {
        return ProfileValidationResult.RetiredOrEmployeeCannotHaveChildTicket;
      }
      return ProfileValidationResult.Valid;
    }
    if (ticketHolderType === TicketHolderType.FAMILY_MEMBER) {
      if (personIs67OrOlder) {
        if (freeTicketProfile === FreeTicketProfile.RETIRED) {
          return ProfileValidationResult.Valid;
        }
        return ProfileValidationResult.PersonWhoHaveTurned67ShouldHaveReducedRateTicket;
      }
      if (personIsTwentyOrOlder) {
        if (freeTicketProfile === FreeTicketProfile.CHILD) {
          return ProfileValidationResult.PersonWhoHaveTurned20CannotHaveChildTicket;
        }
        return ProfileValidationResult.Valid;
      }
      if (turns20ThisYearButIs19) {
        return ProfileValidationResult.Valid;
      }

      if (freeTicketProfile === FreeTicketProfile.CHILD) {
        return ProfileValidationResult.Valid;
      }
      return ProfileValidationResult.PersonThatTurns19ThisYearOrIsYoungerShouldHaveChildTicket;
    }
  }
  if (tenant === Tenant.Brakar) {
    if (ticketHolderType === TicketHolderType.EMPLOYEE && freeTicketProfile !== FreeTicketProfile.ADULT) {
      return ProfileValidationResult.EmployeeShouldHaveAdultTicket;
    }
    if (ticketHolderType === TicketHolderType.FAMILY_MEMBER) {
      if (freeTicketProfile === FreeTicketProfile.RETIRED) {
        return ProfileValidationResult.FamilyMemberCannotBeRetired;
      }
      if (isUnder18 && freeTicketProfile === FreeTicketProfile.ADULT) {
        return ProfileValidationResult.FamilyMemberUnder18ShouldHaveChildTicket;
      }
      if (!isUnder18 && freeTicketProfile === FreeTicketProfile.CHILD) {
        return ProfileValidationResult.FamilyMemberWhoIs18OrOlderShouldHaveAdultTicket;
      }
    }
    if (ticketHolderType === TicketHolderType.RETIRED && freeTicketProfile !== FreeTicketProfile.RETIRED) {
      return ProfileValidationResult.RetiredShouldHaveReducedRateTicket;
    }
    return ProfileValidationResult.Valid;
  }
  if (tenant === Tenant.Akt) {
    if (ticketHolderType === TicketHolderType.RETIRED || ticketHolderType === TicketHolderType.EMPLOYEE) {
      if (personIs67OrOlder) {
        if (freeTicketProfile === FreeTicketProfile.RETIRED) {
          return ProfileValidationResult.Valid;
        }
        return ProfileValidationResult.PersonWhoHaveTurned67ShouldHaveReducedRateTicket;
      }
      if (freeTicketProfile === FreeTicketProfile.CHILD) {
        return ProfileValidationResult.RetiredOrEmployeeCannotHaveChildTicket;
      }
      return ProfileValidationResult.Valid;
    }
    if (ticketHolderType === TicketHolderType.FAMILY_MEMBER) {
      if (personIs67OrOlder) {
        if (freeTicketProfile === FreeTicketProfile.RETIRED) {
          return ProfileValidationResult.Valid;
        }
        return ProfileValidationResult.PersonWhoHaveTurned67ShouldHaveReducedRateTicket;
      }
      if (personIsTwentyOrOlder) {
        if (freeTicketProfile === FreeTicketProfile.CHILD) {
          return ProfileValidationResult.PersonWhoHaveTurned20CannotHaveChildTicket;
        }
        return ProfileValidationResult.Valid;
      }
      if (turns20ThisYearButIs19) {
        return ProfileValidationResult.Valid;
      }

      if (freeTicketProfile === FreeTicketProfile.CHILD) {
        return ProfileValidationResult.Valid;
      }
      return ProfileValidationResult.PersonThatTurns19ThisYearOrIsYoungerShouldHaveChildTicket;
    }
  }
  throw new Error("Unhandled case");
};

export const getValidProfiles = (birthDate: Date, ticketHolderType: TicketHolderType, tenant: Tenant): FreeTicketProfile[] => {
  const validProfiles: FreeTicketProfile[] = [];

  if (validateProfile(birthDate, ticketHolderType, FreeTicketProfile.CHILD, tenant) === ProfileValidationResult.Valid) {
    validProfiles.push(FreeTicketProfile.CHILD);
  }

  if (validateProfile(birthDate, ticketHolderType, FreeTicketProfile.ADULT, tenant) === ProfileValidationResult.Valid) {
    validProfiles.push(FreeTicketProfile.ADULT);
  }

  if (validateProfile(birthDate, ticketHolderType, FreeTicketProfile.RETIRED, tenant) === ProfileValidationResult.Valid) {
    validProfiles.push(FreeTicketProfile.RETIRED);
  }

  return validProfiles;
};
