import { useLanguageResource } from "@ruter-as/web-components-and-tools";
import { Card, Container } from "@ruter-ds/rds-components";
import React from "react";
import { useValidCompanyTicketService } from "src/AuthContext";
import successAlert from "../../../common/toastr";
import { WhiteButton } from "../../common/buttons";
import widgetLanguageResource from "./lang-resource";
import "./LinkPage.scss";
import useWidget from "./useWidget";

const LinkPage: React.FC = () => {
  const companyService = useValidCompanyTicketService();
  const lang = useLanguageResource(widgetLanguageResource);
  const widget = useWidget();

  if (!widget) return null;

  const copyUrl = () => {
    (document.getElementById("code-textarea") as HTMLTextAreaElement).select();
    document.execCommand("copy");

    const selection = window.getSelection();
    if (selection) {
      if (selection.empty) {
        selection.empty();
      }
      if (selection.removeAllRanges) {
        selection.removeAllRanges();
      }
    }
    (document.getElementById("code-textarea") as HTMLTextAreaElement).blur();

    successAlert(lang.copiedToClipboard);
  };

  const code = companyService.widgetUrls.getLinkUrl(widget);

  return (
    <Container data-test-id="link-page" width="m" className="link-page">
      <h1>{lang.linkTitle}</h1>
      <Card className="code-card" id="code-card" data-test-id="code-card">
        <textarea id="code-textarea" value={code} readOnly />
      </Card>
      <div className="buttons">
        <WhiteButton text={lang.copyUrl} onClick={copyUrl} dataTestId="copy-code-button" />
      </div>
    </Container>
  );
};

export default LinkPage;
