import { AKTProducts, RuterProducts } from "@ruter-as/billettluke-frontend";
import { Tenant } from "../features/Tenant";

const AktProductBlacklist = [
  AKTProducts["UiA-billetten-V2"],
];

export const getBlacklistedProducts = (tenant: Tenant) => {
  switch (tenant) {
    case Tenant.Ruter:
      return [RuterProducts.Reisepenger];
    case Tenant.Akt:
      return AktProductBlacklist;
    case Tenant.Brakar:
      return [];
    default:
      throw new Error(`Blacklist does not exist for Tenant ${tenant}`);
  }
};
