import { LanguageResource, languageResourceBuilder, OneParamLanguageResourceTemplate } from "@ruter-as/web-components-and-tools";

export interface HousingUnitLanguageResource {
  title: OneParamLanguageResourceTemplate
  mobileNumber: string
  tickets: string
  expired: string
  payroll: string
  invoiceMonth: string
  orderNumber: string
  zones: string
  amount: string
  submitFailure: string
  housingAssociationTicket: string
  statuses: {
    active: string
    pending: string
    expired: string
    cancelled: string
    failed: string
    unknown: string
  }
  orderId: string
  validFrom: string
  validTo: string
  pickedUp: string
  pickupCode: string
  appId: string
  pricePerMonth: string
  invoiceReference: string
  resendTicket: string
  sendPickupCode: string
  cancelActiveTicket: string
  cancelPendingTicket: string
  status: string
  changeZone: string
}

export const housingUnitLanguageResource: LanguageResource<HousingUnitLanguageResource> = {
  nb: {
    title: languageResourceBuilder.oneParamTemplate("Billetter tilknyttet boenhet: $p1$"),
    mobileNumber: "Mobilnummer",
    tickets: "Billetter",
    zones: "Soner",
    payroll: "Siste fem lønnstrekk",
    amount: "Beløp",
    orderNumber: "Billettnummer",
    expired: "Utløpte",
    invoiceMonth: "Fakturamnd.",
    submitFailure: "Det skjedde en feil ved lagring av skjema",
    housingAssociationTicket: "Borettslagbillett",
    statuses: {
      active: "Gyldig",
      pending: "Ventende",
      expired: "Utløpt",
      cancelled: "Kansellert",
      failed: "Feilet",
      unknown: "Ukjent",
    },
    orderId: "Billettnummer",
    validFrom: "Gyldig fra",
    validTo: "Gyldig til",
    pickedUp: "Hentet",
    pickupCode: "Hentekode",
    appId: "App id",
    pricePerMonth: "Pris/mnd",
    invoiceReference: "Faktura ref.",
    resendTicket: "Send billett på nytt",
    sendPickupCode: "Send hentekode",
    cancelActiveTicket: "Avslutt billett",
    cancelPendingTicket: "Kansellere billett",
    status: "Status",
    changeZone: "Endre sone",
  },
  en: {
    title: languageResourceBuilder.oneParamTemplate("Tickets associated with housing unit: $p1$"),
    mobileNumber: "Mobile number",
    tickets: "Tickets",
    zones: "Zones",
    payroll: "Last 5 salary deductions",
    amount: "Amount",
    invoiceMonth: "Invoice month",
    orderNumber: "Ticket number",
    expired: "Expired",
    submitFailure: "An error occured while saving the form",
    housingAssociationTicket: "Housing association ticket",
    statuses: {
      active: "Valid",
      pending: "Pending",
      expired: "Expired",
      cancelled: "Cancelled",
      failed: "Failed",
      unknown: "Unknown",
    },

    orderId: "Ticket number",
    validFrom: "Valid from",
    validTo: "Valid to",
    pickedUp: "Fetched",
    pickupCode: "Pickup code",
    appId: "App id",
    pricePerMonth: "Price/month",
    invoiceReference: "Invoice ref.",
    cancelActiveTicket: "Terminate ticket",
    cancelPendingTicket: "Cancel ticket",
    resendTicket: "Resend ticket",
    sendPickupCode: "Sed pickup code",
    status: "Status",
    changeZone: "Change zone",
  },
};
