import { hasValidAgreement } from "src/common/agreements";
import { HousingAssociationWidget } from "src/common/api/companyAgreementApi/housingAssociation/housingAssociationWidget";
import { AgreementSubTypeId } from "src/types/AgreementSubTypeId";
import { AuthCompany } from "src/types/AuthCompany";
import { Contact } from "src/types/Contact";
import { CompanyRoleType } from "src/types/user/CompanyRoleType";
import { UserRoleType } from "src/types/user/UserRoleType";
import { EnvName } from "../EnvName";
import { Tenant } from "../Tenant";
import { WidgetSettings } from "./CompanyTicketService";
import { HasNoService } from "./Services";

interface WidgetAccess {
  getIframeUrl: (widget: HousingAssociationWidget, preview: boolean, theme: "light" | "dark") => string,
  getLinkUrl: (widget: HousingAssociationWidget) => string,
}

export interface HasHousingAssociationService {
  hasService: true,
  allowOrderTicket: boolean
  agreementNumber: string
  widgetSettings: WidgetSettings
  housingUnitCosts: {
    showDatePicker: boolean
  }
  widgetUrls: WidgetAccess
}



const getIframeUrl = (widget: HousingAssociationWidget, preview: boolean, theme: "light" | "dark", env: EnvName): string => {
  const { clientId, clientSecret } = widget;

  let origin = "http://localhost:4000";
  if (env === "test") {
    origin = "https://bigcustomer-widget.test.transhub.io";
  } else if (env === "stage") {
    origin = "https://bigcustomer-widget.stage.transhub.io";
  } else if (env === "prod") {
    origin = "https://bigcustomer-widget.transhub.io";
  }

  let url = `${origin}/?id=${clientId}&secret=${clientSecret}&theme=${theme}`;
  if (preview) {
    url += "&preview=true";
  }
  return url;
};


const getLinkUrl = (widget: HousingAssociationWidget, env: EnvName) => {
  const { clientId, clientSecret } = widget;

  let origin = "http://localhost:4000";
  if (env === "test") {
    origin = "https://bigcustomer-widget.test.transhub.io";
  } else if (env === "stage") {
    origin = "https://bigcustomer-widget.stage.transhub.io";
  } else if (env === "prod") {
    origin = "https://bigcustomer-widget.transhub.io";
  }

  return `${origin}/?id=${clientId}&secret=${clientSecret}&viewmode=link`;
};
export type HousingAssociationService = HasHousingAssociationService | HasNoService;


const calculateWidgetUrls = (env: EnvName): WidgetAccess => ({
  getIframeUrl: (widget: HousingAssociationWidget, preview: boolean, theme: "light" | "dark") => getIframeUrl(widget, preview, theme, env),
  getLinkUrl: (widget: HousingAssociationWidget) => getLinkUrl(widget, env),
});

export const calculateHousingAssociatonService = (selectedCompany: AuthCompany, contact: Contact, tenant: Tenant, env: EnvName): HousingAssociationService => {
  const agreement = selectedCompany.allAgreements.find(x => x.subTypeId === AgreementSubTypeId.HOUSING_ASSOCIATION);

  if (tenant !== Tenant.Ruter) {
    return { hasService: false };
  }

  if (!agreement) {
    return {
      hasService: false,
    };
  }

  const companyAdmin = selectedCompany.roles.some(role => role === CompanyRoleType.FreeTicketOrCompanyTicketAdmin)
    || contact.roles.some(role => role === UserRoleType.GlobalCompanyAdmin);

  if (!companyAdmin) {
    return { hasService: false };
  }

  return {
    hasService: true,
    allowOrderTicket: hasValidAgreement([agreement]),
    agreementNumber: agreement.id,
    widgetSettings: agreement.settings,
    housingUnitCosts: {
      showDatePicker: env !== "prod",
    },
    widgetUrls: calculateWidgetUrls(env),
  };
};

export const getValidHomeOwnerAssociationService = (service: Partial<HousingAssociationService>): HousingAssociationService => ({
  hasService: true,
  agreementNumber: "1234",
  allowOrderTicket: false,
  widgetSettings: {
    emailForInvoicePreview: "",
    emailForTicketRequests: "",
    employeeIdLabel: "",
    widgetAccess: "DISABLED",
  },
  housingUnitCosts: {
    showDatePicker: false,
  },
  widgetUrls: {
    getIframeUrl: (widget: HousingAssociationWidget, preview: boolean, theme: "light" | "dark") => getIframeUrl(widget, preview, theme, "local"),
    getLinkUrl: (widget: HousingAssociationWidget) => getLinkUrl(widget, "local"),
  },
  ...service,
}); 
