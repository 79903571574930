import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface HousingAssociationTicketsLanguageResource {
  title: string
  newTicket: string
  searchPlaceholder: string;
}

const housingAssociationTicketsLanguageResource: LanguageResource<HousingAssociationTicketsLanguageResource> = {
  nb: {
    title: "Borettslagbilletter",
    newTicket: "Ny billett",
    searchPlaceholder: "Filtrer på navn eller telefon",
  },
  en: {
    title: "Housing association tickets",
    newTicket: "New ticket",
    searchPlaceholder: "Search by name og phone",
  },
};

export default housingAssociationTicketsLanguageResource;